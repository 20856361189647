import React, { useCallback, useEffect, useState } from 'react';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import VideoPanel from '../../components/DetailsPanel/VideoPanel/VideoPanel';
import Filter from '../../components/Filter/Filter';
import DeclineVideoModal from '../../components/Modals/DeclineVideoModal/DeclineVideoModal';
import VideoRow from '../../components/TableRows/VideoRow/VideoRow';
import {
  FileResponseViewModel,
  UserViewModel,
  VideoRejectReasonType,
  VideoStatusType
} from '../../core/backend/models';
import {
  sortNewVideos,
  sortVideosByStatus
} from '../../core/helpers/sorting-helpers';
import {
  FilterOptionType,
  IMainPanel,
  IMessage,
  INewContentRow,
  IUserPanel
} from '../../core/types';
import {
  addAllVideos,
  addSchedules,
  clearSchedules,
  getAllVideos,
  ScheduleAction,
  updateVideoStatus,
  useAppDispatch,
  useAppSelector
} from '../../state';
import './ModerateVideos.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchVideosFilter } from '../../core/helpers/search-helpers';
import { videoFilterOptions } from '../../core/helpers/filter-helpers';
import NewContentTableHeader from '../../components/TableHeaders/NewContentTableHeader/NewContentTableHeader';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import VideoRejectionReasonModal from '../../components/Modals/VideoRejectionReasonModal/VideoRejectionReasonModal';
import { updateMediaFileStatus } from '../../core/api';
import appToast from '../../core/toast';
import { createVideoModel } from '../../core/helpers/model-helpers';
import { SortType, VideoStatus } from '../../core/enums';

interface IRejectVideoModal {
  isOpen: boolean;
  videos: number[];
}

const ModerateVideos = () => {
  const dispatch = useAppDispatch();
  const { schedule, videos, auth } = useAppSelector((state) => state);
  const [page, setPage] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<FilterOptionType[]>(
    []
  );
  const [panelState, setPanelState] = useState<IMainPanel>({
    isOpen: false,
    target: null,
    type: null,
    relatedRowIndex: -1
  });
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [rejectModal, setRejectModal] = useState<IRejectVideoModal>({
    isOpen: false,
    videos: []
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [currentVideos, setCurrentVideos] = useState<INewContentRow[]>(videos);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (!isSearching) setCurrentVideos(videos);
  }, [videos, isSearching]);
  /**
   * Run existing scheduled actions before closing the window
   */
  useEffect(() => {
    if (schedule.length === 0) {
      window.onbeforeunload = null;
      return;
    }

    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = '??');
    };
  }, [schedule]);

  /**
   * Filter reports if selected filter changes or reports change
   */
  useEffect(() => {
    filterReports();
  }, [selectedFilters, videos]);

  /**
   * Process all scheduled tasks
   */
  useEffect(() => {
    if (snackState.isOpen || schedule.length < 1) return;
    runScheduledActions();
  }, [snackState.isOpen, schedule]);

  const refreshVideoData = async () => {
    const _videos = await getAllVideos();
    dispatch(addAllVideos(_videos));
  };

  const runScheduledActions = async () => {
    try {
      const requests: Promise<any>[] = schedule.map((task) => {
        if (task.type === 'video') {
          return updateMediaFileStatus(
            task.id,
            +task.action,
            task.extra as number,
            task.additionalData
          );
        }
      });
      await Promise.all(requests);
      dispatch(clearSchedules());
    } catch (e) {
      filterReports();
    } finally {
      await refreshVideoData();
    }
  };

  const filterReports = () => {
    if (selectedFilters.length) {
      const typeFilters: string[] = [];
      const statusFilters: string[] = [];

      selectedFilters.forEach((filter) => {
        if (filter.group === 'Type') typeFilters.push(filter.value);
        else statusFilters.push(filter.value);
      });

      const checkFilterType = (report: INewContentRow) =>
        typeFilters.length && statusFilters.length
          ? typeFilters.includes(report.type) &&
            statusFilters.includes(report.status)
          : typeFilters.includes(report.type) ||
            statusFilters.includes(report.status);

      const filteredVideos = videos
        .filter(checkFilterType)
        .sort(sortVideosByStatus);

      setCurrentVideos(filteredVideos);
    }
  };

  const updateSelectedVideoStatus = (
    videoIds: number[],
    status: number,
    rejectionReason: number,
    additionalInformation?: string
  ) => {
    const scheduledActions: ScheduleAction[] = videoIds.map((itemId) => ({
      id: itemId,
      type: 'video',
      action: status,
      extra: rejectionReason,
      additionalData: additionalInformation
    }));

    dispatch(addSchedules(scheduledActions));

    videoIds.forEach((videoId) => {
      const video = videos.find((video) => video.id === videoId);
      const newVideo = createVideoModel({
        ...video,
        videoStatus: status,
        videoRejectReason: rejectionReason,
        admin: auth.user,
        actionDate: new Date().getTime()
      });

      dispatch(
        updateVideoStatus({
          videoId,
          newVideo
        })
      );
    });
  };

  /**
   * Table methods
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const updateMediaLocally = (items: number[], tempStatus: string) => {
    const updatedVideos = currentVideos.map((video) =>
      items.includes(video.id) ? { ...video, status: tempStatus } : video
    );

    setCurrentVideos(updatedVideos);
  };

  const idFromItem = (item: INewContentRow) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleSeachContent = useCallback(
    (query: string) => {
      if (!query.length) {
        setIsSearching(false);
        setCurrentVideos(videos);
        return;
      }

      setIsSearching(true);

      const lowerCasedQuery = query.toLowerCase();
      const _result = videos.filter(searchVideosFilter(lowerCasedQuery));

      setCurrentVideos(_result);
    },
    [videos]
  );

  const handleApproveReviewSingleVideoStatus =
    (id: number, status: 'approved' | 'review', payload: string = '') =>
    (event?: React.SyntheticEvent) => {
      event && event?.stopPropagation();

      const _status =
        status === 'approved'
          ? VideoStatus.Accepted
          : status === 'review'
          ? VideoStatus.Review
          : VideoStatus.Rejected;
      updateSelectedVideoStatus([id], _status, null);
      openSnack(`Video ${status}`);
    };

  const handleApproveReviewVideoStatus =
    (status: 'approved' | 'review') => () => {
      const _status =
        status === 'approved'
          ? VideoStatus.Accepted
          : status === 'review'
          ? VideoStatus.Review
          : VideoStatus.Rejected;
      updateSelectedVideoStatus(selectedItems, _status, null);
      openSnack(`Videos ${status}`);
      setSelectedItems([]);
    };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const handleReviewVideo = (id: number) => async () => {
    try {
      const videoIds = [id];

      videoIds.forEach((videoId) => {
        const video = videos.find((video) => video.id === videoId);
        const newVideo = createVideoModel({
          ...video,
          videoStatus: VideoStatus.Review as number,
          videoRejectReason: null,
          admin: auth.user,
          actionDate: new Date().getTime()
        });

        dispatch(
          updateVideoStatus({
            videoId,
            newVideo
          })
        );
      });

      await updateMediaFileStatus(id, VideoStatus.Review);
    } catch (e) {
      appToast.showError('Something went wrong. Try again.');
    }
  };

  const handleConfirmSelectedVideosRejection = (
    rejectionReason: VideoRejectReasonType,
    additionalInformation: string
  ) => {
    openSnack('Video(s) rejected');
    handleCloseRejectModal();
    updateSelectedVideoStatus(
      rejectModal.videos,
      VideoStatus.Rejected,
      rejectionReason,
      additionalInformation
    );
    setSelectedItems([]);
  };

  const handleShowRejectModal = (videoId?: number) => () => {
    const _seletedVideos = videoId ? [videoId] : selectedItems;
    setRejectModal({ isOpen: true, videos: _seletedVideos });
  };

  /**
   * Side Panel
   */

  const handleViewVideo =
    (video: FileResponseViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      setPanelState({
        isOpen: true,
        target: video,
        type: 'video',
        relatedRowIndex
      });
    };

  const handleViewUser = (user: UserViewModel) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setUserPanel({ isOpen: true, user });
  };

  const handleCloseVideoPanel = () => {
    setPanelState({ ...panelState, isOpen: false, relatedRowIndex: -1 });
  };

  const handleCloseUserPanel = () => {
    setUserPanel({ ...userPanel, isOpen: false });
  };

  /**
   * Modal Methods
   */

  const handleCloseRejectModal = () => {
    setRejectModal({ isOpen: false, videos: [] });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  const handleSnackUndo = () => {
    dispatch(clearSchedules());
    setCurrentVideos(videos);
    filterReports();
    closeSnack();
  };

  return (
    <>
      <div className='ModerateVideos'>
        <div className='ModerateVideos__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSeachContent}
            className='ModerateVideos__search'
          />
          <Filter
            options={videoFilterOptions}
            onChange={(value: FilterOptionType[]) => {
              if (!value.length) {
                setCurrentVideos(videos);
                setSelectedFilters([]);
                return;
              }

              setSelectedFilters(value);
            }}
          />
        </div>

        <div className='ModerateVideos__content'>
          <BasicTable
            data={currentVideos}
            Header={NewContentTableHeader}
            page={page}
            idFromItem={idFromItem}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrderBy='createdOn'
            customSortingHandlers={{ status: sortNewVideos }}
            renderItem={(video, idx, { isSelected, handleSelectRow }) => (
              <VideoRow
                key={`video-row-${idx}`}
                video={video}
                selected={isSelected}
                onSelect={handleSelectRow(video.id)}
                onViewDetails={handleViewVideo(video, idx)}
                onPreviewUser={handleViewUser}
                onAccept={handleApproveReviewSingleVideoStatus(
                  video.id,
                  'approved'
                )}
                onReview={handleReviewVideo(video.id)}
                onReject={handleShowRejectModal(video.id)}
              />
            )}
          />
        </div>
      </div>
      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onApproveAll={handleApproveReviewVideoStatus('approved')}
          onDeclineAll={handleShowRejectModal()}
        />
      )}

      <VideoRejectionReasonModal
        isOpen={rejectModal.isOpen}
        onAccept={handleConfirmSelectedVideosRejection}
        onClose={handleCloseRejectModal}
      />

      <VideoPanel
        isOpen={panelState.isOpen}
        video={panelState.target}
        onClose={handleCloseVideoPanel}
      />
      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={handleCloseUserPanel}
      />
      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
        onAction={handleSnackUndo}
      />
    </>
  );
};

export default ModerateVideos;
