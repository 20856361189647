import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as HooplaLogo } from '../../images/logo_sm.svg';
import { logOut } from '../../core/api';
import { useAppDispatch, logOutUser, useAppSelector } from '../../state';
import './Header.scss';
import SearchResult from '../SearchResult/SearchResult';
import SimpleSearchInput from '../SimpleSearchInput/SimpleSearchInput';
import { RoutesEnum } from '../../core/enums';

const Header = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();

  const handleLogOut = async () => {
    await logOut();
    dispatch(logOutUser());
    handleCloseMenu();
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSearchResult = () => {
    setSearchQuery('');
  };

  return (
    <div className='Header'>
      <div className='Header__container'>
        <HooplaLogo className='Header__logo' />
        <div className='Header__title'>Dashboard</div>
        <div className='Header__nav'>
          <NavLink
            className='Header__navLink'
            activeClassName='Header__navLink--active'
            exact
            to={RoutesEnum.Events}
            isActive={() =>
              pathname.includes('/events') && !pathname.includes('bookings')
            }
          >
            Events
          </NavLink>
          <NavLink
            className='Header__navLink'
            activeClassName='Header__navLink--active'
            exact
            to={RoutesEnum.Bookings}
            isActive={() => pathname.includes('bookings/')}
          >
            Bookings
          </NavLink>
          <NavLink
            className='Header__navLink'
            activeClassName='Header__navLink--active'
            to={`${RoutesEnum.Transactions}/payouts`}
            isActive={() =>
              [
                '',
                RoutesEnum.Transactions,
                RoutesEnum.Payouts,
                RoutesEnum.Payments
              ].includes(pathname)
            }
          >
            Transactions
          </NavLink>

          {auth.accessPermissionAreas.find((area) =>
            area.startsWith(RoutesEnum.Moderate)
          ) && (
            <NavLink
              className='Header__navLink'
              activeClassName='Header__navLink--active'
              to={RoutesEnum.ModerateVideos}
              isActive={() =>
                [
                  '',
                  RoutesEnum.ModerateVideos,
                  RoutesEnum.ModerateBookingReports,
                  RoutesEnum.ModerateVideoReports,
                  RoutesEnum.ModerateComments
                ].includes(pathname)
              }
            >
              Moderate
            </NavLink>
          )}

          {auth.accessPermissionAreas.includes(RoutesEnum.Statistics) && (
            <NavLink
              className='Header__navLink'
              activeClassName='Header__navLink--active'
              to={RoutesEnum.Statistics}
            >
              Statistics
            </NavLink>
          )}

          {auth.accessPermissionAreas.includes(RoutesEnum.Users) && (
            <NavLink
              className='Header__navLink'
              activeClassName='Header__navLink--active'
              to={RoutesEnum.Users}
            >
              Users
            </NavLink>
          )}

          {auth.accessPermissionAreas.find((area) =>
            area.startsWith(RoutesEnum.CouponList)
          ) && (
            <NavLink
              className='Header__navLink'
              activeClassName='Header__navLink--active'
              to={RoutesEnum.CouponList}
              isActive={() =>
                ['', RoutesEnum.CouponList, RoutesEnum.CouponReport].includes(
                  pathname
                )
              }
            >
              Coupons
            </NavLink>
          )}

          {auth.accessPermissionAreas.find((area) =>
            area.startsWith(RoutesEnum.Cms)
          ) && (
            <NavLink
              className='Header__navLink'
              activeClassName='Header__navLink--active'
              to={RoutesEnum.Banners}
              isActive={() =>
                ['', RoutesEnum.Banners, RoutesEnum.Categories].includes(
                  pathname
                )
              }
            >
              CMS
            </NavLink>
          )}
        </div>

        <div className='Header__rightMenu'>
          <div className='Header__search'>
            <SimpleSearchInput
              rounded
              compact
              onDebouncedTextChange={setSearchQuery}
              placeholder='Search all content'
            />
            {searchQuery.length > 0 && (
              <SearchResult
                className='Header__searchResult'
                query={searchQuery}
                onViewResultsClick={handleCloseSearchResult}
              />
            )}
          </div>
          <div className='Header__menuButton'>
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>

        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          disableScrollLock={true}
        >
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
