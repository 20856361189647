import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBookingRow } from '../../core/types';

const initialState: IBookingRow[] = [];

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    addAllBookings: (state, action: PayloadAction<IBookingRow[]>) =>
      action.payload,
    updateBookingComment: (
      state,
      action: PayloadAction<{ bookingId: number; comments: string }>
    ) => {
      const booking = state.find(
        (booking) => booking.id === action.payload.bookingId
      );

      if (booking) {
        booking.comment = action.payload.comments;
      }
    }
  }
});

export const { addAllBookings, updateBookingComment } = bookingsSlice.actions;
