import appToast from '../../core/toast';
import './CopyBtn.scss';

interface IProps {
  textToCopy: string;
  successMsg: string;
}

const CopyToClipboardButton = ({ textToCopy, successMsg }: IProps) => {
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      appToast.showSuccess(successMsg);
    } catch (err) {
      appToast.showSuccess('Something went wrong!');
    }
  };

  return (
    <button onClick={handleCopyClick} className='CopyBtn__copy-button'></button>
  );
};

export default CopyToClipboardButton;
