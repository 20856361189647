import { useEffect } from 'react';
import cn from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import { useSimpleSearch } from '../../hooks/useSimpleSearch';
import './SimpleSearchInput.scss';

interface Props {
  placeholder?: string;
  compact?: boolean;
  rounded?: boolean;
  className?: string;
  onDebouncedTextChange: (text: string) => void;
}

const SimpleSearchInput = ({
  onDebouncedTextChange,
  placeholder,
  compact = false,
  rounded = false,
  className = ''
}: Props) => {
  const { searchText, debouncedSearchText, onSearchChange } = useSimpleSearch(
    '',
    600
  );

  useEffect(() => {
    onDebouncedTextChange(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleOnTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
  };

  return (
    <div
      className={cn(`SearchInput ${className}`, {
        'SearchInput--rounded': rounded,
        'SearchInput--compact': compact
      })}
    >
      <SearchIcon />
      <input
        className='SearchInput__input'
        placeholder={placeholder ?? 'Search...'}
        value={searchText}
        onChange={handleOnTextChange}
      />
    </div>
  );
};

export default SimpleSearchInput;
