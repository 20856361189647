import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { getUserPayments } from '../../core/api';
import { UserPaymentLogViewModel } from '../../core/backend/models';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import { FilterOptionType } from '../../core/types';
import './Payments.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchPaymentsFilter } from '../../core/helpers/search-helpers';
import { userPaymentFilterOptions } from '../../core/helpers/filter-helpers';
import Filter from '../../components/Filter/Filter';
import PaymentRow from '../../components/TableRows/PaymentRow/PaymentRow';
import PaymenttTableHeader from '../../components/TableHeaders/PaymenttTableHeader/PaymenttTableHeader';

const Payments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tablePage, setTablePage] = useState(0);
  const [payments, setPayments] = useState<UserPaymentLogViewModel[]>([]);
  const [currentPayments, setCurrentPayments] = useState<
    UserPaymentLogViewModel[]
  >([]);

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = async () => {
    const _payments = await getUserPayments();

    const sortedPayments = _payments.sort(
      (a, b) => +b.dateOfTransaction - +a.dateOfTransaction
    );

    setPayments(sortedPayments);
    setCurrentPayments(sortedPayments);
    setIsLoading(false);
  };

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setTablePage(newPage);
  };

  // const handleSelectionChange = (selectedRows: string[]) => {
  //   setSelectedItems(selectedRows);
  // };

  const idFromItem = (item: UserPaymentLogViewModel) => {
    return item.bookingId;
  };
  /**
   * Actions
   */

  const filterPaymentsWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const transactionFilters = selectedFilters.map((filter) => filter.value);

      const checkFilterTypes = (payout: UserPaymentLogViewModel) =>
        transactionFilters.includes(payout.paymentType.toString());

      const filteredPayouts = payments.filter(checkFilterTypes);
      setCurrentPayments(filteredPayouts);
    }
  };

  const handleSearchContent = (query: string) => {
    if (!query.length) {
      setTablePage(0);
      setCurrentPayments(payments);
      return;
    }

    const lowerCasedQuery = query.toLowerCase();
    const _result = payments.filter(searchPaymentsFilter(lowerCasedQuery));

    setTablePage(0);
    setCurrentPayments(_result);
  };

  /**
   * Side Panel
   */

  /**
   * Modal Methods
   */

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='Payouts'>
        <div className='Payouts__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSearchContent}
            className='Payouts__search'
          />
          <Filter
            options={userPaymentFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentPayments(payments);
                return;
              }

              filterPaymentsWith(selectedFilters);
            }}
          />
        </div>

        <div className='Payouts__content'>
          <BasicTable
            Header={PaymenttTableHeader}
            data={currentPayments}
            idFromItem={idFromItem}
            page={tablePage}
            rowsPerPage={20}
            onPageChange={handleChangePage}
            //onSelectionChange={handleSelectionChange}
            renderItem={(item, idx) => <PaymentRow payment={item} />}
          />
        </div>
      </div>
    </>
  );
};

export default Payments;
