import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Moderate.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import ModerateVideos from '../ModerateVideos/ModerateVideos';
import ModerateComments from '../ModerateComments/ModerateComments';
import ModerateVideoReports from '../ModerateVideoReports/ModerateVideoReports';
import ModerateBookingReports from '../ModerateBookingReports/ModerateBookingReports';
import { RoutesEnum } from '../../core/enums';

const Moderate = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.ModerateVideos}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Videos
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.ModerateVideoReports}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Video Reports
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.ModerateBookingReports}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Booking Reports
          </Button>

          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.ModerateComments}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Comments
          </Button>
        </ButtonGroup>
      </Box>
      <Switch>
        <Route
          exact
          path={RoutesEnum.ModerateVideoReports}
          component={ModerateVideoReports}
        />
        <Route
          exact
          path={RoutesEnum.ModerateBookingReports}
          component={ModerateBookingReports}
        />
        <Route
          exact
          path={RoutesEnum.ModerateVideos}
          component={ModerateVideos}
        />
        <Route
          exact
          path={RoutesEnum.ModerateComments}
          component={ModerateComments}
        />
      </Switch>
    </MainLayout>
  );
};

export default Moderate;
