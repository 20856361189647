import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { purple } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import { FormikValues, useFormik } from 'formik';
import classNames from 'classnames';
import ReplySchema from '../../validators/ReplySchema';
import './ReplyForm.scss';

interface IProps {
  placeholder: string;
  isLoading?: boolean;
  onSubmit: (values: any) => void;
}

interface FormValues {
  message: string;
}

const ReplyForm = ({ placeholder, onSubmit, isLoading = false }: IProps) => {
  const { errors, values, handleChange, handleSubmit } = useFormik<FormValues>({
    initialValues: { message: '' },
    validationSchema: ReplySchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: FormikValues, { resetForm }) => {
      onSubmit(values);
      resetForm();
    }
  });

  return (
    <div className='ReplyForm'>
      <form
        onSubmit={handleSubmit}
        method='post'
        className={classNames('ReplyForm__form', {
          ReplyForm__error: errors.message
        })}
      >
        {/* <IconButton>
          <AttachFileIcon />
        </IconButton> */}
        <input
          className='ReplyForm__message'
          name='message'
          type='text'
          placeholder={(errors.message as string) ?? placeholder}
          value={values.message}
          onChange={handleChange}
          disabled={isLoading}
        />
        <Box sx={{ position: 'relative' }}>
          <IconButton type='submit' disabled={isLoading}>
            <SendIcon />
          </IconButton>
          {isLoading && (
            <CircularProgress
              size={38}
              sx={{
                color: purple[100],
                position: 'absolute',
                top: 1,
                left: -1,
                zIndex: 1
              }}
            />
          )}
        </Box>
      </form>
    </div>
  );
};

export default ReplyForm;
