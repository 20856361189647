import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';
import { GetAllEventsOnlyResponse } from '../../../core/backend/models';

const EventsTableHeader: React.FC<
  ITableHeaderProps<GetAllEventsOnlyResponse>
> = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler =
    (property: keyof GetAllEventsOnlyResponse) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Video
        </TableCell>
        <TableCell align='left' padding='normal'>
          Name
        </TableCell>
        <TableCell align='center' padding='normal'>
          Creator
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'requestTime'}
            direction={orderBy === 'requestTime' ? order : 'asc'}
            onClick={createSortHandler('isRecurrentEvent')}
          >
            Recurrence
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'requestTime'}
            direction={orderBy === 'requestTime' ? order : 'asc'}
            onClick={createSortHandler('address')}
          >
            Location
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'requestTime'}
            direction={orderBy === 'requestTime' ? order : 'asc'}
            onClick={createSortHandler('oneEventTimeTimestampToSeconds')}
          >
            End Date
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'requestTime'}
            direction={orderBy === 'requestTime' ? order : 'asc'}
            onClick={createSortHandler('visibility')}
          >
            Visibility
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default EventsTableHeader;
