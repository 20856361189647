import Scrollbar from 'react-scrollbars-custom';
import { ChatMemberViewModel, UserViewModel } from '../../core/backend/models';
import './Messages.scss';

interface IProps {
  data: ChatMemberViewModel[];
  renderItem: (member: UserViewModel, index: number) => React.ReactNode;
}

const Messages = ({ data, renderItem }: IProps) => {
  return (
    <div className='Messages'>
      <div className='Messages__wrapper'>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <div className='Messages__messages'>
            {data.length ? (
              data.map((chat, idx) => renderItem(chat.member, idx))
            ) : (
              <div className='Messages__noMessages'>No messages.</div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default Messages;
