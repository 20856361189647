import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Scrollbar from 'react-scrollbars-custom';
import { GigViewModel } from '../../../core/backend/models';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './GigDetailsTab.scss';
import {
  translateHostingType,
  translateLocationType,
  translatePricingModel
} from '../../../core/helpers/enums-helpers';

interface IProps extends IBaseTabProps {
  gig?: GigViewModel;
}

const GigDetailsTab = ({ gig, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='GigDetailsTab'>
      <div className='GigDetailsTab__wrapper'>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <Grid
            container
            style={{ padding: '17px 12px 12px' }}
            direction={'column'}
            rowSpacing={2}
          >
            <Grid item>
              <TextField
                id='title'
                label='Title'
                variant='standard'
                value={gig.name ?? ''}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                id='category'
                label='Category'
                variant='standard'
                value={gig.category?.name ?? ''}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id='type'
                label='Type'
                variant='standard'
                value={translateHostingType(gig.hostingType)}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            {gig.hostingType !== 0 && (
              <Grid item>
                <TextField
                  id='location'
                  label='Location'
                  variant='standard'
                  value={translateLocationType(gig.locationType)}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <TextField
                  id='pricing'
                  label='Pricing model'
                  variant='standard'
                  value={translatePricingModel(gig.pricingModel)}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant='standard'>
                  <InputLabel htmlFor='price'>Price</InputLabel>
                  <Input
                    id='price'
                    value={gig.price ?? ''}
                    readOnly
                    startAdornment={
                      <InputAdornment position='start'>$</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id='description'
                label='Description'
                multiline
                fullWidth
                maxRows={14}
                variant='standard'
                value={gig.description ?? ''}
                inputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
        </Scrollbar>
      </div>
    </BaseTab>
  );
};

export default GigDetailsTab;
