import { toast, ToastOptions } from 'react-toastify';

const appToastSettings: ToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

const appToast = {
  showError(message: string) {
    toast.error(message, appToastSettings);
  },
  showSuccess(message: string) {
    toast.success(message, appToastSettings);
  }
};

export default appToast;
