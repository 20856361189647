import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDebounce } from './useDebounce';

export function useSearch(initialValue: string, delay = 300, minLength = 2) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const searchValue = params.get('query');
  const [searchText, setSearchText] = useState<string>(
    searchValue || initialValue
  );
  const trimmed = searchText.trim();
  const debouncedSearchText = useDebounce(
    trimmed.length >= minLength ? trimmed : '',
    delay
  );

  useEffect(() => {
    if (searchValue === debouncedSearchText) return;
    searchValue && setSearchText(searchValue);
  }, [searchValue]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const searchQuery = params.get('query');

    if (searchQuery !== debouncedSearchText) {
      if (!!debouncedSearchText) params.set('query', debouncedSearchText);
      else params.delete('query');

      history.replace(pathname + '?' + params.toString());
    }
  }, [history, pathname, search, debouncedSearchText]);

  const onSearchChange = (value: string) => {
    setSearchText(value);
  };

  return {
    debouncedSearchText,
    searchText,
    onSearchChange
  };
}
