import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps } from '../../../core/types';
import './GigPanel.scss';
import { GigViewModel } from '../../../core/backend/models';
import GigDetailsTab from '../../Tabs/GigDetailsTab/GigDetailsTab';
import { useEffect, useState } from 'react';
import { getExperience } from '../../../core/api';
import appToast from '../../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/consts';
import Loader from '../../Loader/Loader';

interface IProps extends IPanelProps {
  gig: GigViewModel;
}

const GigPanel = ({ title = 'Gig details', gig, ...props }: IProps) => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [experience, setExperience] = useState<GigViewModel>(null);

  useEffect(() => {
    if (!props.isOpen) return;

    getEventData();
  }, [gig?.id, props.isOpen]);

  const getEventData = async () => {
    try {
      setIsProcessing(true);

      let event = gig;

      if (!event?.name) {
        event = await getExperience(gig.id);
      }

      setExperience(event);
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={0}
          textColor='secondary'
          indicatorColor='secondary'
          variant='fullWidth'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='simple-tab-0' />
        </Tabs>
      )}

      <div className='GigPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <GigDetailsTab gig={experience} value={0} index={0} />
        )}
      </div>
    </BasePanel>
  );
};

export default GigPanel;
