import { useCallback, useEffect, useState } from 'react';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import VideoPanel from '../../components/DetailsPanel/VideoPanel/VideoPanel';
import Filter from '../../components/Filter/Filter';
import DeclineVideoModal from '../../components/Modals/DeclineVideoModal/DeclineVideoModal';
import VideoReportRow from '../../components/TableRows/VideoReportRow/VideoReportRow';
import { resolveMediaFileReport } from '../../core/api';
import {
  FileResponseViewModel,
  UserViewModel
} from '../../core/backend/models';
import { sortVideoReportsByStatus } from '../../core/helpers/sorting-helpers';
import {
  FilterOptionType,
  IMainPanel,
  IMessage,
  IModalState,
  IBookingReportRow,
  IVideoReportRow,
  IUserPanel
} from '../../core/types';
import {
  addAllVideoReports,
  addSchedule,
  addSchedules,
  clearSchedules,
  getAllVideoReports,
  ScheduleAction,
  updateVideoReportStatus,
  useAppDispatch,
  useAppSelector
} from '../../state';
import './ModerateVideoReports.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchVideoReportsFilter } from '../../core/helpers/search-helpers';
import { videoReportsFilterOptions } from '../../core/helpers/filter-helpers';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import { createVideoReportModel } from '../../core/helpers/model-helpers';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import VideoReportsTableHeader from '../../components/TableHeaders/VideoReportsTableHeader/VideoReportsTableHeader';
import { SortType } from '../../core/enums';

const ModerateVideoReports = () => {
  const dispatch = useAppDispatch();
  const { schedule, videoReports } = useAppSelector((state) => state);
  const [page, setPage] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<FilterOptionType[]>(
    []
  );
  const [reportModal, setReportModal] = useState<IModalState>({
    isOpen: false,
    id: 0
  });
  const [panelState, setPanelState] = useState<IMainPanel>({
    isOpen: false,
    target: null,
    type: null,
    relatedRowIndex: -1
  });
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [currentReports, setCurrentReports] =
    useState<IVideoReportRow[]>(videoReports);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (!isSearching) setCurrentReports(videoReports);
  }, [videoReports, isSearching]);
  /**
   * Run existing scheduled actions before closing the window
   */
  useEffect(() => {
    if (schedule.length === 0) {
      window.onbeforeunload = null;
      return;
    }

    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = '??');
    };
  }, [schedule]);

  /**
   * Filter reports if selected filter changes or reports change
   */
  useEffect(() => {
    filterReports();
  }, [selectedFilters, videoReports]);

  /**
   * Process all scheduled tasks
   */
  useEffect(() => {
    if (snackState.isOpen || schedule.length < 1) return;
    runScheduledActions();
  }, [snackState.isOpen, schedule]);

  const runScheduledActions = async () => {
    const refreshState = async () => {
      const _reports = await getAllVideoReports();
      const reports = _reports.map(createVideoReportModel);
      dispatch(addAllVideoReports(reports));
    };

    try {
      const requests: Promise<any>[] = [];

      schedule.forEach((task) => {
        const mediaFileReport = videoReports.find(
          (report) => report.id === task.id
        );

        dispatch(
          updateVideoReportStatus({
            status: task.action as string,
            videoIds: [task.id]
          })
        );

        requests.push(
          resolveMediaFileReport(
            mediaFileReport.reportingUserId,
            task.id,
            task.extra as string,
            task.action === 'declined'
          )
        );
      });

      await Promise.all(requests);

      //TODO: remove if not needed
      //await refreshState();
    } catch (e) {
      await refreshState();
      filterReports();
    } finally {
      dispatch(clearSchedules());
    }
  };

  const filterReports = () => {
    if (selectedFilters.length) {
      const statusFilters: string[] = selectedFilters.map(
        (filter) => filter.value
      );

      const checkFilterType = (report: IBookingReportRow) =>
        statusFilters.includes(report.status);

      const filteredReports = videoReports
        .filter(checkFilterType)
        .sort(sortVideoReportsByStatus);

      setCurrentReports(filteredReports);
    }
  };

  /**
   * Table methods
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const updateMediaLocally = (items: number | number[], tempStatus: string) => {
    const _items = Array.isArray(items) ? items : [items];

    const updatedReports = currentReports.map((report) => {
      const reportIndex = _items.findIndex((itemId) => itemId === report.id);
      if (reportIndex === -1) return report;

      const updatedReport = Object.assign(
        { ...report },
        { status: tempStatus }
      );

      return updatedReport;
    });

    setCurrentReports(updatedReports);
  };

  /**
   * Table Methods
   */

  const idFromItem = (item: IVideoReportRow) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleSeachContent = useCallback(
    (query: string) => {
      if (!query.length) {
        setIsSearching(false);
        setCurrentReports(videoReports);
        return;
      }

      setIsSearching(true);
      const lowerCasedQuery = query.toLowerCase();

      const _result = videoReports.filter(
        searchVideoReportsFilter(lowerCasedQuery)
      );
      setCurrentReports(_result);
    },
    [videoReports]
  );

  const handleSingleVideoStatus =
    (id: number, action: 'approved' | 'declined', payload: string = '') =>
    (event?: React.SyntheticEvent) => {
      event && event.stopPropagation();

      dispatch(
        addSchedule({
          id,
          type: 'video_report',
          action,
          extra: payload
        })
      );

      updateMediaLocally(id, action);
      openSnack(`Video ${action}`);
    };

  const handleAllVideosStatus = (action: 'approved' | 'declined') => () => {
    const approvedItems: ScheduleAction[] = selectedItems.map((itemId) => ({
      id: itemId,
      type: 'video_report',
      action
    }));

    dispatch(addSchedules(approvedItems));
    updateMediaLocally(selectedItems, action);
    openSnack(`Videos ${action}`);
    setSelectedItems([]);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const confirmVideoDeclination = (message: string) => {
    handleSingleVideoStatus(reportModal.id, 'declined', message)();
    closeReportModal();
  };

  /**
   * Side Panel
   */

  const handleViewUser = (user: UserViewModel) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setUserPanel({ isOpen: true, user });
  };

  const handleViewVideo =
    (video: FileResponseViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      setPanelState({
        isOpen: true,
        target: video,
        type: 'video',
        relatedRowIndex
      });
    };

  const closePanel = () => {
    setPanelState({ ...panelState, isOpen: false, relatedRowIndex: -1 });
  };

  const handleCloseUserPanel = () => {
    setUserPanel({ ...userPanel, isOpen: false });
  };

  /**
   * Modal Methods
   */
  const handleShowReportModal =
    (id: number) => (event: React.SyntheticEvent) => {
      event.stopPropagation();
      setReportModal({ isOpen: true, id });
    };

  const closeReportModal = () => {
    setReportModal({ isOpen: false, id: 0, type: null });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  const handleSnackUndo = () => {
    dispatch(clearSchedules());
    setCurrentReports(videoReports);
    filterReports();
    closeSnack();
  };

  return (
    <>
      <div className='ModerateVideoReports'>
        <div className='ModerateVideoReports__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSeachContent}
            className='ModerateVideoReports__search'
          />
          <Filter
            options={videoReportsFilterOptions}
            onChange={(value: FilterOptionType[]) => {
              if (!value.length) {
                setCurrentReports(videoReports);
                return;
              }

              setSelectedFilters(value);
            }}
          />
        </div>

        <div className='ModerateVideoReports__content'>
          <BasicTable
            data={currentReports}
            Header={VideoReportsTableHeader}
            page={page}
            idFromItem={idFromItem}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrderBy='createdOn'
            renderItem={(video, idx, { isSelected, handleSelectRow }) => (
              <VideoReportRow
                key={`video-report-row-${idx}`}
                status={video.status}
                video={video}
                selected={isSelected}
                onSelect={handleSelectRow(video.mediaFileId)}
                onViewDetails={handleViewVideo(video.mediaFile, idx)}
                onPreviewUser={handleViewUser}
                onAccept={handleSingleVideoStatus(
                  video.mediaFileId,
                  'approved'
                )}
                onDecline={handleShowReportModal(video.mediaFileId)}
              />
            )}
          />
        </div>
      </div>

      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onApproveAll={handleAllVideosStatus('approved')}
          onDeclineAll={handleAllVideosStatus('declined')}
        />
      )}

      <VideoPanel
        title='Clip details'
        isOpen={panelState.isOpen && panelState.type === 'video'}
        video={panelState.target}
        onClose={closePanel}
      />

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={handleCloseUserPanel}
      />

      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
        onAction={handleSnackUndo}
      />
      <DeclineVideoModal
        isOpen={reportModal.isOpen}
        onClose={closeReportModal}
        onAccept={confirmVideoDeclination}
      />
    </>
  );
};

export default ModerateVideoReports;
