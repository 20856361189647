import {
  ChatMemberViewModel,
  CommentViewModel,
  MessageViewModel
} from '../../../core/backend/models';
import Comments from '../../Comments/Comments';
import Conversation from '../../Conversation/Conversation';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './ConversationTab.scss';

interface IProps extends IBaseTabProps {
  members?: ChatMemberViewModel[];
  data?: MessageViewModel[];
}

const ConversationTab = ({ members = [], data = [], ...props }: IProps) => {
  return (
    <BaseTab {...props} className='ConversationTab'>
      <Conversation members={members} data={data} isDirectChat={false} />
    </BaseTab>
  );
};

export default ConversationTab;
