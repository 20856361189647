import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { getExternalEventLinks } from '../../core/api';
import {
  GetAllGigTicketClicksResponse,
  GigViewModel
} from '../../core/backend/models';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import './ExternalEvents.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchExternalEventsFilter } from '../../core/helpers/search-helpers';
import ExternalEventsTableHeader from '../../components/TableHeaders/ExternalEventsTableHeader/ExternalEventsTableHeader';
import ExternalEventRow from '../../components/TableRows/ExternalEventRow/ExternalEventRow';
import GigPanel from '../../components/DetailsPanel/GigPanel/GigPanel';
import { IMainPanel } from '../../core/types';
import LazyTable from '../../components/Tables/LazyTable/LazyTable';
import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DEFAULT_ERROR_MESSAGE } from '../../core/consts';
import appToast from '../../core/toast';

const EVENTS_PER_PAGE = 50;

const ExternalEvents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [externalEvents, setExternalEvents] = useState<
    GetAllGigTicketClicksResponse[]
  >([]);
  const [currentExternalEvents, setCurrentExternalEvents] = useState<
    GetAllGigTicketClicksResponse[]
  >([]);
  const [eventPanel, setEventPanel] = useState<IMainPanel>({
    isOpen: false,
    target: null,
    type: 'Experience',
    relatedRowIndex: -1
  });

  useEffect(() => {
    getExternalEvents(skip, EVENTS_PER_PAGE);
  }, []);

  const getExternalEvents = async (
    skip: number,
    take: number,
    loadMore = false
  ) => {
    try {
      if (loadMore) setIsLoadingMore(true);
      else setIsLoading(true);

      const _externalEvents = await getExternalEventLinks(skip, take);

      if (_externalEvents.length) {
        setExternalEvents([...externalEvents, ..._externalEvents]);
        setCurrentExternalEvents([...externalEvents, ..._externalEvents]);
        setSkip((skip) => skip + _externalEvents.length);
        if (loadMore) setPage((prevState) => prevState + 1);
      }

      setHasMore(_externalEvents.length > 0);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  /**
   * Table methods
   */

  const idFromItem = (item: GetAllGigTicketClicksResponse) => {
    return item.gigId;
  };

  /**
   * Actions
   */

  const handleSearchContent = (query: string) => {
    if (!query.length) {
      setCurrentExternalEvents(externalEvents);
      return;
    }

    const lowerCasedQuery = query.toLowerCase();
    const _result = externalEvents.filter(
      searchExternalEventsFilter(lowerCasedQuery)
    );

    setCurrentExternalEvents(_result);
  };

  const handlePrevPage = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleNextPage = async () => {
    if (!hasMore) return;

    const startIndex = page * EVENTS_PER_PAGE;
    const endIndex = startIndex + EVENTS_PER_PAGE;

    if (endIndex >= externalEvents.length - 1) {
      await getExternalEvents(skip, EVENTS_PER_PAGE, true);
    }
  };

  /**
   * Side Panel
   */

  const handleViewEvent =
    (experience: GigViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setEventPanel({
        ...eventPanel,
        isOpen: true,
        target: experience,
        relatedRowIndex
      });
    };

  const closeEventPanel = () => {
    setEventPanel({
      ...eventPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  /**
   * Modal Methods
   */

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='ExternalEvents'>
        <div className='ExternalEvents__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSearchContent}
            className='ExternalEvents__search'
          />
        </div>

        <div className='ExternalEvents__content'>
          <LazyTable
            Header={ExternalEventsTableHeader}
            data={currentExternalEvents}
            idFromItem={idFromItem}
            initialOrderBy='totalClicks'
            renderItem={(item, idx) => (
              <ExternalEventRow
                key={`payout-row-${idx}`}
                event={item}
                selected={eventPanel.relatedRowIndex === idx}
                onClick={handleViewEvent({ id: item.gigId }, idx)}
              />
            )}
          />
        </div>

        <div className='ExternalEvents__pageButtons'>
          <IconButton
            color='secondary'
            onClick={handlePrevPage}
            disabled={isLoadingMore || page === 0}
            size='large'
          >
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
          <IconButton
            color='secondary'
            onClick={handleNextPage}
            disabled={isLoadingMore || !hasMore}
            size='large'
          >
            <NavigateNextIcon fontSize='large' />
          </IconButton>
          {isLoadingMore && (
            <Loader className='CouponsReport__loader' adaptToParent size={32} />
          )}
        </div>
      </div>

      <GigPanel
        isOpen={eventPanel.isOpen}
        gig={eventPanel.target}
        onClose={closeEventPanel}
      />
    </>
  );
};

export default ExternalEvents;
