import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { ITableHeaderProps } from '../../../core/types';
import { SortType } from '../../../core/enums';

interface ICustomSortings<T> {
  [key: string]: (a: T, b: T) => number;
}
interface IProps<T, K> {
  data: T[];
  Header: React.ComponentType<ITableHeaderProps<T>>;
  renderHeader?: (props: ITableHeaderProps<T>) => React.ReactNode;
  selectedRows?: K[];
  onSelectionChange?: (selectedRows: K[]) => void;
  idFromItem: (item: T) => K;
  renderItem: (
    item: T,
    index: number,
    helpers: {
      handleSelectRow: (id: K) => React.MouseEventHandler;
      isSelected: boolean;
    }
  ) => React.ReactNode;
  page?: number;
  onPageChange?: (e: unknown, newPage: number) => void;
  rowsPerPage?: number;
  initialOrder?: SortType;
  initialOrderBy?: string;
  customSortingHandlers?: ICustomSortings<T>;
}

const BasicTable = <T, K>({
  data = [],
  Header,
  renderHeader,
  idFromItem,
  selectedRows = [],
  onSelectionChange,
  page,
  onPageChange,
  renderItem,
  rowsPerPage = 10,
  initialOrder = SortType.Desc,
  initialOrderBy = '',
  customSortingHandlers
}: IProps<T, K>) => {
  const [order, setOrder] = useState<SortType>(initialOrder);
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy);
  const [_rowsPerPage, set_RowsPerPage] = useState(rowsPerPage);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === SortType.Asc;
    setOrder(isAsc ? SortType.Desc : SortType.Asc);
    setOrderBy(property as string);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: K[] = data.map((item) => idFromItem(item));

      onSelectionChange(newSelecteds);
      return;
    }

    onSelectionChange([]);
  };

  const handleSelectRow = (id: K) => (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();

    const selectedIndex = selectedRows.findIndex(
      (selectedId) => selectedId === id
    );
    let newSelected: K[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedRows, id];
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    onSelectionChange(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    set_RowsPerPage(parseInt(event.target.value, 10));
    onPageChange('', 0);
  };

  const isSelected = (id: K) =>
    selectedRows.findIndex((selectedId) => selectedId === id) !== -1;

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortType, orderBy: keyof T) {
    if (customSortingHandlers && customSortingHandlers[orderBy as string]) {
      return order === SortType.Desc
        ? (a: T, b: T) => customSortingHandlers[orderBy as string](a, b)
        : (a: T, b: T) => -customSortingHandlers[orderBy as string](a, b);
    } else {
      return order === SortType.Desc
        ? (a: T, b: T) => descendingComparator(a, b, orderBy)
        : (a: T, b: T) => -descendingComparator(a, b, orderBy);
    }
  }

  const emptyRows =
    _rowsPerPage - Math.min(_rowsPerPage, data.length - page * _rowsPerPage);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size='medium'>
            {/* <Header
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAll}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            /> */}
            {renderHeader &&
              renderHeader({
                numSelected: selectedRows.length,
                rowCount: data.length,
                order,
                orderBy,
                onSelectAllClick: handleSelectAll,
                onRequestSort: handleRequestSort
              })}
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy as keyof T))
                .slice(page * _rowsPerPage, page * _rowsPerPage + _rowsPerPage)
                .map((row, index) =>
                  renderItem(row, index, {
                    handleSelectRow,
                    isSelected: isSelected(idFromItem(row))
                  })
                )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 85 * emptyRows
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          component='div'
          count={data.length}
          rowsPerPage={_rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default BasicTable;
