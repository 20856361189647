export const GOOGLE_API_KEY = 'AIzaSyCy3_7sJJi_ZhNS_xMXOqPxOejbYZpLu6k';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

export const HOUR_IN_SECONDS = 3600;
export const SERVICE_FEE = 0.08;

export const USERS_PER_PAGE = 50;
export const COMMENTS_PER_PAGE = 50;
export const COUPONS_REPORT_PER_PAGE = 25;
export const BOOKING_LOGS_PER_PAGE = 25;
