import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getMediaFileReports } from '../../core/api';
import { createVideoReportModel } from '../../core/helpers/model-helpers';
import { IVideoReportRow } from '../../core/types';

const initialState: IVideoReportRow[] = [];

export const getAllVideoReports = async () => {
  const videos = await getMediaFileReports(0, 10000);
  return videos.map(createVideoReportModel);
};

export const fetchAllVideoReports = createAsyncThunk(
  'report/fetchVideoReports',
  getAllVideoReports
);

export const videoReportsSlice = createSlice({
  name: 'video-reports',
  initialState,
  reducers: {
    addAllVideoReports: (state, action: PayloadAction<IVideoReportRow[]>) =>
      action.payload,
    updateVideoReportStatus: (
      state,
      { payload }: PayloadAction<{ status: string; videoIds: number[] }>
    ) => {
      payload.videoIds.forEach((id) => {
        const videoIndex = state.findIndex((video) => video.id === id);

        if (videoIndex < 0) return;

        state[videoIndex].status = payload.status;
        state[videoIndex].isBadVideo = payload.status === 'declined';
      });
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchAllVideoReports.fulfilled, (state, action) => {
      state = action.payload;
    });
  }
});

export const { addAllVideoReports, updateVideoReportStatus } =
  videoReportsSlice.actions;
