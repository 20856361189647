import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getBookingReports } from '../../core/api';
import { createBookingReportModel } from '../../core/helpers/model-helpers';
import { IBookingReportRow } from '../../core/types';
import { BookingResolution } from '../../core/enums';

const initialState: IBookingReportRow[] = [];

export const getAllBookingReports = async () => {
  const bookings = await getBookingReports(0, 10000);
  return bookings.map(createBookingReportModel);
};

export const fetchAllReports = createAsyncThunk(
  'report/fetchBookingReports',
  getAllBookingReports
);

export const bookingReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addAllBookingReports: (state, action: PayloadAction<IBookingReportRow[]>) =>
      action.payload,
    updateBookingReportStatus: (
      state,
      {
        payload
      }: PayloadAction<{
        status: string;
        bookingId: number;
        reportResolution: number;
      }>
    ) => {
      const targetIndex = state.findIndex(
        (booking) => booking.id === payload.bookingId
      );

      state[targetIndex].status = payload.status;
      state[targetIndex].reportResolution = payload.reportResolution;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchAllReports.fulfilled, (state, action) => {
      state = action.payload;
    });
  }
});

export const { addAllBookingReports, updateBookingReportStatus } =
  bookingReportsSlice.actions;
