import { useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { ICoordinates } from '../../core/types';
import { GOOGLE_API_KEY } from '../../core/consts';

interface IProps {
  children?: React.ReactNode;
  center: ICoordinates;
}

const Map = ({ center, children }: IProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY
  });

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback((map) => {
    mapRef.current = null;
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      zoom={15}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        disableDefaultUI: true
      }}
    >
      {children}
    </GoogleMap>
  ) : null;
};

export default Map;
