import FormControlLabel from '@mui/material/FormControlLabel';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './VideoRejectionReasonModal.scss';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import { BannerViewModel } from '../../../core/backend/models';
import { Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react';

interface FormValues {
  reason: number | null;
  additionalInformation: string;
}

const VideoRejectionReasonModal = ({
  onAccept,
  onClose,
  ...props
}: IModalProps) => {
  const { errors, values, handleChange, setFieldValue, submitForm, resetForm } =
    useFormik<FormValues>({
      initialValues: { reason: -1, additionalInformation: '' },
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      validate: (values) => {
        const errors: FormikErrors<FormValues> = {};

        if (values.reason < 0) {
          errors.reason = 'Please select a reason.';
        }

        const addInfo = values.additionalInformation.trim();

        if (addInfo.length > 0 && addInfo.length < 10) {
          errors.additionalInformation = 'Please, be more descriptive.';
        }

        return errors;
      },
      onSubmit: async (
        { reason, additionalInformation }: FormikValues,
        { resetForm }
      ) => {
        onAccept(reason, additionalInformation);
      }
    });

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setFieldValue('reason', +value);
  };

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Select Rejection Reason'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      {errors.reason && (
        <div className='VideoRejectionReasonModal__error'>{errors.reason}</div>
      )}

      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        value={values.reason}
        onChange={handleReasonChange}
      >
        <FormControlLabel
          value='0'
          control={<Radio />}
          label='Inappropriate Behavior / Content'
        />
        <FormControlLabel
          value='1'
          control={<Radio />}
          label='Content not relevant to Experience'
        />
        <FormControlLabel
          value='2'
          control={<Radio />}
          label='Abusive Behavior'
        />
        <FormControlLabel value='3' control={<Radio />} label='Other' />
        <TextField
          name='additionalInformation'
          label='Additional Information (Optional)'
          variant='standard'
          sx={{ marginTop: '12px' }}
          multiline
          rows={4}
          value={values.additionalInformation}
          onChange={handleChange}
          helperText={errors.additionalInformation ?? ''}
          error={!!errors.additionalInformation}
          fullWidth
        />
      </RadioGroup>
    </BaseModal>
  );
};

export default VideoRejectionReasonModal;
