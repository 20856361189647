import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Events.scss';
import { addAllEvents, useAppDispatch, useAppSelector } from '../../state';
import SearchInput from '../../components/SearchInput/SearchInput';
import Filter from '../../components/Filter/Filter';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import Loader from '../../components/Loader/Loader';
import {
  FilterOptionType,
  IEventPanel,
  IMessage,
  IUserPanel
} from '../../core/types';
import { useEffect, useState } from 'react';
import { getExperiences } from '../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../core/consts';
import appToast from '../../core/toast';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import { searchEventsFilter } from '../../core/helpers/search-helpers';
import { eventFilterOptions } from '../../core/helpers/filter-helpers';
import {
  GetAllEventsOnlyResponse,
  UserViewModel
} from '../../core/backend/models';
import EventsTableHeader from '../../components/TableHeaders/EventsTableHeader/EventsTableHeader';
import EventRow from '../../components/TableRows/EventRow/EventRow';
import EditEventPanel from '../../components/DetailsPanel/EditEventPanel/EditEventPanel';

const Events = () => {
  const dispatch = useAppDispatch();
  const events = useAppSelector((state) => state.events);
  const [eventsPage, setEventsPage] = useState(0);
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [eventPanel, setEventPanel] = useState<IEventPanel>({
    isOpen: false,
    eventId: null,
    relatedRowIndex: -1
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [currentEvents, setCurrentEvents] =
    useState<GetAllEventsOnlyResponse[]>(events);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEvents();
  }, []);

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setEventsPage(newPage);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: GetAllEventsOnlyResponse) => {
    return item.gigId;
  };

  /**
   * Actions
   */

  const getEvents = async () => {
    try {
      setIsLoading(true);

      const events = await getExperiences(0, 1000);

      dispatch(addAllEvents(events));
      setCurrentEvents(events);
    } catch (e: any) {
      const error = e.response?.date?.description ?? DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterEventsWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const statusFilters: string[] = selectedFilters.map((filter) => {
        return filter.value;
      });

      let filteredEvents = events;
      if (statusFilters.includes('recurrent')) {
        filteredEvents = filteredEvents.filter(
          (report: GetAllEventsOnlyResponse) => report.isRecurrentEvent
        );
      } else if (statusFilters.includes('single')) {
        filteredEvents = filteredEvents.filter(
          (report: GetAllEventsOnlyResponse) => !report.isRecurrentEvent
        );
      }

      if (statusFilters.includes('private')) {
        filteredEvents = filteredEvents.filter(
          (report: GetAllEventsOnlyResponse) => report.isPrivateExperience
        );
      } else if (statusFilters.includes('public')) {
        filteredEvents = filteredEvents.filter(
          (report: GetAllEventsOnlyResponse) => !report.isPrivateExperience
        );
      }

      setCurrentEvents(filteredEvents);
      return;
    }
  };

  const handleSearchContent = (query: string) => {
    if (!query.length) {
      setEventsPage(0);
      setCurrentEvents(events);
      return;
    }

    const _result = events.filter(searchEventsFilter(query.toLowerCase()));

    setEventsPage(0);
    setCurrentEvents(_result);
  };

  /**
   * Side Panel
   */

  /*const handlePreviewExperience =
      (Event: GetAllEventsOnlyResponse, relatedRowIndex: number, initialTab = 0) =>
      () => {
        setEventPanel({
          ...EventPanel,
          isOpen: true,
          EventId: Event.id,
          experienceId: Event.gig?.id ?? null,
          relatedRowIndex,
          initialTab
        });
      };*/

  const handlePreviewUser =
    (user: UserViewModel, relatedRowIndex: number) => () => {
      setUserPanel({ ...userPanel, user, relatedRowIndex, isOpen: true });
    };

  const handleEditEvent = (eventId: number, relatedRowIndex: number) => () => {
    setEventPanel({ ...eventPanel, eventId, relatedRowIndex, isOpen: true });
  };

  const closeUserPanel = () => {
    setUserPanel({
      ...userPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  const closeEventPanel = () => {
    setEventPanel({
      ...eventPanel,
      isOpen: false,
      eventId: null,
      relatedRowIndex: -1
    });
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='Events'>
        <div className='Events__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSearchContent}
            className='Events__search'
          />
          <Filter
            options={eventFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentEvents(events);
                return;
              }

              filterEventsWith(selectedFilters);
            }}
          />
        </div>

        <div className='Events__content'>
          <BasicTable
            Header={EventsTableHeader}
            data={currentEvents}
            idFromItem={idFromItem}
            page={eventsPage}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrderBy='status'
            renderHeader={(headerProps) => (
              <EventsTableHeader {...headerProps} />
            )}
            renderItem={(event, index) => (
              <EventRow
                key={`Event-row-${index}`}
                event={event}
                onPreviewProvider={handlePreviewUser(event.provider, index)}
                onEditEvent={handleEditEvent(event.gigId, index)}
              />
            )}
          />
        </div>
      </div>

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={closeUserPanel}
      />

      <EditEventPanel
        isOpen={eventPanel.isOpen}
        eventId={eventPanel.eventId}
        onClose={closeEventPanel}
      />

      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
      />

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default Events;
