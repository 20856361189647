import TableCell from '@mui/material/TableCell';
import { getMediaFile } from '../../../core/api';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import './CouponReportRow.scss';
import UserImage from '../../UserImage/UserImage';
import { UserRedemptionCodeViewModel } from '../../../core/backend/models';
import { convertToCurrency } from '../../../core/helpers/helpers';

interface IProps extends IBaseRowProps {
  report: UserRedemptionCodeViewModel;
  onViewExperience: React.MouseEventHandler;
  onViewUser: React.MouseEventHandler;
}

const CouponReportRow = ({
  report,
  selected,
  onViewUser,
  onViewExperience
}: IProps) => {
  return (
    <BaseRow selected={selected}>
      <TableCell align='left' sx={{ maxWidth: '130px' }}>
        <div className='CouponReportRow__code'>{report.code}</div>
      </TableCell>

      <TableCell align='left' sx={{ maxWidth: '130px' }}>
        {report.coupon}
      </TableCell>

      <TableCell align='right' sx={{ maxWidth: '90px' }}>
        {report.amount
          ? convertToCurrency(report.amount)
          : `${report.percentage}%`}
      </TableCell>

      <TableCell align='left' sx={{ maxWidth: '130px' }}>
        {report.bearerName}
      </TableCell>

      <TableCell align='left' sx={{ maxWidth: '130px' }}>
        <div
          className='CouponReportRow__experienceName'
          onClick={onViewExperience}
        >
          {report.gig.name}
        </div>
      </TableCell>

      <TableCell component='th' scope='row' style={{ maxWidth: '200px' }}>
        <div className='CouponReportRow__userInfo' onClick={onViewUser}>
          <UserImage
            className='CouponReportRow__image'
            url={getMediaFile(report.customer.image)}
          />
          <div className='CouponReportRow__userNameWrapper'>
            <div className='CouponReportRow__userName'>
              @{report.customer.userName}
            </div>
            <div className='CouponReportRow__userFullName'>
              {report.customer.fullName}
            </div>
          </div>
        </div>
      </TableCell>

      <TableCell component='th' scope='row' style={{ maxWidth: '200px' }}>
        <div className='CouponReportRow__userInfo' onClick={onViewUser}>
          <UserImage
            className='CouponReportRow__image'
            url={getMediaFile(report.booking.gig.provider.image)}
          />
          <div className='CouponReportRow__userNameWrapper'>
            <div className='CouponReportRow__userName'>
              @{report.booking.gig.provider.userName}
            </div>
            <div className='CouponReportRow__userFullName'>
              {report.booking.gig.provider.fullName}
            </div>
          </div>
        </div>
      </TableCell>

      <TableCell align='right' sx={{ maxWidth: '90px' }}>
        {convertToCurrency(report.gig.price)}
      </TableCell>
      <TableCell align='right' sx={{ maxWidth: '90px' }}>
        {convertToCurrency(
          report.amount !== 0
            ? report.gig.price - report.amount
            : report.gig.price - report.gig.price / report.percentage
        )}
      </TableCell>
    </BaseRow>
  );
};

export default CouponReportRow;
