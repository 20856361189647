import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/material';

interface IStatusObject {
  [key: string]: { label: string; backColor: string; foreColor: string };
}

interface IProps {
  status: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const Status = ({ status = 'new', sx, size }: IProps) => {
  const type: IStatusObject = {
    new: { label: 'New', backColor: '#FF6F00', foreColor: '#FFFFFF' },
    completed: {
      label: 'Completed',
      backColor: '#0B79D0',
      foreColor: '#FFFFFF'
    },
    confirmed: {
      label: 'Confirmed',
      backColor: '#0B79D0',
      foreColor: '#FFFFFF'
    },
    review: {
      label: 'Under Review',
      backColor: '#0B79D0',
      foreColor: '#FFFFFF'
    },
    reported: { label: 'Reported', backColor: '#E0E0E0', foreColor: '#000000' },
    pending: {
      label: 'Pending',
      backColor: '#E0E0E0',
      foreColor: '#000000'
    },
    declined: { label: 'Declined', backColor: '#E91E63', foreColor: '#FFFFFF' },
    rejected: { label: 'Rejected', backColor: '#E91E63', foreColor: '#FFFFFF' },
    approved: { label: 'Approved', backColor: '#B4FFC4', foreColor: '#000000' },
    'in progress': {
      label: 'In Progress',
      backColor: '#B4FFC4',
      foreColor: '#000000'
    },
    'on time': { label: 'On Time', backColor: '#B4FFC4', foreColor: '#000000' },
    aware: { label: 'On Time', backColor: '#FF6F00', foreColor: '#FFFFFF' },
    overdue: { label: 'Overdue', backColor: '#E91E63', foreColor: '#FFFFFF' },
    recurrent: {
      label: 'Recurrent',
      backColor: '#0B79D0',
      foreColor: '#FFFFFF'
    }
  };

  return (
    <div className={classNames('Status')}>
      <Chip
        label={type[status].label}
        size={size}
        sx={{
          backgroundColor: `${type[status].backColor}`,
          color: `${type[status].foreColor}`,
          ...sx
        }}
      />
    </div>
  );
};

export default Status;
