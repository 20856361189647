import TableCell from '@mui/material/TableCell';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PasswordIcon from '@mui/icons-material/Password';
import LockResetIcon from '@mui/icons-material/LockReset';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import './UserRow.scss';
import UserImage from '../../UserImage/UserImage';
import { IUserRow } from '../../../core/types';
import { IconButton, ListItemIcon, ListItemText, Rating } from '@mui/material';
import { useState } from 'react';

interface IProps extends IBaseRowProps {
  user: IUserRow;
  selected: boolean;
  onSelect: React.MouseEventHandler;
  onEdit: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
  onChangeVisibility: React.MouseEventHandler;
  onRestrictAccess: React.MouseEventHandler;
  onChangePassword: React.MouseEventHandler;
  onResetPassword: React.MouseEventHandler;
  onViewDetails?: React.MouseEventHandler;
  onChangeScore?: (newScore: number, prevScore: number) => void;
}

const UserRow = ({
  user,
  selected,
  onSelect,
  onEdit,
  onDelete,
  onRestrictAccess,
  onChangeVisibility,
  onChangePassword,
  onResetPassword,
  onViewDetails,
  onChangeScore
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell component='th' scope='row' style={{ maxWidth: '200px' }}>
        <div className='UserRow__userInfo' onClick={onViewDetails}>
          <UserImage
            className='UserRow__image'
            url={getMediaFile(user.image)}
          />
          <div className='UserRow__userNameWrapper'>
            <div className='UserRow__userName'>@{user.userName}</div>
            <div className='UserRow__userFullName'>{user.fullName}</div>
          </div>
        </div>
        <Rating
          name='simple-controlled'
          value={user.userScoring}
          max={10}
          onChange={(event, newValue) => {
            onChangeScore(newValue, user.scores.providerScore);
          }}
        />
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <p className='UserRow__cellRow'>{user.formattedCreatedOnDate}</p>
        <p className='UserRow__cellRow'>{user.formattedCreatedOnTime}</p>
      </TableCell>

      <TableCell style={{ maxWidth: '200px' }}>
        <div className='UserRow__email'>{user.email}</div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <div className='UserRow__capital'>{user.accountStatus}</div>
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <p className='UserRow__cellRow'>{user.formattedDeactivatedOnDate}</p>
        <p className='UserRow__cellRow'>{user.formattedDeactivatedOnTime}</p>
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        {user.isCreatorPro
          ? user.isProviderPro
            ? 'Creator Pro'
            : 'Creator'
          : 'User'}
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        {user.isAdmin ? 'Admin' : 'Normal'}
      </TableCell>

      <TableCell align='center'>
        <IconButton color='primary' onClick={onEdit} title='Edit Account'>
          <EditIcon />
        </IconButton>
        <IconButton
          color={user.accountVisibility ? 'primary' : 'warning'}
          onClick={onChangeVisibility}
          title={user.accountVisibility ? 'Hide Account' : 'Show Account'}
        >
          {user.accountVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
        <IconButton
          color={user.restrictAccess ? 'warning' : 'primary'}
          onClick={onRestrictAccess}
          title={
            user.restrictAccess ? 'Grant User Access' : 'Restrict User Access'
          }
        >
          {user.restrictAccess ? (
            <NoEncryptionGmailerrorredIcon />
          ) : (
            <LockOpenIcon />
          )}
        </IconButton>
        <IconButton color='primary' onClick={handleClick} title='User Password'>
          <PasswordIcon />
        </IconButton>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={onChangePassword}>
            <ListItemIcon>
              <LockResetIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Change User Password</ListItemText>
          </MenuItem>
          <MenuItem onClick={onResetPassword}>
            <ListItemIcon>
              <ForwardToInboxIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Reset Password</ListItemText>
          </MenuItem>
        </Menu>

        <IconButton color='error' onClick={onDelete} title='Delete Account'>
          <ClearIcon />
        </IconButton>
      </TableCell>
    </BaseRow>
  );
};

export default UserRow;
