import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import { getMediaFile } from '../../../core/api';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { UserPaymentLogViewModel } from '../../../core/backend/models';
import './PaymentRow.scss';
import UserImage from '../../UserImage/UserImage';

interface IProps extends IBaseRowProps {
  payment: UserPaymentLogViewModel;
}

const PaymentRow = ({ payment }: IProps) => {
  const date = dayjs(payment.dateOfTransaction);
  const formattedDate = date.format('MM/DD/YY hh:mm a');

  return (
    <BaseRow>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <b>{payment.reservationId}</b>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        $ {payment.amount.toFixed(2)}
      </TableCell>
      <TableCell sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}>
        {payment.paymentType === 1
          ? 'Commercial'
          : payment.paymentType === 2
          ? 'Charity'
          : payment.paymentType === 3
          ? 'Tip'
          : 'Unknown'}
      </TableCell>
      <TableCell component='th' scope='row' style={{ maxWidth: '140px' }}>
        <div className='PaymentRow__userInfo'>
          <UserImage
            className='PaymentRow__image'
            url={getMediaFile(payment.user?.image)}
          />
          <div className='PaymentRow__userNameWrapper'>
            <div className='PaymentRow__userName'>
              @{payment.user?.userName}
            </div>
            <div className='PaymentRow__userFullName'>
              {payment.user?.fullName}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        {formattedDate}
      </TableCell>
    </BaseRow>
  );
};

export default PaymentRow;
