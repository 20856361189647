import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IExperienceLogRow, ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';

const BookingLogsTableHeader: React.FC<
  ITableHeaderProps<IExperienceLogRow>
> = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler =
    (property: keyof IExperienceLogRow) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Reservation
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'dateOfTransaction'}
            direction={orderBy === 'dateOfTransaction' ? order : 'asc'}
            onClick={createSortHandler('dateOfTransaction')}
          >
            Date of Transaction
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal'>Creator</TableCell>
        <TableCell align='center' padding='normal'>
          User Type
        </TableCell>
        <TableCell align='center' padding='normal'>
          Status
        </TableCell>
        <TableCell padding='normal'>Booking Amount</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BookingLogsTableHeader;
