import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ITableHeaderProps, IUserRow } from '../../../core/types';
import { TableSortLabel } from '@mui/material';

const UserTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  onRequestSort,
  order,
  orderBy
}: ITableHeaderProps<IUserRow>) => {
  const createSortHandler =
    (property: keyof IUserRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>

        <TableCell align='left' padding='normal'>
          <TableSortLabel
            active={orderBy === 'userName'}
            direction={orderBy === 'userName' ? order : 'asc'}
            onClick={createSortHandler('userName')}
          >
            User
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'createdOn'}
            direction={orderBy === 'createdOn' ? order : 'asc'}
            onClick={createSortHandler('createdOn')}
          >
            Created On
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal'>Email</TableCell>
        <TableCell align='center' padding='normal'>
          Account Status
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === ''}
            direction={orderBy === 'deactivatedOn' ? order : 'asc'}
            onClick={createSortHandler('deactivatedOn')}
          >
            Deactivated On
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          Creator
        </TableCell>
        <TableCell align='center' padding='normal'>
          Type
        </TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default UserTableHeader;
