import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Scrollbar from 'react-scrollbars-custom';
import { Marker } from '@react-google-maps/api';
import { GigViewModel } from '../../../core/backend/models';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './DetailsTab.scss';
import { ICoordinates } from '../../../core/types';
import {
  translateHostingType,
  translateLocationType,
  translatePricingModel
} from '../../../core/helpers/enums-helpers';
import Map from '../../Map/Map';
import { getExcelEventInformation } from '../../../core/api';

interface IProps extends IBaseTabProps {
  experience?: GigViewModel;
  isResolved?: boolean;
  bookingLatLng?: ICoordinates | null;
  onResolve?: React.MouseEventHandler;
}

const DetailsTab = ({
  experience,
  isResolved,
  bookingLatLng,
  onResolve,
  ...props
}: IProps) => {
  const downloadExcelFile = async () => {
    try {
      const response = await getExcelEventInformation(experience.id);
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'booking_details.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the Excel file', error);
    }
  };

  const openLocation = () => {
    window.open(
      `https://www.google.com/maps/search/${bookingLatLng?.lat},${bookingLatLng?.lng}`
    );
  };

  return (
    <BaseTab {...props} className='DetailsTab'>
      <Scrollbar
        noScrollX={true}
        thumbYProps={{ className: 'thumbX' }}
        trackYProps={{ className: 'trackX' }}
        height='100%'
        width='100%'
      >
        <Grid
          container
          style={{ padding: '17px 12px 12px' }}
          direction={'column'}
          rowSpacing={2}
        >
          <Grid item>
            <TextField
              id='title'
              label='Title'
              variant='standard'
              value={experience.name}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id='category'
              label='Category'
              variant='standard'
              value={experience.category?.name ?? 'Undefined'}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id='type'
              label='Type'
              variant='standard'
              value={translateHostingType(experience.hostingType)}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          {experience.hostingType !== 0 && (
            <>
              <Grid item>
                <TextField
                  id='location'
                  label='Location'
                  variant='standard'
                  value={translateLocationType(experience.locationType)}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>

              {bookingLatLng && (
                <Grid item>
                  <div className='DetailsTab__mapContainer'>
                    <Map center={bookingLatLng}>
                      <Marker position={bookingLatLng} />
                    </Map>
                  </div>
                  <Button
                    fullWidth
                    variant='text'
                    sx={{ marginTop: '7px' }}
                    onClick={openLocation}
                  >
                    View in Google Maps
                  </Button>
                </Grid>
              )}
            </>
          )}
          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                id='pricing'
                label='Pricing model'
                variant='standard'
                value={translatePricingModel(experience.pricingModel)}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant='standard'>
                <InputLabel htmlFor='price'>Price</InputLabel>
                <Input
                  id='price'
                  value={experience.price}
                  readOnly
                  startAdornment={
                    <InputAdornment position='start'>$</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              id='description'
              label='Description'
              multiline
              fullWidth
              maxRows={14}
              variant='standard'
              value={experience.description}
              inputProps={{
                readOnly: true
              }}
            />
          </Grid>

          <Grid item>
            <div>
              <Button
                onClick={downloadExcelFile}
                variant='contained'
                color='primary'
              >
                Download Details
              </Button>
            </div>
          </Grid>

          <Grid item>
            {onResolve && !isResolved && (
              <Button
                fullWidth
                variant='contained'
                sx={{ marginTop: '17px' }}
                onClick={onResolve}
              >
                Resolve issue
              </Button>
            )}
            {isResolved && <div className='DetailsTab__resolved'>Resolved</div>}
          </Grid>
        </Grid>
      </Scrollbar>
    </BaseTab>
  );
};

export default DetailsTab;
