import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { getMediaFile } from '../../core/api';
import {
  AdminCommentViewModel,
  UserViewModel
} from '../../core/backend/models';
import UserImage from '../UserImage/UserImage';
import './AdminNote.scss';
import { useAppSelector } from '../../state';

interface IProps {
  note: AdminCommentViewModel;
  onDeleteNote: React.MouseEventHandler;
  className?: string;
}

const AdminNote = ({ note, onDeleteNote, className }: IProps) => {
  const { auth } = useAppSelector((state) => state);
  const date = new Date(note.createdOnUnix);
  return (
    <div className={classNames(`AdminNote ${className}`)}>
      <UserImage
        url={getMediaFile(note.admin.image)}
        letter={note.admin.fullName[0]}
        className='AdminNote__userImage'
      />
      <div className='AdminNote__bubble'>
        <div className='AdminNote__author'>{note.admin.fullName}</div>
        <div className='AdminNote__content'>{note.comment}</div>
      </div>
      <div className='AdminNote__controls'>
        <div className='AdminNote__time'>{dayjs(date).format('h:mma')}</div>
        {note.admin.id === auth.user.id && (
          <IconButton
            className='AdminNote__deleteNote'
            aria-label='delete'
            size='small'
            color='error'
            onClick={onDeleteNote}
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default AdminNote;
