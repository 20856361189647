import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Scrollbar from 'react-scrollbars-custom';
import ClearIcon from '@mui/icons-material/Clear';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './AdditionalInformationTab.scss';
import { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import appToast from '../../../core/toast';

interface IProps extends IBaseTabProps {
  hashtags?: string[];
  onAddTag: (tag: string) => void;
  onRemoveTag: (index: number) => () => void;
}

const AdditionalInformationTab = ({
  hashtags = [],
  onAddTag,
  onRemoveTag,
  ...props
}: IProps) => {
  const [tag, setTag] = useState<string>('');

  const handleAddTag = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (tag[tag.length - 1] === '#') {
        return;
      }

      if (tag.includes('#')) {
        const _tag = tag.split('#').pop();

        if (hashtags.includes(_tag)) {
          return appToast.showError('Tag already added');
        }

        onAddTag(_tag);
        setTag('');
      }
    }
  };

  const handleChangeTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  return (
    <BaseTab {...props} className='AdditionalInformationTab'>
      <Scrollbar
        noScrollX={true}
        thumbYProps={{ className: 'thumbX' }}
        trackYProps={{ className: 'trackX' }}
        height='100%'
        width='100%'
      >
        <Grid
          container
          style={{ padding: '17px 12px 12px' }}
          direction={'column'}
          rowSpacing={2}
        >
          <Grid item>
            <TextField
              id='name'
              label='Tags'
              variant='standard'
              value={tag}
              onChange={handleChangeTag}
              onKeyPress={handleAddTag}
              fullWidth
            />
            <div className='AdditionalInformationTab__tags'>
              {hashtags.map((tag, index) => (
                <div key={index} className='AdditionalInformationTab__tag'>
                  <div className='AdditionalInformationTab__tagLabel'>
                    {tag}
                  </div>
                  <IconButton
                    className='AdditionalInformationTab__tagRemoveButton'
                    onClick={onRemoveTag(index)}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Scrollbar>
    </BaseTab>
  );
};

export default AdditionalInformationTab;
