import * as yup from 'yup';

export default yup.object({
  password: yup
    .string()
    .min(6, 'Password must be 6 characters at least.')
    .required('Required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match")
    .required('Required.')
});
