import { IModalProps, ItemQuantity } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './PaymentDetailsModal.scss';
import {
  BookingTicketViewModel,
  ProviderBookingPayoutSummaryViewModel
} from '../../../core/backend/models';
import Loader from '../../Loader/Loader';
import { convertToCurrency } from '../../../core/helpers/helpers';
import { HOUR_IN_SECONDS } from '../../../core/consts';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';

interface Props extends IModalProps {
  payouts: ProviderBookingPayoutSummaryViewModel;
  tickets: BookingTicketViewModel[];
}

type TabType = 'customer' | 'creator';

const PaymentDetailsModal = ({
  isProcessing,
  payouts,
  tickets,
  onAccept,
  ...props
}: Props) => {
  const [tab, setTab] = useState<TabType>('creator');

  const handleTab = (event: React.MouseEvent<HTMLElement>, newTab: TabType) => {
    setTab(newTab);
  };

  const handleOnAcceptModal = () => {
    setTab('creator');
    onAccept();
  };

  let ticketsSubtoal = 0;

  const _tickets = tickets?.reduce<ItemQuantity[]>((acc, ticket) => {
    const existingIndex = acc.findIndex(
      (_ticket) => _ticket.name === ticket.ticketName
    );
    if (existingIndex > -1) {
      acc[existingIndex].quantity += 1;
      acc[existingIndex].amount += ticket.price;
    } else
      acc.push({
        name: ticket.ticketName,
        price: ticket.price,
        quantity: 1,
        amount: ticket.price
      });

    ticketsSubtoal += ticket.price;
    return acc;
  }, []);

  const isHourly = payouts?.booking?.gig?.pricingModel === 0;
  const experiencePrice = payouts?.booking?.gig?.price ?? 0;
  const bookingPrice = isHourly
    ? payouts?.booking?.price ?? 0
    : experiencePrice;
  const totalBookedHours = payouts
    ? payouts?.booking?.duration / HOUR_IN_SECONDS
    : 0;
  const total = payouts ? payouts?.subTotal + payouts.creatorTips : 0;

  return (
    <BaseModal
      title='Payment Details'
      onAccept={handleOnAcceptModal}
      {...props}
    >
      {isProcessing ? (
        <Loader adaptToParent />
      ) : (
        <>
          <div className='PaymentDetailsModal__tabs'>
            <ToggleButtonGroup
              value={tab}
              exclusive
              onChange={handleTab}
              size='small'
              color='secondary'
            >
              <ToggleButton value='creator'>Creator</ToggleButton>
              <ToggleButton value='customer'>Customer</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className='PaymentDetailsModal__content'>
            {_tickets?.length > 0 ? (
              <>
                <div className='PaymentDetailsModal__tickets'>
                  <div className='PaymentDetailsModal__description'>
                    Tickets
                  </div>
                  <div className='PaymentDetailsModal__ticketList'>
                    {_tickets.map((ticket, idx) => (
                      <div key={idx} className='PaymentDetailsModal__ticket'>
                        <div className='PaymentDetailsModal__ticketName'>
                          {ticket.name}
                        </div>
                        <div className='PaymentDetailsModal__ticketQuantity'>
                          {convertToCurrency(ticket.price)} x {ticket.quantity}
                        </div>
                        <div className='PaymentDetailsModal__ticketAmount'>
                          {convertToCurrency(ticket.amount)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Subtotal Tickets
                  </div>
                  <div className='PaymentDetailsModal__amount PaymentDetailsModal__amount--fee'>
                    {convertToCurrency(ticketsSubtoal)}
                  </div>
                </div>
              </>
            ) : (
              <div className='PaymentDetailsModal__transferItem'>
                <div className='PaymentDetailsModal__description'>
                  {payouts?.booking?.gig?.oneEventTime ? 'Event' : 'Experience'}{' '}
                  Cost
                </div>
                <div className='PaymentDetailsModal__amount'>
                  {isHourly
                    ? `${convertToCurrency(
                        experiencePrice
                      )}/hr x ${totalBookedHours} = `
                    : ''}
                  {convertToCurrency(bookingPrice)}
                </div>
              </div>
            )}

            {payouts?.promotionUsed && (
              <>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Discount Code
                  </div>
                  <div className='PaymentDetailsModal__amount'>
                    {payouts.promotionUsed.code}
                  </div>
                </div>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Discount
                  </div>
                  <div className='PaymentDetailsModal__amount'>
                    {convertToCurrency(payouts.promotionUsed.value)}
                  </div>
                </div>
              </>
            )}

            {tab === 'creator' ? (
              <>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Hoopla Service Fee
                  </div>
                  <div className='PaymentDetailsModal__amount PaymentDetailsModal__amount--fee'>
                    {convertToCurrency(payouts?.hooplaFee ?? 0)}
                  </div>
                </div>

                {payouts?.payouts?.length > 0 && (
                  <div className='PaymentDetailsModal__payouts'>
                    {payouts.payouts.map((transfer, idx) => (
                      <div
                        key={idx}
                        className='PaymentDetailsModal__transferItem'
                      >
                        <div className='PaymentDetailsModal__description'>
                          <div className='PaymentDetailsModal__payoutType'>
                            {transfer.type}
                          </div>
                          <div className='PaymentDetailsModal__payoutDescription'>
                            {transfer.description}
                          </div>
                        </div>
                        <div className='PaymentDetailsModal__amount'>
                          {convertToCurrency(transfer.amount)}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Subtotal
                  </div>
                  <div className='PaymentDetailsModal__amount'>
                    {convertToCurrency(payouts?.subTotal ?? 0)}
                  </div>
                </div>

                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Creator's Tip
                  </div>
                  <div className='PaymentDetailsModal__amount'>
                    {convertToCurrency(payouts?.creatorTips ?? 0)}
                  </div>
                </div>

                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description PaymentDetailsModal__total'>
                    Creator Earnings
                  </div>
                  <div className='PaymentDetailsModal__amount PaymentDetailsModal__total'>
                    {convertToCurrency(total)}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>
                    Service Fee
                  </div>
                  <div className='PaymentDetailsModal__amount'>
                    {convertToCurrency(payouts?.customerFee - ticketsSubtoal)}
                  </div>
                </div>
                <div className='PaymentDetailsModal__transferItem'>
                  <div className='PaymentDetailsModal__description'>Total</div>
                  <div className='PaymentDetailsModal__amount'>
                    {convertToCurrency(payouts?.customerFee)}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default PaymentDetailsModal;
