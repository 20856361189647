import { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import Loader from '../../components/Loader/Loader';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import CommentRow from '../../components/TableRows/CommentRow/CommentRow';
import CommentsTable from '../../components/Tables/CommentsTable/CommentsTable';
import { deleteComment, getAllComments } from '../../core/api';
import { CommentViewModel, UserViewModel } from '../../core/backend/models';
import { COMMENTS_PER_PAGE, DEFAULT_ERROR_MESSAGE } from '../../core/consts';
import appToast from '../../core/toast';
import { IMessage, IUserPanel } from '../../core/types';
import './ModerateComments.scss';
import { SortType } from '../../core/enums';
import SearchInput from '../../components/SearchInput/SearchInput';

interface IDeleteModal {
  isOpen: boolean;
  commentId?: number | null;
}

const ModerateComments = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [deleteModal, setDeleteModal] = useState<IDeleteModal>({
    isOpen: false,
    commentId: null
  });
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [comments, setComments] = useState<CommentViewModel[]>([]);
  const [currentComments, setCurrentComments] = useState<CommentViewModel[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [order, setOrder] = useState<SortType>(SortType.Desc);

  /**
   * Request all reports
   */

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (comments.length === 0) return;

    pageContent();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [page, order, comments]);

  const pageContent = () => {
    const startIndex = (page - 1) * COMMENTS_PER_PAGE;
    const endIndex = startIndex + COMMENTS_PER_PAGE;

    const _comments = comments.slice(startIndex, endIndex);
    setCurrentComments(_comments);
  };

  const getComments = async () => {
    try {
      setIsLoadingMore(true);

      const _comments = await getAllComments(
        null,
        skip,
        page * COMMENTS_PER_PAGE,
        order
      );

      if (_comments.length) {
        setComments([...comments, ..._comments]);
        setSkip((skip) => skip + _comments.length);
      }

      setHasMore(_comments.length > 0);
    } catch (error) {
      appToast.showError('Failed getting comments');
    } finally {
      setIsProcessing(false);
      setIsLoadingMore(false);
    }
  };

  const deleteComments = async (items: number | number[]) => {
    try {
      setIsDeleting(true);

      const _items = Array.isArray(items) ? items : [items];
      const reqs = _items.map((commentId) => deleteComment(commentId));
      await Promise.all(reqs);

      //Remove locally
      const _comments = comments.filter(
        (comment) => !_items.includes(comment.id)
      );

      setComments(_comments);
      handleCloseDeleteComment();
      openSnack('Comment(s) deleted');
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: e?.response?.data?.description ?? null
      });
    } finally {
      setIsDeleting(false);
    }
  };

  // /**
  //  * Init Infinite Scroll
  //  */
  // const [sentryRef] = useInfiniteScroll({
  //   loading: isLoadingMore,
  //   hasNextPage: hasMore,
  //   onLoadMore: getComments,
  //   disabled: undefined
  // });

  /**
   * Table methods
   */

  /**
   * Actions
   */
  const handleSeachContent = async (query: string) => {
    try {
      if (!query.length) {
        setIsSearching(false);
        setCurrentComments(comments);
        return;
      }

      setIsSearching(true);

      const commentsResult = await getAllComments(query, 0, 50, order);
      setCurrentComments(commentsResult);
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ?? DEFAULT_ERROR_MESSAGE
      );
    } finally {
      setIsSearching(false);
    }
  };

  const handleDeleteComment = async () => {
    await deleteComments(deleteModal.commentId);

    if (selectedItems.includes(deleteModal.commentId)) {
      const _selectedItems = selectedItems.filter(
        (commentId) => commentId !== deleteModal.commentId
      );

      setSelectedItems(_selectedItems);
    }
  };

  const handleDeleteAllComments = async () => {
    await deleteComments(selectedItems);
    setSelectedItems([]);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const handleSortDate = async () => {
    try {
      setIsProcessing(true);

      const newSort = order === SortType.Asc ? SortType.Desc : SortType.Asc;
      const _comments = await getAllComments(
        null,
        0,
        COMMENTS_PER_PAGE,
        newSort
      );

      if (_comments.length) {
        setComments(_comments);
        setSkip(_comments.length);
      }

      setOrder(newSort);
    } catch (error) {
      appToast.showError('Failed getting comments');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePrevPage = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleNextPage = async () => {
    if (!hasMore) return;

    const startIndex = page * COMMENTS_PER_PAGE;
    const endIndex = startIndex + COMMENTS_PER_PAGE;

    if (endIndex >= comments.length - 1) {
      await getComments();
    }

    setPage((prevState) => prevState + 1);
  };

  /**
   * Side Panel
   */

  const handleViewUser = (user: UserViewModel) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setUserPanel({ isOpen: true, user });
  };

  const handleCloseUserPanel = () => {
    setUserPanel({ ...userPanel, isOpen: false });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  /**
   * Modal actions
   */

  const handleShowDeleteModal = (commentId?: number) => () => {
    setDeleteModal({ isOpen: true, commentId });
  };
  const handleCloseDeleteComment = () => {
    setDeleteModal({ isOpen: false, commentId: null });
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  if (isProcessing) return <Loader adaptToParent size={60} />;

  return (
    <>
      <div className='ModerateComments'>
        <div className='ModerateComments__filterControls'>
          <SearchInput
            isProcessing={isSearching}
            onDebouncedTextChange={handleSeachContent}
            className='ModerateComments__search'
          />
        </div>

        <div className='ModerateComments__content'>
          <CommentsTable
            data={currentComments}
            order={order}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            onSort={handleSortDate}
            renderItem={(row, index, { isSelected, handleSelectRow }) => {
              return (
                <CommentRow
                  key={`booking-row-${index}`}
                  comment={row}
                  selected={isSelected}
                  onDelete={handleShowDeleteModal(row.id)}
                  onSelect={handleSelectRow(row.id)}
                  onPreviewUser={handleViewUser}
                />
              );
            }}
          />
        </div>
        {/* {(isLoadingMore || hasMore) && (
          <div ref={sentryRef}>
            <Loader adaptToParent size={32} />
          </div>
        )} */}

        <div className='Users__pageButtons'>
          <IconButton
            color='secondary'
            onClick={handlePrevPage}
            disabled={isLoadingMore || page === 1}
            size='large'
          >
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
          <IconButton
            color='secondary'
            onClick={handleNextPage}
            disabled={isLoadingMore || !hasMore}
            size='large'
          >
            <NavigateNextIcon fontSize='large' />
          </IconButton>
          {isLoadingMore && (
            <Loader className='Users__loader' adaptToParent size={32} />
          )}
        </div>
      </div>

      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onDeclineAll={handleShowDeleteModal()}
          declineTitle='Delete all'
        />
      )}

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={handleCloseUserPanel}
      />

      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
      />

      <BaseModal
        title='Delete Comment'
        isOpen={deleteModal.isOpen}
        acceptButtonTitle='Delete'
        onAccept={
          deleteModal.commentId ? handleDeleteComment : handleDeleteAllComments
        }
        isProcessing={isDeleting}
        onClose={handleCloseDeleteComment}
      >
        Are you sure you want to delete the selected comment(s)? This action can
        not be undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default ModerateComments;
