import TableCell from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getMediaFile } from '../../../core/api';
import './StatisticRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import { IStatsRow } from '../../../core/types';
import { convertToCurrency } from '../../../core/helpers/helpers';
import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material';
import { SERVICE_FEE } from '../../../core/consts';

interface IProps extends IBaseRowProps {
  stats: IStatsRow;
  onPreviewUser: React.MouseEventHandler;
}

const StatisticRow = ({ stats, selected, onClick, onPreviewUser }: IProps) => {
  const [showBookingList, setShowBookingList] = useState(false);

  const handleToggleBookingList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowBookingList((prevState) => !prevState);
  };

  return (
    <>
      <BaseRow selected={showBookingList || selected} onClick={onClick}>
        <TableCell
          style={{ verticalAlign: 'top', width: '350px', maxWidth: '350px' }}
        >
          <div className='StatisticRow__userInfo' onClick={onPreviewUser}>
            <UserImage
              className='StatisticRow__image'
              url={getMediaFile(stats.user.image)}
            />
            <div className='StatisticRow__userNameWrapper'>
              <div className='StatisticRow__userName'>
                @{stats.user.userName}
              </div>
              <div className='StatisticRow__userFullName'>
                {stats.user.fullName}
              </div>
            </div>
            {stats.experiences.length > 0 && (
              <div className='StatisticRow__showBookingList'>
                <IconButton
                  aria-label='delete'
                  onClick={handleToggleBookingList}
                >
                  {!showBookingList ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </div>
            )}
          </div>
        </TableCell>
        <TableCell
          align='right'
          style={{ verticalAlign: 'top' }}
          sx={{ maxWidth: '140px', width: '140px' }}
        >
          {stats.totalBookings}
        </TableCell>
        <TableCell
          align='right'
          style={{ verticalAlign: 'top' }}
          sx={{ width: '210px', maxWidth: '210px' }}
        >
          {convertToCurrency(stats.sumBookingTotal)}
        </TableCell>

        <TableCell
          align='right'
          style={{ verticalAlign: 'top' }}
          sx={{ width: '210px', maxWidth: '210px' }}
        >
          {convertToCurrency(stats.serviceFee)}
        </TableCell>
        <TableCell
          align='right'
          style={{ width: '210px', maxWidth: '210px', verticalAlign: 'top' }}
        >
          {convertToCurrency(stats.sumHooplaFee)}
        </TableCell>
        <TableCell align='right' style={{ verticalAlign: 'top' }}>
          {convertToCurrency(stats.sumTotalEarnings)}
        </TableCell>
      </BaseRow>
      {stats.experiences.length > 0 && (
        <TableRow>
          <TableCell
            className='StatisticRow__bookingList'
            style={{
              paddingBottom: 0,
              paddingTop: 0
            }}
            colSpan={6}
            padding='none'
          >
            <Collapse in={showBookingList} timeout='auto' unmountOnExit>
              <Table size='small' aria-label='bookings'>
                <TableBody>
                  {stats.experiences.map((payout) => (
                    <TableRow key={payout.gigId}>
                      <TableCell
                        style={{
                          verticalAlign: 'top',
                          width: '350px',
                          maxWidth: '350px'
                        }}
                      >
                        {payout.name}
                      </TableCell>
                      <TableCell
                        align='right'
                        style={{ verticalAlign: 'top' }}
                        sx={{ maxWidth: '140px', width: '140px' }}
                      >
                        {payout.totalBookings}
                      </TableCell>
                      <TableCell
                        align='right'
                        style={{ verticalAlign: 'top' }}
                        sx={{ width: '210px', maxWidth: '210px' }}
                      >
                        {convertToCurrency(payout.sumBookingTotal)}
                      </TableCell>

                      <TableCell
                        align='right'
                        style={{ verticalAlign: 'top' }}
                        sx={{ width: '210px', maxWidth: '210px' }}
                      >
                        {convertToCurrency(payout.sumCustomerFee)}
                      </TableCell>
                      <TableCell
                        align='right'
                        style={{ width: '210px', maxWidth: '210px' }}
                      >
                        {convertToCurrency(payout.sumHooplaFee)}
                      </TableCell>
                      <TableCell align='right' style={{ verticalAlign: 'top' }}>
                        {convertToCurrency(payout.sumTotalEarnings)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default StatisticRow;
