import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps } from '../../../core/types';
import './EditEventPanel.scss';
import { useState, useEffect } from 'react';
import { CommentViewModel, GigViewModel } from '../../../core/backend/models';
import { getExperience, updateEvent } from '../../../core/api';
import Loader from '../../Loader/Loader';
import appToast from '../../../core/toast';
import AdditionalInformationTab from '../../Tabs/AdditionalInformationTab/AdditionalInformationTab';
import { Button } from '@mui/material';

interface IProps extends IPanelProps {
  eventId: number;
}

interface ICommentsState {
  isLoading: boolean;
  skip: number;
  hasMore: boolean;
  comments: CommentViewModel[];
}

const EditEventPanel = ({
  title = 'Event Details',
  eventId,
  ...props
}: IProps) => {
  const [value, setValue] = useState(0);
  const [event, setEvent] = useState<GigViewModel>(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      setValue(0);
      return;
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (eventId && props.isOpen) {
      getEventData();
    }
  }, [eventId]);

  const getEventData = async () => {
    try {
      setIsProcessing(true);
      const _event = await getExperience(eventId);
      setEvent(_event);
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ??
          'Something went wrong while loading event data. Close panel and try again.'
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateEvent = async () => {
    try {
      setIsUpdating(true);
      await updateEvent(event.id, event.gigHashtags);
      appToast.showSuccess('Event updated successfully');
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ??
          'Something went wrong while updating event data. Please try again.'
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAddtag = (tag: string) => {
    setEvent({ ...event, gigHashtags: [...event.gigHashtags, tag] });
  };

  const handleRemoveTag = (index: number) => () => {
    const tags = event.gigHashtags.filter((_, i) => i !== index);
    setEvent({ ...event, gigHashtags: tags });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='scrollable'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Additional Information' id='additional-information-tab' />
        </Tabs>
      )}
      <div className='EditEventPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <AdditionalInformationTab
              hashtags={event.gigHashtags}
              onAddTag={handleAddtag}
              onRemoveTag={handleRemoveTag}
              value={value}
              index={0}
            />
          </>
        )}
      </div>
      <div className='EditEventPanel__action'>
        <Button
          variant='contained'
          size='large'
          color='primary'
          disabled={isUpdating}
          onClick={handleUpdateEvent}
        >
          {isUpdating ? 'Updating...' : 'Update'}
        </Button>
      </div>
    </BasePanel>
  );
};

export default EditEventPanel;
