import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './core/Routes';
import { store } from './state';
import './main.scss';

const App = () => {
  return (
    <Provider store={store}>
      <div className='App'>
        <CssBaseline />
        <ToastContainer />
        <Router>
          <Routes />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
