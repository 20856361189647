import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Transactions.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import { RoutesEnum } from '../../core/enums';
import Payouts from '../Payouts/Payouts';
import Payments from '../Payments/Payments';

const Transactions = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button
            color='secondary'
            component={NavLink}
            to={`${RoutesEnum.Transactions}/payouts`}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
          >
            Payouts
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={`${RoutesEnum.Transactions}/payments`}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Payments
          </Button>
        </ButtonGroup>
      </Box>
      <Switch>
        <Route
          exact
          path={[RoutesEnum.Transactions, RoutesEnum.Payouts]}
          component={Payouts}
        />
        <Route exact path={RoutesEnum.Payments} component={Payments} />
      </Switch>
    </MainLayout>
  );
};

export default Transactions;
