import { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps } from '../../../core/types';
import VideoDetailsTab from '../../Tabs/VideoDetailsTab/VideoDetailsTab';
import CommentsTab from '../../Tabs/CommentsTab/CommentsTab';
import { getVideoComments, saveVideoNotes } from '../../../core/api';
import Loader from '../../Loader/Loader';
import {
  CommentViewModel,
  FileResponseViewModel
} from '../../../core/backend/models';
import './VideoPanel.scss';
import AddCommentsTab from '../../Tabs/AddCommentsTab/AddCommentsTab';
import { AdminCommentType } from '../../../core/enums';

interface IProps extends IPanelProps {
  video: FileResponseViewModel;
  hideComments?: boolean;
}

const VideoPanel = ({
  title = 'Clip details',
  video,
  hideComments = false,
  ...props
}: IProps) => {
  const [value, setValue] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const [comments, setComments] = useState<CommentViewModel[] | null>([]);

  useEffect(() => {
    if (props.isOpen) {
      setValue(0);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (hideComments) {
      setIsProcessing(false);
      return;
    }

    if (video && props.isOpen) {
      setIsProcessing(true);
      requestVideoComments().then(() => {
        setIsProcessing(false);
      });
    }
  }, [video]);

  const requestVideoComments = async () => {
    const _comments = await getVideoComments(video.id, 0, 30);
    setComments(_comments);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='fullWidth'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='simple-tab-0' />
          {!hideComments && <Tab label='Comments' id='simple-tab-1' />}
          {/* <Tab label='User Score' id='simple-tab-2' /> */}
          <Tab label='Notes' id='simple-tab-2' />
        </Tabs>
      )}
      <div className='VideoPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <VideoDetailsTab video={video} value={value} index={0} />
            {!hideComments && (
              <CommentsTab data={comments} value={value} index={1} />
            )}
            <AddCommentsTab
              targetId={video.id}
              adminCommentType={AdminCommentType.MediaFile}
              value={value}
              index={2}
            />
          </>
        )}
      </div>
    </BasePanel>
  );
};

export default VideoPanel;
