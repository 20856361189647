import { getMediaFile } from '../../../core/api';
import { UserViewModel } from '../../../core/backend/models';
import UserImage from '../../UserImage/UserImage';
import './UserCard.scss';

interface Props {
  user: UserViewModel;
  className?: string;
}
const UserCard = ({ user, className = '' }: Props) => {
  return (
    <div className={`UserCard ${className}`}>
      <UserImage className='UserCard__image' url={getMediaFile(user.image)} />
      <div className='UserCard__userNameWrapper'>
        {user.userName ?? user.fullName}
      </div>
    </div>
  );
};

export default UserCard;
