import { forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormikValues, useFormik } from 'formik';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import 'react-datepicker/dist/react-datepicker.css';
import './NewUserModal.scss';
import UserSchema from '../../../validators/UserSchema';

interface FormValues {
  fullName: string;
  userName: string;
  email: string;
  dateOfBirth?: Date;
  sendEmailNotification?: boolean;
}

dayjs.extend(relativeTime);
const now = dayjs();

const NewUserModal = ({ onAccept, onClose, ...props }: IModalProps) => {
  const CustomDateInput = forwardRef(
    (
      { value, onClick }: { value?: string; onClick?: React.MouseEventHandler },
      ref: any
    ) => (
      <button className='NewUserModal__datePicker' onClick={onClick} ref={ref}>
        {value}
      </button>
    )
  );

  const { values, errors, setFieldValue, handleChange, resetForm, submitForm } =
    useFormik<FormValues>({
      initialValues: {
        fullName: '',
        userName: '',
        email: '',
        dateOfBirth: now.subtract(16, 'year').toDate(),
        sendEmailNotification: false
      },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: UserSchema,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        const success = await onAccept(values);
        success && resetForm();
      }
    });

  const handleDateChange = (date: Date) => setFieldValue('dateOfBirth', date);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('sendEmailNotification', event.target.checked);
  };

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='New User'
      size='lg'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='NewUserModal__description'>
        Fill out the form below to create a new account
      </div>
      <Grid container direction={'column'} rowSpacing={2}>
        <Grid item>
          <TextField
            name='fullName'
            label='Full Name'
            variant='standard'
            value={values.fullName}
            onChange={handleChange}
            helperText={errors.fullName ?? ''}
            error={!!errors.fullName}
            fullWidth
          />
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='userName'
              label='Username'
              variant='standard'
              value={values?.userName ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>@</InputAdornment>
                )
              }}
              onChange={handleChange}
              helperText={errors.userName ?? ''}
              error={!!errors.userName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='email'
              label='Email'
              variant='standard'
              value={values?.email ?? ''}
              onChange={handleChange}
              helperText={errors.email ?? ''}
              error={!!errors.email}
              fullWidth
            />
          </Grid>
          <Grid item sx={{ marginTop: '10px' }}>
            <InputLabel htmlFor='birthday' error={!!errors.dateOfBirth}>
              Day of Birth
            </InputLabel>
            <div className='NewUserModal__birthdayCalendar'>
              <DatePicker
                onChange={handleDateChange}
                onYearChange={handleDateChange}
                onMonthChange={handleDateChange}
                selected={values.dateOfBirth}
                customInput={<CustomDateInput />}
                dropdownMode='select'
                showYearDropdown
              />
            </div>
            {!!errors.dateOfBirth && (
              <FormHelperText error={true}>{errors.dateOfBirth}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: '5px' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.sendEmailNotification}
                  onChange={handleCheckboxChange}
                />
              }
              label='Send email notification'
            />
          </FormGroup>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default NewUserModal;
