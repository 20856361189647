import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
  createInvitationCode,
  getAllInvitationCodes,
  getExperience,
  getUsers
} from '../../core/api';
import {
  GigInvitationCodeViewModel,
  GigViewModel,
  InviteGigInvitationCodeViewModel,
  UserViewModel
} from '../../core/backend/models';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import { IMessage, INewInvitationCodeData, IUserPanel } from '../../core/types';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import './InvitationCode.scss';
import { DEFAULT_ERROR_MESSAGE } from '../../core/consts';
import LazyTable from '../../components/Tables/LazyTable/LazyTable';
import { SortType } from '../../core/enums';
import { useParams } from 'react-router-dom';
import appToast from '../../core/toast';
import InvitationCodesTableHeader from '../../components/TableHeaders/InvitationCodesTableHeader/InvitationCodesTableHeader';
import Loader from '../../components/Loader/Loader';
import InvitationCodeRow from '../../components/TableRows/InvitationCodeRow/InvitationCodeRow';
import NewCodeModal from '../../components/Modals/NewCodeModal/NewCodeModal';

const InvitationCode = () => {
  const { id } = useParams<{ id: string }>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewCodeOpen, setIsNewCodeOpen] = useState(false);
  const [event, setEvent] = useState<GigViewModel>(null);
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [panelState, setPanelState] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [invitationCodes, setInvitationCodes] = useState<
    GigInvitationCodeViewModel[]
  >([]);
  const [users, setUsers] = useState<UserViewModel[]>([]);

  useEffect(() => {
    getInvitationCodes();
  }, []);

  const getInvitationCodes = async () => {
    try {
      setIsLoading(true);

      const gig = await getExperience(Number(id));
      const users = await getUsers(0, 10000);
      const invitationCodes = await getAllInvitationCodes(Number(id));

      setEvent(gig);

      setInvitationCodes(invitationCodes);
      setUsers(users);
    } catch (e: any) {
      const error = e.response?.date?.description ?? DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Table methods
   */

  const idFromItem = (item: InviteGigInvitationCodeViewModel) => {
    return item.id;
  };

  const handleCreateNewCode = async (values: INewInvitationCodeData) => {
    let success = false;
    try {
      setIsProcessing(true);

      // Create the new invitation code
      const [newCode, status] = await createInvitationCode({
        ...values,
        expirationDateEpoch: values.expirationDateEpoch.getTime(),
        bookingsLimit: Number(values.bookingsLimit)
      });

      // If the creation is successful, fetch all invitation codes again
      if (status === 200) {
        const updatedInvitationCodes = await getAllInvitationCodes(Number(id));

        // Update state with the updated invitation codes
        setInvitationCodes(updatedInvitationCodes);

        closeNewCodeModal();
        success = true;
      }
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: e.response?.data?.description || null
      });
    } finally {
      setIsProcessing(false);
    }

    return success;
  };

  const closePanel = () => {
    setPanelState({
      ...panelState,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  /**
   * Modal Methods
   */
  const handleShowNewCodeModal = () => {
    setIsNewCodeOpen(true);
  };

  const closeNewCodeModal = () => {
    setIsNewCodeOpen(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  if (isLoading) return <Loader />;

  return (
    <MainLayout>
      <div className='InvitationCodes'>
        {!event.isClosed ? (
          <div className='InvitationCodes__actionButtons'>
            <Button
              variant='text'
              color='secondary'
              startIcon={<AddIcon />}
              onClick={handleShowNewCodeModal}
            >
              New Invitation Code
            </Button>
          </div>
        ) : (
          <p>
            This event is already closed, no new invitation codes can be
            created.
          </p>
        )}

        <div className='InvitationCodes__content'>
          <LazyTable
            Header={InvitationCodesTableHeader}
            data={invitationCodes}
            idFromItem={idFromItem}
            initialOrder={SortType.Asc}
            initialOrderBy='companyName'
            renderItem={(code, index) => (
              <InvitationCodeRow code={code} key={`${code.id}-${index}`} />
            )}
          />
        </div>
        {/* {(isLoadingMore || hasMore) && (
          <div ref={sentryRef}>
            <Loader adaptToParent size={32} />
          </div>
        )} */}
      </div>

      <UserPanel
        isOpen={panelState.isOpen}
        user={panelState.user}
        onClose={closePanel}
      />

      <NewCodeModal
        isOpen={isNewCodeOpen}
        onClose={closeNewCodeModal}
        isProcessing={isProcessing}
        onAccept={handleCreateNewCode}
        gig={event}
        users={users}
      />

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </MainLayout>
  );
};

export default InvitationCode;
