import {
  IBookingRow,
  IEventRow,
  INewContentRow,
  IVideoReportRow
} from '../types';

export const sortVideosByStatus = (a: INewContentRow, b: INewContentRow) =>
  a.status === 'new'
    ? -1
    : b.status === 'new'
    ? 1
    : a.status === 'approved'
    ? -1
    : b.status === 'approved'
    ? 1
    : 0;

export const sortVideoReportsByStatus = (
  a: IVideoReportRow,
  b: IVideoReportRow
) =>
  a.status === 'new'
    ? -1
    : b.status === 'new'
    ? 1
    : a.status === 'approved'
    ? -1
    : b.status === 'approved'
    ? 1
    : 0;

export const sortBookings = (a: IBookingRow, b: IBookingRow) => {
  if (
    (a.status === 'pending' && b.status === 'pending') ||
    (a.status === 'confirmed' && b.status === 'confirmed') ||
    (a.status === 'in progress' && b.status === 'in progress') ||
    (a.status === 'overdue' && b.status === 'overdue') ||
    (a.status === 'declined' && b.status === 'declined')
  ) {
    return a.remainingTime - b.remainingTime;
  }

  if (a.status === 'pending' && b.status !== 'pending') {
    return -1;
  } else if (a.status !== 'pending' && b.status === 'pending') {
    return 1;
  }

  if (a.status === 'confirmed' && b.status !== 'pending') {
    return -1;
  } else if (a.status !== 'pending' && b.status === 'confirmed') {
    return 1;
  }

  if (
    a.status === 'in progress' &&
    !['pending', 'confirmed'].includes(b.status)
  ) {
    return -1;
  } else if (
    !['pending', 'confirmed'].includes(a.status) &&
    b.status === 'in progress'
  ) {
    return 1;
  }

  if (
    a.status === 'overdue' &&
    !['pending', 'confirmed', 'in progress'].includes(b.status)
  ) {
    return -1;
  } else if (
    !['pending', 'confirmed', 'in progress'].includes(a.status) &&
    b.status === 'overdue'
  ) {
    return 1;
  }
};

export const sortNewVideos = (a: INewContentRow, b: INewContentRow) => {
  if (
    (a.status === 'new' && b.status === 'new') ||
    (a.status === 'review' && b.status === 'review') ||
    (a.status === 'rejected' && b.status === 'rejected') ||
    (a.status === 'approved' && b.status === 'approved')
  ) {
    return a.createdOn - b.createdOn;
  }

  if (a.status === 'new' && b.status !== 'new') {
    return -1;
  } else if (a.status !== 'new' && b.status === 'new') {
    return 1;
  }

  if (a.status === 'review' && b.status !== 'new') {
    return -1;
  } else if (a.status !== 'new' && b.status === 'review') {
    return 1;
  }

  if (a.status === 'rejected' && !['new', 'review'].includes(b.status)) {
    return -1;
  } else if (!['new', 'review'].includes(a.status) && b.status === 'rejected') {
    return 1;
  }

  if (
    a.status === 'approved' &&
    !['new', 'review', 'rejected'].includes(b.status)
  ) {
    return -1;
  } else if (
    !['new', 'review', 'rejected'].includes(a.status) &&
    b.status === 'approved'
  ) {
    return 1;
  }
};
