import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getVideos } from '../../core/api';
import { createVideoModel } from '../../core/helpers/model-helpers';
import { INewContentRow } from '../../core/types';

const initialState: INewContentRow[] = [];

export const getAllVideos = async () => {
  const videos = await getVideos(0, 10000);
  return videos.map(createVideoModel);
};

export const fetchAllVideos = createAsyncThunk(
  'videos/fetchVideos',
  getAllVideos
);

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    addAllVideos: (state, action: PayloadAction<INewContentRow[]>) =>
      action.payload,
    updateVideoStatus: (
      state,
      {
        payload
      }: PayloadAction<{
        videoId: number;
        newVideo: INewContentRow;
      }>
    ) => {
      const videoIndex = state.findIndex(
        (video) => video.id === payload.videoId
      );
      if (videoIndex > -1) {
        state[videoIndex] = payload.newVideo;
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchAllVideos.fulfilled, (state, action) => {
      state = action.payload;
    });
  }
});

export const { addAllVideos, updateVideoStatus } = videosSlice.actions;
