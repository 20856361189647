import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

export interface IBaseRowProps {
  selected?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  tableRowRoot: {
    '&$tableRowSelected, &$tableRowSelected:hover': {
      backgroundColor: '#F6ECFE',
      cursor: 'pointer!important'
    }
  },
  tableRowSelected: {
    backgroundColor: '#F6ECFE'
  }
}));

const BaseRow = ({ selected, children, onClick }: IBaseRowProps) => {
  const classes = useStyles();

  return (
    <TableRow
      hover
      role='checkbox'
      onClick={onClick}
      selected={selected}
      classes={{
        root: classes.tableRowRoot,
        selected: classes.tableRowSelected
      }}
    >
      {children}
    </TableRow>
  );
};

export default BaseRow;
