import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import { getMediaFile } from '../../../core/api';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { ServiceProviderPayoutLogViewModel } from '../../../core/backend/models';
import './PayoutRow.scss';
import UserImage from '../../UserImage/UserImage';
import { translateTransferType } from '../../../core/helpers/enums-helpers';

interface IProps extends IBaseRowProps {
  payout: ServiceProviderPayoutLogViewModel;
}

const PayoutRow = ({ payout }: IProps) => {
  const date = dayjs(payout.dateOfTransaction);
  const formattedDate = date.format('MM/DD/YY hh:mm a');

  return (
    <BaseRow>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <b>{payout.reservationId}</b>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        $ {payout.amount.toFixed(2)}
      </TableCell>
      <TableCell sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}>
        {translateTransferType(payout.transferType)}
      </TableCell>
      <TableCell component='th' scope='row' style={{ maxWidth: '140px' }}>
        <div className='PayoutRow__userInfo'>
          <UserImage
            className='PayoutRow__image'
            url={getMediaFile(payout.provider.image)}
          />
          <div className='PayoutRow__userNameWrapper'>
            <div className='PayoutRow__userName'>
              @{payout.provider.userName}
            </div>
            <div className='PayoutRow__userFullName'>
              {payout.provider.fullName}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        {formattedDate}
      </TableCell>
    </BaseRow>
  );
};

export default PayoutRow;
