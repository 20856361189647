import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Video from '../../Video/Video';
import { getMediaFile } from '../../../core/api';
import './VideoReportRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { translateCustomerReportReason } from '../../../core/helpers/enums-helpers';
import Mentions from '../../Mentions/Mentions';
import UserImage from '../../UserImage/UserImage';
import { UserViewModel } from '../../../core/backend/models';
import { IVideoReportRow } from '../../../core/types';
import { Button } from '@mui/material';

interface IProps extends IBaseRowProps {
  status: string;
  video: IVideoReportRow;
  onSelect: React.MouseEventHandler;
  onViewDetails: React.MouseEventHandler;
  onPreviewUser: (user: UserViewModel) => React.MouseEventHandler;
  onAccept: React.MouseEventHandler;
  onDecline: React.MouseEventHandler;
}

const VideoReportRow = ({
  status,
  video,
  selected,
  onViewDetails,
  onPreviewUser,
  onSelect,
  onAccept,
  onDecline
}: IProps) => {
  return (
    <BaseRow selected={selected} onClick={onViewDetails}>
      <TableCell padding='checkbox'>
        {status === 'new' && (
          <Checkbox color='primary' checked={selected} onClick={onSelect} />
        )}
      </TableCell>
      <TableCell component='th' scope='row'>
        <Video
          url={video.isVideo ? getMediaFile(video.mediaFile.awsUrl) : null}
          coverUrl={getMediaFile(
            video.mediaFile?.thumbnails?.web[0] ?? video.mediaFile.awsUrl
          )}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        <Mentions
          textOnly
          content={
            video.mediaFile.linkedGig?.name ?? video.mediaFile.description ?? ''
          }
          taggedUsers={video.mediaFile.taggedUsers}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        <div
          className='VideoReportRow__userInfo'
          onClick={onPreviewUser(video.mediaFile?.owner)}
        >
          <UserImage
            className='VideoReportRow__image'
            url={getMediaFile(video.mediaFile?.owner?.image)}
          />
          <div className='VideoReportRow__userFullName'>
            {video.mediaFile?.owner?.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        <div
          className='VideoReportRow__userInfo'
          onClick={onPreviewUser(video.reportingUser)}
        >
          <UserImage
            className='VideoReportRow__image'
            url={getMediaFile(video.reportingUser?.image)}
          />
          <div className='VideoReportRow__userFullName'>
            {video.reportingUser?.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <p className='VideoReportRow__cellRow'>
          {video.formattedReportingDate}
        </p>
        <p className='VideoReportRow__cellRow'>
          {video.formattedReportingTime}
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <Status status={status} />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <div className='VideoReportRow__reportReasons'>
          {translateCustomerReportReason(video.reportReason)}
        </div>
      </TableCell>
      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        {video.isBadVideo === null && (
          <div className='VideoReportRow__actions'>
            <Button
              variant='outlined'
              onClick={onAccept}
              color='primary'
              size='small'
              startIcon={<DoneIcon />}
            >
              Mark as Appropriate
            </Button>
            <Button
              variant='outlined'
              onClick={onDecline}
              color='error'
              size='small'
              startIcon={<ClearIcon />}
            >
              Delete Video
            </Button>
          </div>
        )}
      </TableCell>
    </BaseRow>
  );
};

export default VideoReportRow;
