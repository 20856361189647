import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import Messages from '../../Messages/Messages';
import { useCallback, useEffect, useRef, useState } from 'react';
import MessagesRow from '../../MessagesRow/MessagesRow';
import Conversation from '../../Conversation/Conversation';
import {
  UserViewModel,
  MessageViewModel,
  ChatViewModel,
  ChatMemberViewModel
} from '../../../core/backend/models';
import ReplyForm from '../../ReplyForm/ReplyForm';
import { createDirectChat } from '../../../core/api';
import ChatSocket from '../../../core/classes/signal/chat-socket';
import './MessagesTab.scss';
import appToast from '../../../core/toast';

interface IProps extends IBaseTabProps {
  members?: ChatMemberViewModel[];
  user?: UserViewModel;
}

const MessagesTab = ({ members = [], user, ...props }: IProps) => {
  const [directChatUser, setDirectChatUser] = useState<UserViewModel | null>(
    null
  );
  const [directChatMembers, setDirectChatMembers] = useState<
    ChatMemberViewModel[]
  >([]);
  const [conversation, setConversation] = useState<MessageViewModel[]>([]);
  const liveChat = useRef<ChatSocket | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // User active - Start socket connection
    if ((user || directChatUser) && !liveChat.current?.isConnected) {
      connectToDirectChat().catch((e) => {
        appToast.showError(
          'Something went wrong trying to connect to the hub. Please refresh the page and try again.'
        );
      });
      return;
    }
  }, [directChatUser, user]);

  // useEffect(() => {
  //   const sortedMessages = chat.messages.sort((a, b) => a.sendOn - b.sendOn);
  //   setDirectChatMembers(chat.members);
  //   setConversation(sortedMessages);
  // }, [chat, user]);

  const connectToDirectChat = async () => {
    try {
      setIsLoading(true);

      //Get saved chat
      const userId = user?.id || directChatUser?.id;
      const adminChat = await createDirectChat(userId);

      const sortedMessages = adminChat.messages.sort(
        (a, b) => a.sendOn - b.sendOn
      );

      setDirectChatMembers(adminChat.members);
      setConversation([...conversation, ...sortedMessages]);

      setIsLoading(false);

      //Start live chat
      const socket = new ChatSocket()
        .setChat(adminChat)
        .whenReceivingMessage((message: MessageViewModel) => {
          setConversation((prevState) => [...prevState, message]);
        });

      await socket.init();
      liveChat.current = socket;
    } catch (e) {
      appToast.showError(
        'Something went wrong while connecting messenger. Close panel and try again.'
      );
    }
  };

  const disconnectFromChat = async () => {
    await liveChat.current.stop();
    liveChat.current = null;
  };

  const handleSendMessage = async (values: any) => {
    if (!liveChat.current.isConnected) {
      return;
    }

    await liveChat.current.send(values.message);
  };

  const handleCloseChat = useCallback(() => {
    setDirectChatUser(null);
    setConversation([]);

    //If isConnected - Disconnect from socket
    if (liveChat.current?.isConnected) disconnectFromChat();
  }, [directChatUser]);

  return (
    <BaseTab {...props} className='MessagesTab'>
      {!user && !directChatUser ? (
        <Messages
          data={members}
          renderItem={(user, idx) => (
            <MessagesRow
              key={`conversation-${idx}`}
              user={user}
              onPress={() => {
                setDirectChatUser(user);
              }}
            />
          )}
        />
      ) : (
        <Conversation
          isLoading={isLoading}
          members={directChatMembers}
          data={conversation}
          title={
            user?.fullName ??
            user?.userName ??
            directChatUser?.fullName ??
            directChatUser?.userName ??
            ''
          }
          onGoBackClick={directChatUser ? handleCloseChat : null}
          form={
            <ReplyForm
              isLoading={false}
              placeholder='Send your message'
              onSubmit={handleSendMessage}
            />
          }
        />
      )}
    </BaseTab>
  );
};

export default MessagesTab;
