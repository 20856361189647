import TableCell from '@mui/material/TableCell';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Video from '../../Video/Video';
import { getMediaFile } from '../../../core/api';
import './VideoRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import Mentions from '../../Mentions/Mentions';
import { useState } from 'react';
import UserImage from '../../UserImage/UserImage';
import { UserViewModel } from '../../../core/backend/models';
import { INewContentRow } from '../../../core/types';
import { Button, ListItemIcon, ListItemText } from '@mui/material';

interface IProps extends IBaseRowProps {
  video: INewContentRow;
  onSelect: React.MouseEventHandler;
  onViewDetails: React.MouseEventHandler;
  onPreviewUser: (user: UserViewModel) => React.MouseEventHandler;
  onAccept: () => void;
  onReview: () => void;
  onReject: () => void;
}

const VideoRow = ({
  video,
  selected,
  onViewDetails,
  onPreviewUser,
  onSelect,
  onAccept,
  onReview,
  onReject
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        {video.status === 'new' && (
          <Checkbox color='primary' checked={selected} onClick={onSelect} />
        )}
      </TableCell>
      <TableCell component='th' scope='row' onClick={onViewDetails}>
        <Video
          url={video?.awsUrl ? getMediaFile(video.awsUrl) : null}
          coverUrl={getMediaFile(video?.thumbnails?.web[0])}
          className='VideoRow__pointer'
        />
      </TableCell>
      <TableCell
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '350px' }}
        onClick={onViewDetails}
      >
        <Mentions
          textOnly
          content={video.linkedGig?.name ?? video.description ?? ''}
          taggedUsers={video.taggedUsers}
          className='VideoRow__pointer'
        />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '110px' }}
      >
        <p className='VideoRow__cellRow'>{video.formattedDateAdded}</p>
        <p className='VideoRow__cellRow'>{video.formattedTimeAdded}</p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        {video.type}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        <div
          className='VideoRow__userInfo'
          onClick={onPreviewUser(video.owner)}
        >
          <UserImage
            className='VideoRow__image'
            url={getMediaFile(video.owner.image)}
          />
          <div className='VideoRow__userNameWrapper'>
            <div className='VideoRow__userName'>@{video.owner.userName}</div>
            <div className='VideoRow__userFullName'>{video.owner.fullName}</div>
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '140px', width: '140px' }}
      >
        <Status status={video.status} />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        {video.formattedRejectionReason}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        {video.admin && (
          <div
            className='VideoRow__userInfo'
            onClick={onPreviewUser(video.admin)}
          >
            <UserImage
              className='VideoRow__image'
              url={getMediaFile(video.admin.image)}
            />
            <div className='VideoRow__userNameWrapper'>
              <div className='VideoRow__userName'>@{video.admin.userName}</div>
              <div className='VideoRow__userFullName'>
                {video.admin.fullName}
              </div>
            </div>
          </div>
        )}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '110px' }}
      >
        <p className='VideoRow__cellRow'>{video.formattedActionDate}</p>
        <p className='VideoRow__cellRow'>{video.formattedActionTime}</p>
      </TableCell>
      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <>
          <Button onClick={handleOpenMenu} endIcon={<ArrowDropDownIcon />}>
            Select
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            disableScrollLock={false}
          >
            <MenuItem
              onClick={() => {
                onAccept();
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText>Approve</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                onReview();
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText>Under Review</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                onReject();
                handleCloseMenu();
              }}
            >
              <ListItemIcon>
                <ClearIcon />
              </ListItemIcon>
              <ListItemText>Reject</ListItemText>
            </MenuItem>
          </Menu>
        </>
      </TableCell>
    </BaseRow>
  );
};

export default VideoRow;
