import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getMediaFile } from '../../../core/api';
import './EventRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import Video from '../../Video/Video';
import { getEventsThumbnails } from '../../../core/helpers/model-helpers';
import { GetAllEventsOnlyResponse } from '../../../core/backend/models';
import { Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { RoutesEnum } from '../../../core/enums';

dayjs.extend(relativeTime);

interface IProps extends IBaseRowProps {
  event: GetAllEventsOnlyResponse;
  onPreviewProvider: React.MouseEventHandler;
  onEditEvent: React.MouseEventHandler;
}

const EventRow = ({ event, onPreviewProvider, onEditEvent }: IProps) => {
  const { videoUrl, staticImageUrl } = getEventsThumbnails(
    event.mediaFiles ?? []
  );
  const href = RoutesEnum.InvitationCode.replace(
    ':id',
    event?.gigId.toString()
  );
  return (
    <BaseRow>
      <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
        <div className='EventRow__videoRow'>
          <Video
            className='EventRow__video'
            coverUrl={staticImageUrl}
            url={videoUrl}
          />
        </div>
      </TableCell>
      <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
        <div className='EventRow__videoRow'>
          <p>
            <b>{event.name}</b>
          </p>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '140px' }}
      >
        <div className='EventRow__userInfo' onClick={onPreviewProvider}>
          <UserImage
            className='EventRow__image'
            url={getMediaFile(event.provider.image)}
          />
          <div className='EventRow__userFullName'>
            {event.provider.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='left'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        {event.isRecurrentEvent ? (
          <Status status='recurrent' size='small' />
        ) : null}
      </TableCell>
      <TableCell
        align='left'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '60px', maxWidth: '70px' }}
      >
        <p className='EventRow__cellRow'>
          <b>{event.location}</b>
        </p>
      </TableCell>
      <TableCell
        align='left'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '60px', maxWidth: '70px' }}
      >
        <p className='EventRow__cellRow'>
          <b>
            {format(
              new Date(event.oneEventTimeTimestampToSeconds * 1000),
              'MM-dd-yyyy'
            )}
          </b>
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '200px' }}
      >
        <div className='EventRow__cellRow'>
          <p> {event.isPrivateExperience ? 'Private' : 'Public'}</p>
          {event.isPrivateExperience && (
            <Button size='small' variant='outlined'>
              <Link className='EventRow__link' to={href}>
                Codes
              </Link>
            </Button>
          )}
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '120px' }}
      >
        <IconButton onClick={onEditEvent} title='Edit Event'>
          <EditIcon />
        </IconButton>
      </TableCell>
    </BaseRow>
  );
};

export default EventRow;
