import { useState } from 'react';
import classNames from 'classnames';
import { useDebounce } from '../../hooks/useDebounce';
import './Video.scss';
import Loader from '../Loader/Loader';

interface IProps {
  url: string;
  coverUrl?: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Video = ({ url, coverUrl, className, size = 'medium' }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoShown, setIsVideoShown] = useState(false);
  const debouncedIsVideoShown = useDebounce(isVideoShown, 250);

  const onMouseEnter = () => {
    setIsVideoShown(true);
  };

  const onMouseLeave = () => {
    setIsVideoShown(false);
  };

  const onCanPlay = () => {
    setIsLoading(false);
  };

  const onLoadStart = () => {
    setIsLoading(true);
  };

  return (
    <div
      className={classNames(`Video ${className} `, {
        'Video--medium': size === 'medium',
        'Video--large': size === 'large'
      })}
      style={{
        background: `#000 url(${coverUrl}) no-repeat center / cover`
      }}
      onMouseEnter={url ? onMouseEnter : undefined}
      onMouseLeave={url ? onMouseLeave : undefined}
    >
      {(isVideoShown ? debouncedIsVideoShown : isVideoShown) && (
        <div className='Video__container'>
          {isLoading && <Loader size={32} light adaptToParent backdrop />}
          <video
            src={url}
            autoPlay={true}
            loop={true}
            muted={true}
            width='100%'
            height='100%'
            onCanPlay={onCanPlay}
            onLoadStart={onLoadStart}
          />
        </div>
      )}
    </div>
  );
};

export default Video;
