import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Scrollbar, ScrollbarContext } from 'react-scrollbars-custom';
import { useAppSelector } from '../../state/hooks';
import './AdminNotes.scss';
import { AdminCommentViewModel } from '../../core/backend/models';
import Loader from '../Loader/Loader';
import AdminNote from '../AdminNote/AdminNote';

interface IProps {
  notes: AdminCommentViewModel[];
  onDeleteNote: (noteId: number) => React.MouseEventHandler;
  form?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

const AdminNotes = ({
  notes,
  onDeleteNote,
  form,
  className,
  isLoading = false
}: IProps) => {
  const scroller = useRef<Scrollbar | null>(null);

  useEffect(() => {
    if (scroller) {
      //scroller.current.scrollToBottom();
    }
  }, [notes]);

  return (
    <div className='AdminNotes'>
      <div className={`AdminNotes__wrapper ${className}`}>
        {isLoading ? (
          <Loader adaptToParent />
        ) : (
          <Scrollbar
            noScrollX={true}
            thumbYProps={{ className: 'thumbX' }}
            trackYProps={{ className: 'trackX' }}
            height='100%'
            width='100%'
            createContext={true}
          >
            <ScrollbarContext.Consumer>
              {({ parentScrollbar }) => {
                scroller.current = parentScrollbar;
                let lastDate: string | null = null;
                return (
                  <div className='AdminNotes__content'>
                    {notes.length ? (
                      notes.map((note, idx) => {
                        const currentDate = dayjs(
                          new Date(note.createdOnUnix)
                        ).format('MM.DD.YYYY');

                        const shouldDisplayDate = lastDate !== currentDate;
                        if (shouldDisplayDate) lastDate = currentDate;

                        return (
                          <>
                            {shouldDisplayDate && (
                              <div
                                key={`date-${idx}`}
                                className='AdminNotes__dateRow'
                              >
                                <div className='AdminNotes__date'>
                                  {currentDate}
                                </div>
                              </div>
                            )}
                            <AdminNote
                              key={`message-${note.id}`}
                              note={note}
                              onDeleteNote={onDeleteNote(note.id)}
                            />
                          </>
                        );
                      })
                    ) : (
                      <div className='AdminNotes__noMessages'>No comments</div>
                    )}
                  </div>
                );
              }}
            </ScrollbarContext.Consumer>
          </Scrollbar>
        )}
      </div>
      {form && form}
    </div>
  );
};

export default AdminNotes;
