import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryViewModel } from '../../core/backend/models';

const initialState: CategoryViewModel[] = [];

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addAllCategories: (state, action: PayloadAction<CategoryViewModel[]>) =>
      action.payload,
    deleteFromCategories: (state, action: PayloadAction<number>) =>
      state.filter((category) => category.id !== action.payload),
    addToCategories: (state, action: PayloadAction<CategoryViewModel>) => {
      state.push(action.payload);
      return state;
    },
    updateCategories: (state, action: PayloadAction<CategoryViewModel>) => {
      const catIndex = state.findIndex(
        (category) => category.id === action.payload.id
      );
      if (catIndex < 0) return state;

      state.splice(catIndex, 1, action.payload);
      return state;
    }
  }
});

export const {
  addAllCategories,
  deleteFromCategories,
  addToCategories,
  updateCategories
} = categoriesSlice.actions;
