import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { ITableHeaderProps } from '../../../core/types';
import { SortType } from '../../../core/enums';

interface IProps<T, K> {
  data: T[];
  Header: React.ComponentType<ITableHeaderProps<T>>;
  selectedRows?: K[];
  onSelectionChange?: (selectedRows: K[]) => void;
  idFromItem: (item: T) => K;
  renderItem: (
    item: T,
    index: number,
    helpers: {
      handleSelectRow: (id: K) => React.MouseEventHandler;
      isSelected: boolean;
    }
  ) => React.ReactNode;
  initialOrder?: SortType;
  initialOrderBy?: string;
  customSortingHandler?: (a: T, b: T) => number;
  customSortingColumn?: keyof T;
}

const LazyTable = <T, K>({
  data = [],
  Header,
  idFromItem,
  selectedRows = [],
  onSelectionChange,
  renderItem,
  initialOrder = SortType.Desc,
  initialOrderBy = '',
  customSortingHandler,
  customSortingColumn = null
}: IProps<T, K>) => {
  const [order, setOrder] = useState<SortType>(initialOrder);
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === SortType.Asc;
    setOrder(isAsc ? SortType.Desc : SortType.Asc);
    setOrderBy(property as string);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: K[] = data.map((item) => idFromItem(item));

      onSelectionChange(newSelecteds);
      return;
    }

    onSelectionChange([]);
  };

  const handleSelectRow = (id: K) => (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();

    const selectedIndex = selectedRows.findIndex(
      (selectedId) => selectedId === id
    );
    let newSelected: K[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedRows, id];
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    onSelectionChange(newSelected);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortType, orderBy: keyof T) {
    if (orderBy === customSortingColumn) {
      return order === SortType.Desc
        ? (a: T, b: T) => customSortingHandler(a, b)
        : (a: T, b: T) => -customSortingHandler(a, b);
    }

    return order === SortType.Desc
      ? (a: T, b: T) => descendingComparator(a, b, orderBy)
      : (a: T, b: T) => -descendingComparator(a, b, orderBy);
  }

  const isSelected = (id: K) =>
    selectedRows.findIndex((selectedId) => selectedId === id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size='medium'>
            <Header
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAll}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy as keyof T))
                .map((row, index) =>
                  renderItem(row, index, {
                    handleSelectRow,
                    isSelected: isSelected(idFromItem(row))
                  })
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default LazyTable;
