import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExperienceLogRow } from '../../core/types';

const initialState: IExperienceLogRow[] = [];

export const experienceLogsSlice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    addAllExperienceLogs: (state, action: PayloadAction<IExperienceLogRow[]>) =>
      action.payload,
    appendBookingLogs: (state, action: PayloadAction<IExperienceLogRow[]>) => [
      ...state,
      ...action.payload
    ]
  }
});

export const { addAllExperienceLogs, appendBookingLogs } =
  experienceLogsSlice.actions;
