import TextField from '@mui/material/TextField';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './ChangeUserPasswordModal.scss';
import { FormikValues, useFormik } from 'formik';
import ChangePasswordSchema from '../../../validators/ChangePasswordSchema';
import { Box } from '@mui/material';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ChangeUserPasswordModal = ({
  onAccept,
  onClose,
  ...props
}: IModalProps) => {
  const { errors, values, handleChange, submitForm, resetForm } =
    useFormik<FormValues>({
      initialValues: { password: '', confirmPassword: '' },
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      validationSchema: ChangePasswordSchema,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        onAccept(values.password);
      }
    });

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Change User Password'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > :not(style)': { m: 1 }
        }}
      >
        <TextField
          id='password'
          name='password'
          label='Enter New Password'
          variant='standard'
          value={values.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password ?? ''}
          fullWidth
        />

        <TextField
          id='confirm-password'
          name='confirmPassword'
          label='Confirm Password'
          variant='standard'
          value={values.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword ?? ''}
          fullWidth
        />
      </Box>
    </BaseModal>
  );
};

export default ChangeUserPasswordModal;
