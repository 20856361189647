import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/material';

interface IProps {
  remainingHours: number;
  hoursUntilEvent: number;
  forceWarningStatus?: boolean;
  sx?: SxProps;
}

const colorsConfig = {
  'on time': { backColor: '#B4FFC4', foreColor: '#000000' },
  aware: { backColor: '#FF6F00', foreColor: '#FFFFFF' },
  overdue: { backColor: '#E91E63', foreColor: '#FFFFFF' }
};

const TimeStatus = ({
  remainingHours = 0,
  hoursUntilEvent = 0,
  forceWarningStatus = false,
  sx
}: IProps) => {
  const color =
    remainingHours === 0 || forceWarningStatus
      ? colorsConfig['overdue']
      : remainingHours > 0 && remainingHours < hoursUntilEvent / 2
      ? colorsConfig['aware']
      : colorsConfig['on time'];

  return (
    <div>
      <Chip
        label={`${remainingHours} hrs`}
        sx={{
          backgroundColor: color.backColor,
          color: color.foreColor,
          ...sx
        }}
      />
    </div>
  );
};

export default TimeStatus;
