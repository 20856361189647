import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IStatsRow, ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';

const StatisticTableHeader = ({
  order,
  orderBy,
  onRequestSort
}: ITableHeaderProps<IStatsRow>) => {
  const createSortHandler =
    (property: keyof IStatsRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='normal'>Creator</TableCell>
        <TableCell padding='normal' align='right'>
          <TableSortLabel
            active={orderBy === 'totalBookings'}
            direction={orderBy === 'totalBookings' ? order : 'asc'}
            onClick={createSortHandler('totalBookings')}
          >
            Bookings
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal' align='right'>
          <TableSortLabel
            active={orderBy === 'sumTotalEarnings'}
            direction={orderBy === 'sumTotalEarnings' ? order : 'asc'}
            onClick={createSortHandler('sumTotalEarnings')}
          >
            Booking Total
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal' align='right'>
          <TableSortLabel
            active={orderBy === 'serviceFee'}
            direction={orderBy === 'serviceFee' ? order : 'asc'}
            onClick={createSortHandler('serviceFee')}
          >
            Service Fee
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal' align='right'>
          <TableSortLabel
            active={orderBy === 'sumHooplaFee'}
            direction={orderBy === 'sumHooplaFee' ? order : 'asc'}
            onClick={createSortHandler('sumHooplaFee')}
          >
            Hoopla Fee
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal' align='right'>
          <TableSortLabel
            active={orderBy === 'sumSubTotal'}
            direction={orderBy === 'sumSubTotal' ? order : 'asc'}
            onClick={createSortHandler('sumSubTotal')}
          >
            Sub-total
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default StatisticTableHeader;
