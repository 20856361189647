import { forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormikValues, useFormik } from 'formik';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import 'react-datepicker/dist/react-datepicker.css';
import './NewCodeModal.scss';
import InvitationCodeSchema from '../../../validators/InvitationCodeSchema';
import { GigViewModel, UserViewModel } from '../../../core/backend/models';
import { Autocomplete } from '@mui/material';

interface FormValues {
  gigId: number;
  userId: string;
  code: string;
  expirationDateEpoch?: Date;
  bookingsLimit?: number;
}

dayjs.extend(relativeTime);
const now = dayjs();

const NewCodeModal = ({
  onAccept,
  onClose,
  gig,
  users,
  ...props
}: IModalProps & { gig: GigViewModel; users: UserViewModel[] }) => {
  const CustomDateInput = forwardRef(
    (
      { value, onClick }: { value?: string; onClick?: React.MouseEventHandler },
      ref: any
    ) => (
      <button className='NewCodeModal__datePicker' onClick={onClick} ref={ref}>
        {value}
      </button>
    )
  );

  const { values, errors, setFieldValue, handleChange, resetForm, submitForm } =
    useFormik<FormValues>({
      initialValues: {
        gigId: gig.id,
        userId: '',
        code: '',
        expirationDateEpoch: now.toDate(),
        bookingsLimit: 0
      },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: InvitationCodeSchema,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        const success = await onAccept(values);
        success && resetForm();
      }
    });

  const handleDateChange = (date: Date) =>
    setFieldValue('expirationDateEpoch', date);

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='New Code'
      size='lg'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='NewCodeModal__description'>
        Fill out the form below to create a new invitation code.
      </div>
      <Grid container direction={'column'} rowSpacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            options={users}
            getOptionLabel={(user) => user.userName}
            renderInput={(params) => (
              <TextField
                {...params}
                name='userId'
                label='Username'
                variant='standard'
                error={!!errors.userId}
                fullWidth
              />
            )}
            value={users.find((user) => user.id === values.userId) || null}
            onChange={(_, newValue) => {
              if (typeof newValue === 'object') {
                setFieldValue('userId', newValue.id);
              } else {
                setFieldValue('userId', '');
              }
            }}
          />
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='code'
              label='Code'
              variant='standard'
              value={values?.code ?? ''}
              onChange={handleChange}
              helperText={errors.code ?? ''}
              error={!!errors.code}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='bookingsLimit'
              label='Bookings Limit'
              variant='standard'
              value={values?.bookingsLimit ?? 0}
              onChange={handleChange}
              helperText={errors.bookingsLimit ?? ''}
              error={!!errors.bookingsLimit}
              fullWidth
            />
          </Grid>
          <Grid item sx={{ marginTop: '10px' }}>
            <InputLabel
              htmlFor='expirationDateEpoch'
              error={!!errors.expirationDateEpoch}
            >
              Expiration Date
            </InputLabel>
            <div className='NewCodeModal__expirationCalendar'>
              <DatePicker
                onChange={handleDateChange}
                onYearChange={handleDateChange}
                onMonthChange={handleDateChange}
                selected={new Date(values.expirationDateEpoch)}
                customInput={<CustomDateInput />}
                dropdownMode='select'
                showYearDropdown
                maxDate={new Date(gig.oneEventTimeTimestampToSeconds * 1000)}
              />
            </div>
            {!!errors.expirationDateEpoch && (
              <FormHelperText error={true}>
                {errors.expirationDateEpoch}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default NewCodeModal;
