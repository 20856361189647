import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

interface IProps {}

const CategoryTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Icon
        </TableCell>
        <TableCell align='left' padding='normal'>
          Name
        </TableCell>
        <TableCell align='right' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CategoryTableHeader;
