import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  IAccountStats,
  IGeneralStats,
  IStatsRow,
  IUserPanel
} from '../../core/types';
import { useAppSelector } from '../../state';
import './Statistic.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchStatsFilter } from '../../core/helpers/search-helpers';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import StatisticTableHeader from '../../components/TableHeaders/StatisticTableHeader/StatisticTableHeader';
import StatisticRow from '../../components/TableRows/StatisticRow/StatisticRow';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { convertToCurrency } from '../../core/helpers/helpers';
import { UserViewModel } from '../../core/backend/models';
import { getExperiencePayouts } from '../../core/api';
import {
  createAccountStats,
  createStatsFromExperiences
} from '../../core/helpers/model-helpers';
import Loader from '../../components/Loader/Loader';
import DateRangeInput from '../../components/DateRangeInput/DateRangeInput';
import appToast from '../../core/toast';

interface IFromToDate {
  start: Date;
  end: Date;
}

const Statistic = () => {
  const { users } = useAppSelector((state) => state);
  const [page, setPage] = useState(0);
  const [stats, setStats] = useState<IGeneralStats>(null);
  const [globalStats, setGlobalStats] = useState<IStatsRow>(null);
  const [currentStats, setCurrentStats] = useState<IStatsRow[]>(null);
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [startEndDate, setStartEndDate] = useState<IFromToDate>(null);
  const [showResetStatsButton, setShowResetStatsButton] = useState(false);
  const [accountStats, setAccountStats] = useState<IAccountStats>(null);
  const [selectedUser, setSelectedUser] = useState<string>('');

  useEffect(() => {
    if (!startEndDate) return;
    getStats(startEndDate.start.getTime(), startEndDate.end.getTime()).then(
      () => {
        isLoading && setIsLoading(false);
      }
    );
  }, [startEndDate]);

  useEffect(() => {
    setIsLoading(true);
    const currentDate = new Date();
    const initialDate = new Date();
    initialDate.setDate(1);
    initialDate.setHours(0, 0, 0, 0);
    currentDate.setHours(23, 59, 59, 999);

    setStartEndDate({ start: initialDate, end: currentDate });

    //generate account stats
    const _accountStats = createAccountStats(users);
    setAccountStats(_accountStats);
  }, []);

  const getStats = async (fromDate: number, toDate: number) => {
    const payouts = await getExperiencePayouts(fromDate, toDate);
    const statistics = createStatsFromExperiences(payouts);

    setStats(statistics);
    setGlobalStats(statistics.globalStats);
    setCurrentStats(statistics.creatorsStats);
  };

  /**
   * Table methods
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const idFromItem = (item: IStatsRow) => {
    return item.user.id;
  };

  /**
   * Actions
   */

  const handleSeachContent = useCallback(
    (query: string) => {
      if (!query.length) {
        setCurrentStats(stats.creatorsStats);
        return;
      }

      const lowerCasedQuery = query.toLowerCase();
      const _result = stats.creatorsStats.filter(
        searchStatsFilter(lowerCasedQuery)
      );
      setCurrentStats(_result);
    },
    [stats]
  );

  const handleShowStats = (userId: string) => () => {
    const _stats = stats.creatorsStats.find(
      (stats) => stats.user.id === userId
    );

    setSelectedUser(userId);
    setGlobalStats(_stats);
    setShowResetStatsButton(true);
  };

  const handleResetGlobalStats = () => {
    setGlobalStats(stats.globalStats);
    setSelectedUser('');
    setShowResetStatsButton(false);
  };

  const handleDateChange = (field: keyof IFromToDate) => (date: Date) => {
    //Lets set hours to get all data starting 00:00:00 of the date
    // and ending at 23:59:59. This ensure we get all content of the day.
    if (field === 'start') date.setHours(0, 0, 0, 0);
    else date.setHours(23, 59, 59, 999);

    if (field === 'start' && date.getTime() > startEndDate.end.getTime()) {
      appToast.showError('Start Time can not be greater than End Time.');
      return;
    }

    if (field === 'end' && date.getTime() < startEndDate.start.getTime()) {
      appToast.showError('End Time can not be less than Start Time.');
      return;
    }

    setStartEndDate({ ...startEndDate, [field]: date });
  };

  /**
   * Side Panel
   */

  const handlePreviewUser =
    (user: UserViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setUserPanel({ isOpen: true, user, relatedRowIndex });
    };

  const closePanel = () => {
    setUserPanel({
      ...userPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  if (isLoading) return <Loader />;

  return (
    <MainLayout>
      <div className='Statistic'>
        <div className='Statistic__globalStats'>
          <div className='Statistic__globalStatsContainer'>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Bookings
                </Typography>
                <Typography variant='h4' component='div'>
                  {globalStats.totalBookings}
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Creator Count
                </Typography>
                <Typography variant='h4' component='div'>
                  {accountStats.creatorCount}
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Customer Count
                </Typography>
                <Typography variant='h4' component='div'>
                  {accountStats.customerCount}
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Total Sales
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.totalSales)}
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Booking Total
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.sumBookingTotal)}
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className='Statistic__globalStatsContainer'>
            <Card sx={{ flex: 1, maxWidth: '260px' }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Booking Fees
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.serviceFee)}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ flex: 1, maxWidth: '260px' }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Hoopla Fee
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.sumHooplaFee)}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ flex: 1, maxWidth: '260px' }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Tips
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.sumCreatorTips)}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ flex: 1, maxWidth: '260px' }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} color='text.secondary'>
                  Total Income
                </Typography>
                <Typography variant='h4' component='div'>
                  {convertToCurrency(globalStats.sumTotalEarnings)}
                </Typography>
              </CardContent>
            </Card>
          </div>
          {showResetStatsButton && (
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              onClick={handleResetGlobalStats}
            >
              Clear
            </Button>
          )}
        </div>

        <div className='Statistic__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSeachContent}
            className='Statistic__search'
          />
          <DateRangeInput
            className='Statistic__dateRange'
            startDate={startEndDate.start}
            endDate={startEndDate.end}
            onStartDateChange={handleDateChange('start')}
            onEndDateChange={handleDateChange('end')}
          />
        </div>

        <div className='Statistic__content'>
          <BasicTable
            data={currentStats}
            Header={StatisticTableHeader}
            page={page}
            idFromItem={idFromItem}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            initialOrderBy='sumTotalEarnings'
            renderItem={(stats, idx) => (
              <StatisticRow
                key={`stats-${stats.user.id}`}
                stats={stats}
                selected={
                  selectedUser === stats.user.id ||
                  userPanel.relatedRowIndex === idx
                }
                onPreviewUser={handlePreviewUser(stats.user, idx)}
                onClick={handleShowStats(stats.user.id)}
              />
            )}
          />
        </div>
      </div>

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={closePanel}
      />
    </MainLayout>
  );
};

export default Statistic;
