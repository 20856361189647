import { useEffect } from 'react';
import cn from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import { useSearch } from '../../hooks/useSearch';
import './SearchInput.scss';
import Loader from '../Loader/Loader';

interface Props {
  placeholder?: string;
  compact?: boolean;
  rounded?: boolean;
  isProcessing?: boolean;
  className?: string;
  onDebouncedTextChange: (text: string) => void;
}

const SearchInput = ({
  onDebouncedTextChange,
  placeholder,
  isProcessing = false,
  compact = false,
  rounded = false,
  className = ''
}: Props) => {
  const { searchText, debouncedSearchText, onSearchChange } = useSearch(
    '',
    600
  );

  useEffect(() => {
    onDebouncedTextChange(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleOnTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
  };

  return (
    <div
      className={cn(`SearchInput ${className}`, {
        'SearchInput--rounded': rounded,
        'SearchInput--compact': compact
      })}
    >
      <SearchIcon />
      <input
        className='SearchInput__input'
        placeholder={placeholder ?? 'Search...'}
        value={searchText}
        onChange={handleOnTextChange}
      />
      {isProcessing && (
        <Loader className='SearchInput__loader' adaptToParent size={30} />
      )}
    </div>
  );
};

export default SearchInput;
