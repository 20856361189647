import { useEffect, useState } from 'react';
import {
  BookingTicketViewModel,
  ExperienceLogViewModel,
  UserViewModel
} from '../../core/backend/models';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  FilterOptionType,
  IBookingPanel,
  IBookingRow,
  IExperienceLogRow,
  IMainPanel,
  IMessage,
  IPayoutModal,
  IUserPanel
} from '../../core/types';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import './BookingLogs.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchExperienceBookingsFilter } from '../../core/helpers/search-helpers';
import BookingPanel from '../../components/DetailsPanel/BookingPanel/BookingPanel';
import BookingLogRow from '../../components/TableRows/BookingLogRow/BookingLogRow';
import BookingLogsTableHeader from '../../components/TableHeaders/BookingLogsTableHeader/BookingLogsTableHeader';
import { appendBookingLogs, useAppDispatch, useAppSelector } from '../../state';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import PaymentDetailsModal from '../../components/Modals/PaymentDetailsModal/PaymentDetailsModal';
import { getBookingLogsV2, getBookingPayouts } from '../../core/api';
import LazyTable from '../../components/Tables/LazyTable/LazyTable';
import { IconButton } from '@mui/material';
import Loader from '../../components/Loader/Loader';
import {
  BOOKING_LOGS_PER_PAGE,
  DEFAULT_ERROR_MESSAGE
} from '../../core/consts';
import appToast from '../../core/toast';
import { createExtendedExperienceLogModel } from '../../core/helpers/model-helpers';

const BookingLogs = () => {
  const dispatch = useAppDispatch();
  const { bookingLogs } = useAppSelector((state) => state);
  const [skip, setSkip] = useState(bookingLogs.length);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  const [bookingPanel, setBookingPanel] = useState<IBookingPanel>({
    isOpen: false,
    bookingId: null,
    experienceId: null,
    relatedRowIndex: -1
  });
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [payoutModal, setPayoutModal] = useState<IPayoutModal>({
    isOpen: false,
    isProcessing: false,
    relatedRowIndex: -1,
    payouts: null,
    tickets: null
  });
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [currentBookings, setCurrentBookings] = useState<IExperienceLogRow[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    const startIndex = page * BOOKING_LOGS_PER_PAGE;
    const endIndex = startIndex + BOOKING_LOGS_PER_PAGE;
    const _bookingLogs = bookingLogs.slice(startIndex, endIndex);
    setCurrentBookings(_bookingLogs);

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [page]);

  /**
   * Table methods
   */

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: ExperienceLogViewModel) => {
    return item.bookingId;
  };

  /**
   * Actions
   */

  const getMoreBookingLogs = async () => {
    try {
      setIsLoadingMore(true);
      const bookingLogsResponse = await getBookingLogsV2(
        null,
        skip,
        BOOKING_LOGS_PER_PAGE
      );
      const _bookingLogs = bookingLogsResponse.map(
        createExtendedExperienceLogModel
      );

      if (_bookingLogs.length) {
        dispatch(appendBookingLogs(_bookingLogs));
        setSkip((skip) => skip + _bookingLogs.length);
      }

      setHasMore(_bookingLogs.length > 0);
    } catch (e: any) {
      appToast.showError('Failed getting booking logs');
    } finally {
      setIsLoadingMore(false);
    }
  };

  // const filterBookingsWith = (selectedFilters: FilterOptionType[]) => {
  //   if (selectedFilters.length) {
  //     const statusFilters: string[] = selectedFilters.map(
  //       (filter) => filter.value
  //     );

  //     const filteredBookings = currentBookings.filter((report: IBookingRow) =>
  //       statusFilters.includes(report.status)
  //     );

  //     setCurrentBookings(filteredBookings);
  //     return;
  //   }
  // };

  const handleSearchContent = async (query: string) => {
    try {
      if (!query.length) {
        setCurrentBookings(bookingLogs);
        return;
      }

      setIsSearching(true);

      const bookingLogsResponse = await getBookingLogsV2(query, 0, 50);

      const _bookingLogs = bookingLogsResponse.map(
        createExtendedExperienceLogModel
      );

      // const lowerCasedQuery = query.toLowerCase();
      // const _result = currentBookings.filter(
      //   searchExperienceBookingsFilter(lowerCasedQuery)
      // );

      setCurrentBookings(_bookingLogs);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleViewBooking =
    (booking: IExperienceLogRow, relatedRowIndex: number = -1) =>
    () => {
      setBookingPanel({
        isOpen: true,
        bookingId: booking.bookingId,
        experienceId: booking.booking.gig.id,
        relatedRowIndex
      });
    };

  const handlePreviewUser =
    (user: UserViewModel, relatedRowIndex: number) => () => {
      setUserPanel({ ...userPanel, user, relatedRowIndex, isOpen: true });
    };

  const handleGetBookingPayout =
    (
      bookingId: number,
      tickets: BookingTicketViewModel[],
      relatedRowIndex: number
    ) =>
    async () => {
      setPayoutModal({
        ...payoutModal,
        isOpen: true,
        isProcessing: true,
        relatedRowIndex,
        tickets
      });

      const payouts = await getBookingPayouts(bookingId);

      setPayoutModal((prevState) => ({
        ...prevState,
        isOpen: true,
        isProcessing: false,
        payouts
      }));
    };

  const handlePrevPage = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleNextPage = async () => {
    if (!hasMore) return;

    const startIndex = page * BOOKING_LOGS_PER_PAGE;
    const endIndex = startIndex + BOOKING_LOGS_PER_PAGE;

    if (endIndex >= bookingLogs.length - 1) {
      await getMoreBookingLogs();
    }

    setPage((prevState) => prevState + 1);
  };

  /**
   * Side Panel
   */

  const hanldeCloseBookingPanel = () => {
    setBookingPanel({
      ...bookingPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  const hanldeCloseUserPanel = () => {
    setUserPanel({ ...userPanel, relatedRowIndex: -1, isOpen: false });
  };

  /**
   * Modal Methods
   */

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  const handleClosePayoutModal = () => {
    setPayoutModal({ ...payoutModal, isOpen: false, relatedRowIndex: -1 });
  };

  /**
   * Snack
   */

  return (
    <>
      <div className='BookingLogs'>
        <div className='BookingLogs__filterControls'>
          <SearchInput
            isProcessing={isSearching}
            onDebouncedTextChange={handleSearchContent}
            className='BookingLogs__search'
          />
          {/* <Filter
            options={bookingFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentBookings(bookings);
                return;
              }

              filterBookingsWith(selectedFilters);
            }}
          /> */}
        </div>

        <div className='BookingLogs__content'>
          <LazyTable
            Header={BookingLogsTableHeader}
            data={currentBookings}
            idFromItem={idFromItem}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            renderItem={(row, index, { isSelected, handleSelectRow }) => (
              <BookingLogRow
                key={`booking-row-${index}`}
                booking={row}
                isActive={
                  bookingPanel.relatedRowIndex === index ||
                  payoutModal.relatedRowIndex === index
                }
                onPreviewExperience={handleViewBooking(row, index)}
                onPreviewCreator={handlePreviewUser(
                  row.booking.gig.provider,
                  index
                )}
                onPreviewPaymentDetails={handleGetBookingPayout(
                  row.bookingId,
                  row.booking.tickets,
                  index
                )}
              />
            )}
          />
        </div>

        <div className='CouponsReport__pageButtons'>
          <IconButton
            color='secondary'
            onClick={handlePrevPage}
            disabled={
              isSearching || showingSearchResults || isLoadingMore || page === 0
            }
            size='large'
          >
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
          <IconButton
            color='secondary'
            onClick={handleNextPage}
            disabled={
              isSearching || showingSearchResults || isLoadingMore || !hasMore
            }
            size='large'
          >
            <NavigateNextIcon fontSize='large' />
          </IconButton>
          {isLoadingMore && (
            <Loader className='CouponsReport__loader' adaptToParent size={32} />
          )}
        </div>
      </div>

      <BookingPanel
        isOpen={bookingPanel.isOpen}
        bookingId={bookingPanel.bookingId}
        experienceId={bookingPanel.experienceId}
        title='Experince Details'
        onClose={hanldeCloseBookingPanel}
      />

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={hanldeCloseUserPanel}
      />

      <PaymentDetailsModal
        isOpen={payoutModal.isOpen}
        isProcessing={payoutModal.isProcessing}
        payouts={payoutModal.payouts}
        tickets={payoutModal.tickets}
        onAccept={handleClosePayoutModal}
        acceptButtonVisible={!payoutModal.isProcessing}
        acceptButtonTitle='Close'
        cancelButtonVisible={false}
      />

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default BookingLogs;
