import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserViewModel } from '../../core/backend/models';
import { UserRoles } from '../../core/enums';
import rolesService from '../../core/roles-service';

interface AuthState {
  user: UserViewModel | null;
  accessPermissionAreas: string[];
}

const rolesHelper = rolesService();

const initialState: AuthState = {
  user: null,
  accessPermissionAreas: rolesHelper.getUserAccessPermissionAreas(
    UserRoles.Unknown
  )
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInUser: (state, action: PayloadAction<UserViewModel>) => {
      const accessLevel = action.payload?.accessLevel ?? 0;

      state.user = action.payload;
      state.accessPermissionAreas = rolesHelper.getUserAccessPermissionAreas(
        accessLevel as unknown as UserRoles
      );
    },
    logOutUser: (state) => initialState
  }
});

export const { signInUser, logOutUser } = authSlice.actions;
