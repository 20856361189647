import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEventRow } from '../../core/types';

const initialState: IEventRow[] = [];

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addAllEvents: (state, action: PayloadAction<IEventRow[]>) => action.payload
  }
});

export const { addAllEvents } = eventsSlice.actions;
