import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  GigViewModel,
  UserRedemptionCodeViewModel,
  UserViewModel
} from '../../core/backend/models';
import { FilterOptionType, IMainPanel, IUserPanel } from '../../core/types';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import './CouponsReport.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { couponRedemptionFilter } from '../../core/helpers/filter-helpers';
import Filter from '../../components/Filter/Filter';
import appToast from '../../core/toast';
import {
  DEFAULT_ERROR_MESSAGE,
  COUPONS_REPORT_PER_PAGE
} from '../../core/consts';
import Loader from '../../components/Loader/Loader';
import LazyTable from '../../components/Tables/LazyTable/LazyTable';
import { getCouponsReport } from '../../core/api';
import CouponsReporTableHeader from '../../components/TableHeaders/CouponsReporTableHeader/CouponsReporTableHeader';
import CouponReportRow from '../../components/TableRows/CouponReportRow/CouponReportRow';
import GigPanel from '../../components/DetailsPanel/GigPanel/GigPanel';
import { SortType } from '../../core/enums';

const CouponsReport = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [panelState, setPanelState] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [experiencePanel, setExperiencePanel] = useState<IMainPanel>({
    isOpen: false,
    target: null,
    type: 'Experience',
    relatedRowIndex: -1
  });
  const [couponReport, setCouponReport] = useState<
    UserRedemptionCodeViewModel[]
  >([]);
  const [currentCouponReport, setCurrentCouponReport] = useState<
    UserRedemptionCodeViewModel[]
  >([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  /**
   * Request all reports
   */

  useEffect(() => {
    getCouponsReportData();
  }, []);

  //This triggers whenever a user is updated locally
  useEffect(() => {
    if (isSearching) {
      // updateSearchResults();
      return;
    }

    // setCurrentCouponReport(users);
  }, [couponReport, isSearching, page]);

  useEffect(() => {
    pageContent();

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [page]);

  const pageContent = () => {
    const startIndex = (page - 1) * COUPONS_REPORT_PER_PAGE;
    const endIndex = startIndex + COUPONS_REPORT_PER_PAGE;
    const _report = couponReport.slice(startIndex, endIndex);

    setCurrentCouponReport(_report);
  };

  /**
   * Table methods
   */

  const handleSelectionChange = (selectedRows: string[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: UserRedemptionCodeViewModel) => {
    return uuidv4();
  };

  /**
   * Actions
   */

  const getCouponsReportData = async () => {
    try {
      setIsLoadingMore(true);
      const _reports = await getCouponsReport(skip, COUPONS_REPORT_PER_PAGE);

      if (_reports.length) {
        setCouponReport([...couponReport, ..._reports]);
        setCurrentCouponReport([...currentCouponReport, ..._reports]);
        setSkip((skip) => skip + _reports.length);
      }

      setHasMore(_reports.length > 0);
    } catch (e: any) {
      appToast.showError('Failed getting users');
    } finally {
      setIsLoadingMore(false);
      setIsProcessing(false);
    }
  };

  const filterReportsWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const bearerTypeFilters: string[] = selectedFilters.map(
        (filter) => filter.value
      );

      const checkFilterTypes = (report: UserRedemptionCodeViewModel) =>
        bearerTypeFilters.includes(report.bearerType.toString());

      const filteredReports = couponReport.filter(checkFilterTypes);
      setCurrentCouponReport(filteredReports);
    }
  };

  const handleSearchContent = async (query: string) => {
    try {
      if (!query.length) {
        setIsSearching(false);
        setShowingSearchResults(false);
        setCurrentCouponReport(couponReport);
        return pageContent();
      }

      setIsSearching(true);

      // const lowerCasedQuery = query.toLowerCase();
      // let _result = couponReport.filter(
      //   searchCouponReportFilter(lowerCasedQuery)
      // );

      const searchResults = await getCouponsReport(
        0,
        COUPONS_REPORT_PER_PAGE,
        query
      );
      setCurrentCouponReport(searchResults);
      setShowingSearchResults(true);
    } catch (e: any) {
      appToast.showError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSearching(false);
    }
  };

  const handlePrevPage = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleNextPage = async () => {
    if (!hasMore) return;

    const startIndex = page * COUPONS_REPORT_PER_PAGE;
    const endIndex = startIndex + COUPONS_REPORT_PER_PAGE;

    if (endIndex >= couponReport.length - 1) {
      await getCouponsReportData();
    }

    setPage((prevState) => prevState + 1);
  };

  /**
   * Side Panel
   */

  const handleViewUser =
    (user: UserViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setPanelState({ isOpen: true, user, relatedRowIndex });
    };

  const handleViewExperience =
    (experience: GigViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setExperiencePanel({
        ...experiencePanel,
        isOpen: true,
        target: experience,
        relatedRowIndex
      });
    };

  const closePanel = () => {
    setPanelState({
      ...panelState,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  const closeExperiencePanel = () => {
    setExperiencePanel({
      ...experiencePanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  /**
   * Modal Methods
   */

  if (isProcessing) return <Loader adaptToParent size={60} />;

  return (
    <>
      <div className='CouponsReport'>
        <div className='CouponsReport__filterControls'>
          <SearchInput
            isProcessing={isSearching}
            onDebouncedTextChange={handleSearchContent}
            className='CouponsReport__search'
          />
          <Filter
            options={couponRedemptionFilter}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentCouponReport(couponReport);
                return;
              }

              filterReportsWith(selectedFilters);
            }}
          />
        </div>

        <div className='CouponsReport__content'>
          <LazyTable
            Header={CouponsReporTableHeader}
            data={currentCouponReport}
            idFromItem={idFromItem}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrder={SortType.Asc}
            initialOrderBy='userName'
            renderItem={(item, idx) => (
              <CouponReportRow
                report={item}
                key={idFromItem(item)}
                selected={
                  panelState.relatedRowIndex === idx ||
                  experiencePanel.relatedRowIndex === idx
                }
                onViewUser={handleViewUser(item.customer, idx)}
                onViewExperience={handleViewExperience(item.gig, idx)}
              />
            )}
          />
        </div>

        <div className='CouponsReport__pageButtons'>
          <IconButton
            color='secondary'
            onClick={handlePrevPage}
            disabled={
              isSearching || showingSearchResults || isLoadingMore || page === 1
            }
            size='large'
          >
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
          <IconButton
            color='secondary'
            onClick={handleNextPage}
            disabled={
              isSearching || showingSearchResults || isLoadingMore || !hasMore
            }
            size='large'
          >
            <NavigateNextIcon fontSize='large' />
          </IconButton>
          {isLoadingMore && (
            <Loader className='CouponsReport__loader' adaptToParent size={32} />
          )}
        </div>
      </div>

      <UserPanel
        isOpen={panelState.isOpen}
        user={panelState.user}
        onClose={closePanel}
      />

      <GigPanel
        isOpen={experiencePanel.isOpen}
        gig={experiencePanel.target}
        onClose={closeExperiencePanel}
      />
    </>
  );
};

export default CouponsReport;
