import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { TableSortLabel } from '@mui/material';
import { SortType } from '../../../core/enums';

interface IProps {
  numSelected: number;
  rowCount: number;
  order: SortType;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSort: React.MouseEventHandler;
}

const CommentsTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  order,
  onSort
}: IProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell align='left' padding='none'>
          Comment
        </TableCell>
        <TableCell align='left' padding='normal'>
          User
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel active={true} direction={order} onClick={onSort}>
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CommentsTableHeader;
