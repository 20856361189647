import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentType, ReportType } from '../../core/types';

export interface ScheduleAction {
  id: number;
  type: ReportType | ContentType;
  action: 'approved' | 'declined' | 'rejected' | number;
  extra?: string | number;
  additionalData?: string;
}

const initialState: ScheduleAction[] = [];

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    addSchedule: (state, action: PayloadAction<ScheduleAction>) => [
      ...state,
      action.payload
    ],
    addSchedules: (state, action: PayloadAction<ScheduleAction[]>) => [
      ...state,
      ...action.payload
    ],
    clearSchedules: (state) => []
  }
});

export const { addSchedule, addSchedules, clearSchedules } =
  scheduleSlice.actions;
