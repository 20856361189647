import { getMediaFile } from '../../../core/api';
import { ReportSearchResult } from '../../../core/types';
import Status from '../../Status/Status';
import UserImage from '../../UserImage/UserImage';
import Video from '../../Video/Video';
import './ReportCard.scss';

interface Props extends ReportSearchResult {
  className?: string;
}

const ReportCard = ({
  videoUrl,
  thumbnailUrl,
  description,
  status,
  type,
  ownerName,
  ownerImage,
  className = ''
}: Props) => {
  return (
    <div className={`ReportCard ${className}`}>
      <Video
        className='ReportCard__video'
        size='small'
        url={getMediaFile(videoUrl)}
        coverUrl={getMediaFile(thumbnailUrl)}
      />
      <div className='ReportCard__details'>
        <div className='ReportCard__info'>
          <b>{description}</b>
        </div>

        <div className='ReportCard__info ReportCard__info--capitalize'>
          {type} &middot;
          <Status sx={{ height: '18px', marginLeft: '5px' }} status={status} />
        </div>
        <div className='ReportCard__info'>
          <UserImage
            className='ReportCard__image'
            size='sm'
            url={getMediaFile(ownerImage)}
          />
          <div className='ReportCard__userNameWrapper'>{ownerName}</div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
