import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITableHeaderProps, IUserRow } from '../../../core/types';
import { TableSortLabel } from '@mui/material';
import { UserRedemptionCodeViewModel } from '../../../core/backend/models';

const CouponsReporTableHeader = ({
  onRequestSort,
  order,
  orderBy
}: ITableHeaderProps<UserRedemptionCodeViewModel>) => {
  const createSortHandler =
    (property: keyof UserRedemptionCodeViewModel) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          <TableSortLabel
            active={orderBy === 'code'}
            direction={orderBy === 'code' ? order : 'asc'}
            onClick={createSortHandler('code')}
          >
            Code
          </TableSortLabel>
        </TableCell>

        <TableCell align='left' padding='normal'>
          Coupon
        </TableCell>

        <TableCell align='right' padding='normal'>
          Amount/Percentage
        </TableCell>

        <TableCell align='left' padding='normal'>
          Bearer
        </TableCell>

        <TableCell align='left' padding='normal'>
          Experience
        </TableCell>

        <TableCell align='left' padding='normal'>
          Customer
        </TableCell>

        <TableCell align='left' padding='normal'>
          Creator
        </TableCell>

        <TableCell align='right' padding='normal'>
          Experience Cost
        </TableCell>

        <TableCell align='right' padding='normal'>
          Discounted Price
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CouponsReporTableHeader;
