import * as yup from 'yup';
import dayjs from 'dayjs';

export default yup.object({
  code: yup
    .string()
    .trim()
    .min(6, 'Coupon code must be 6 characters min')
    .max(20, 'Coupon code must be 20 characters max')
    .matches(/^[A-Z0-9]+$/, 'Include only capital letters and numbers')
    .required('Required'),
  value: yup
    .number()
    .required('Required')
    .test(
      'value',
      'Price cannot be lower than one',
      function (value: any, context) {
        if (context.parent.type === 'percentage') return true;

        return value > 0;
      }
    )
    .test(
      'value',
      'Percentage cannot be 0% or greater than 100%',
      function (value: any, context) {
        if (context.parent.type === 'fixed') return true;

        return value > 0 && value <= 100;
      }
    ),
  maxRedemptions: yup
    .number()
    .required('Required')
    .min(0, `Must be zero or greater`),
  maxRedemptionsByCustomer: yup
    .number()
    .required('Required')
    .min(1, `Must be greater than zero`)
    .test(
      'maxRedemptions',
      'Max Redemptions Per User cannot be greater than coupon Max Redemptions',
      function (value: any, context) {
        if (context.parent.maxRedemptions === 0) return true;
        return context.parent.maxRedemptions >= value;
      }
    )
});

export const DiscountSchema2 = yup.object({
  costBearer: yup
    .string()
    .test('couponId', 'Select Bearer', function (value: string) {
      return value !== '-1';
    })
    .required('Required'),
  expirationDate: yup
    .date()
    .required('Required field')
    .nullable()
    .default(undefined)
    .test(
      'expirationDate',
      'Select a correct expiration date. it cannot be less than now',
      function (value: Date) {
        if (!value) return false;

        const currentHour = new Date();
        currentHour.setHours(23, 0, 0, 0);

        return value.getTime() >= currentHour.getTime();
      }
    )
});
