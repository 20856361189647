import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Scrollbar from 'react-scrollbars-custom';
import { ProviderSummaryResponse } from '../../../core/backend/models';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './ProviderStatsTab.scss';

interface IProps extends IBaseTabProps {
  stats?: ProviderSummaryResponse;
}

const ProviderStatsTab = ({ stats, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='ProviderStatsTab'>
      <Scrollbar
        noScrollX={true}
        thumbYProps={{ className: 'thumbX' }}
        trackYProps={{ className: 'trackX' }}
        height='100%'
        width='100%'
      >
        <Grid
          container
          style={{ padding: '12px' }}
          direction={'column'}
          rowSpacing={2}
        >
          <Grid item>
            <TextField
              id='videoviews'
              label='Video Views'
              variant='standard'
              value={stats?.videoViewsCount ?? 0}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id='videolikes'
              label='Video Likes'
              variant='standard'
              value={stats?.videoLikesCount ?? 0}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id='followers'
              label='Followers'
              variant='standard'
              value={stats?.followersCount ?? 0}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Scrollbar>
    </BaseTab>
  );
};

export default ProviderStatsTab;
