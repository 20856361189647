import TableCell from '@mui/material/TableCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getMediaFile } from '../../../core/api';
import './BookingLogRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import { translateBookingStatus } from '../../../core/helpers/enums-helpers';
import { IExperienceLogRow } from '../../../core/types';
import Video from '../../Video/Video';
import { HOUR_IN_SECONDS } from '../../../core/consts';
import { convertToCurrency } from '../../../core/helpers/helpers';
import { getExperienceThumbnails } from '../../../core/helpers/model-helpers';

interface IProps extends IBaseRowProps {
  booking: IExperienceLogRow;
  isActive: boolean;
  onPreviewExperience: React.MouseEventHandler;
  onPreviewCreator: React.MouseEventHandler;
  onPreviewPaymentDetails: React.MouseEventHandler;
}

const BookingLogRow = ({
  booking,
  isActive,
  onClick,
  onPreviewExperience,
  onPreviewCreator,
  onPreviewPaymentDetails
}: IProps) => {
  const isHourly = booking.booking.gig?.pricingModel === 0;
  const totalBookedHours = booking.booking.duration / HOUR_IN_SECONDS;
  const expPrice = booking.booking.price;

  const { videoUrl, staticImageUrl } = getExperienceThumbnails(
    booking.booking.gig.gigMainMediaFiles ?? []
  );

  return (
    <BaseRow selected={isActive}>
      <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
        <div className='BookingLogRow__videoRow' onClick={onPreviewExperience}>
          <Video
            className='BookingLogRow__video'
            coverUrl={staticImageUrl}
            url={videoUrl}
          />
          <div>
            <p>
              <b>{booking.booking.gig.name}</b>
            </p>
            <p className='BookingLogRow__reservationId'>
              {/* <b>{booking.reservationId}</b> */}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <p className='BookingLogRow__cellRow'>
          {booking.formattedTransactionDate}
        </p>
        <p className='BookingLogRow__cellRow'>
          {booking.formattedTransactionTime}
        </p>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '160px' }}>
        <div className='BookingLogRow__userInfo' onClick={onPreviewCreator}>
          <UserImage
            className='BookingLogRow__image'
            url={getMediaFile(booking.booking.gig.provider.image)}
          />
          <div className='BookingLogRow__userNameWrapper'>
            <div className='BookingLogRow__userName'>
              @{booking.booking.gig.provider.userName}
            </div>
            <div className='BookingLogRow__userFullName'>
              {booking.booking.gig.provider.fullName}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '120px' }}
      >
        Creator
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '160px' }}
      >
        {translateBookingStatus(booking.bookingStatus)}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ width: '250px' }}>
        <div
          className='BookingLogRow__invoice'
          onClick={onPreviewPaymentDetails}
        >
          <div className='BookingLogRow__invoiceData'>
            {isHourly
              ? `${convertToCurrency(
                  booking.subTotalAmount
                )} /hr x ${totalBookedHours} = `
              : ''}
            <span>{convertToCurrency(expPrice)}</span>
          </div>

          <OpenInNewIcon />
        </div>
      </TableCell>
    </BaseRow>
  );
};

export default BookingLogRow;
