import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import {
  changeUserPassword,
  changeUserVisibility,
  createRegistration,
  deleteUser,
  getUsers,
  grantRestrictUserAccess,
  resetUserPassword,
  searchUser,
  setUserScore,
  updateUser
} from '../../core/api';
import { UserViewModel } from '../../core/backend/models';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import NewUserModal from '../../components/Modals/NewUserModal/NewUserModal';
import UserRow from '../../components/TableRows/UserRow/UserRow';
import EditUserModal from '../../components/Modals/EditUserModal/EditUserModal';
import UserTableHeader from '../../components/TableHeaders/UserTableHeader/UserTableHeader';
import {
  FilterOptionType,
  IMessage,
  INewUSerData,
  IUserPanel,
  IUserRow
} from '../../core/types';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import './Users.scss';
import {
  addLocalUser,
  appendUsers,
  deleteLocalUsers,
  updateLocalGrantRestrictUserAccess,
  updateLocalUser,
  updateLocalUserVisibility,
  updateLocalUseScore,
  useAppDispatch,
  useAppSelector
} from '../../state';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchUsersFilter } from '../../core/helpers/search-helpers';
import { userFilterOptions } from '../../core/helpers/filter-helpers';
import Filter from '../../components/Filter/Filter';
import { createExtendedUserModel } from '../../core/helpers/model-helpers';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE, USERS_PER_PAGE } from '../../core/consts';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Loader from '../../components/Loader/Loader';
import LazyTable from '../../components/Tables/LazyTable/LazyTable';
import { SortType } from '../../core/enums';
import ChangeUserPasswordModal from '../../components/Modals/ChangeUserPasswordModal/ChangeUserPasswordModal';
interface IUserCredentials {
  isOpen: boolean;
  email?: string;
  password?: string;
}
interface IUserModal {
  isOpen: boolean;
  isProcessing?: boolean;
  user?: UserViewModel | string;
}

interface IDeleteModal {
  isOpen: boolean;
  users?: string[] | null;
}

const Users = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users);
  const [isProcessing, setIsProcessing] = useState(false);
  const [skip, setSkip] = useState(users.length);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState<IUserModal>({
    isOpen: false,
    isProcessing: false,
    user: null
  });
  const [resetPasswordModal, setResetPasswordModal] = useState<IUserModal>({
    isOpen: false,
    user: null
  });
  const [userCredentials, setUserCredentials] = useState<IUserCredentials>({
    isOpen: false,
    email: '',
    password: ''
  });
  const [editModal, setEditModal] = useState<IUserModal>({
    isOpen: false,
    user: null
  });
  const [deleteModal, setDeleteModal] = useState<IDeleteModal>({
    isOpen: false,
    users: null
  });
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [panelState, setPanelState] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [currentUsers, setCurrentUsers] = useState<IUserRow[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  //This triggers whenever a user is updated locally
  useEffect(() => {
    setCurrentUsers(users);
  }, [users]);

  useEffect(() => {
    const startIndex = page * USERS_PER_PAGE;
    const endIndex = startIndex + USERS_PER_PAGE;
    const _users = users.slice(startIndex, endIndex);

    setCurrentUsers(_users);

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [page]);

  /**
   * Table methods
   */

  const handleSelectionChange = (selectedRows: string[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: UserViewModel) => {
    return item.id;
  };

  /**
   * Actions
   */

  const getMoreUsers = async () => {
    try {
      setIsLoadingMore(true);
      const userResponse = await getUsers(skip, USERS_PER_PAGE);
      const _users = userResponse.map(createExtendedUserModel);

      if (_users.length) {
        dispatch(appendUsers(_users));
        setSkip((skip) => skip + _users.length);
      }

      setHasMore(users.length > 0);
    } catch (e: any) {
      appToast.showError('Failed getting users');
    } finally {
      setIsLoadingMore(false);
    }
  };

  const filterUsersWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const userTypeFilters: string[] = [];
      const accountTypeFilters: string[] = [];
      const accountStatusFilters: string[] = [];

      selectedFilters.forEach((filter) => {
        if (filter.group === 'User Type') userTypeFilters.push(filter.value);
        else if (filter.group === 'Account Type')
          accountTypeFilters.push(filter.value);
        else accountStatusFilters.push(filter.value);
      });

      const checkFilterTypes = (user: IUserRow) =>
        userTypeFilters.length &&
        accountTypeFilters.length &&
        accountStatusFilters.length // user type, account type and account status
          ? userTypeFilters.includes(user.userType) &&
            accountTypeFilters.includes(user.accountType) &&
            accountStatusFilters.includes(user.accountStatus)
          : userTypeFilters.length && accountTypeFilters.length //user type and account type only
          ? userTypeFilters.includes(user.userType) &&
            accountTypeFilters.includes(user.accountType)
          : userTypeFilters.length && accountStatusFilters.length //user type and account status only
          ? userTypeFilters.includes(user.userType) &&
            accountStatusFilters.includes(user.accountStatus)
          : accountTypeFilters.length && accountStatusFilters.length //account type and account status only
          ? accountTypeFilters.includes(user.accountType) &&
            accountStatusFilters.includes(user.accountStatus)
          : userTypeFilters.includes(user.userType) || // only one filter is selected
            accountTypeFilters.includes(user.accountType) ||
            accountStatusFilters.includes(user.accountStatus);

      const filteredUsers = users.filter(checkFilterTypes);

      setCurrentUsers(filteredUsers);
    }
  };

  const handleCreateNewUser = async (values: INewUSerData) => {
    let success = false;
    try {
      setIsProcessing(true);
      const userAccount = await createRegistration({
        ...values,
        birthDay: values.dateOfBirth.getTime() / 1000
      });

      closeNewUserModal();
      setUserCredentials({ isOpen: true, ...userAccount.credentials });
      dispatch(addLocalUser(createExtendedUserModel(userAccount.user)));
      success = true;
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: e.response?.data?.description || null
      });
    } finally {
      setIsProcessing(false);
    }

    return success;
  };

  const handleUpdateUser = async (
    prevUserData: UserViewModel,
    newUserData: UserViewModel
  ) => {
    const {
      id: userId,
      fullName,
      userName,
      website,
      about,
      email,
      address1,
      address2,
      city,
      state,
      zip,
      isCreatorPro,
      isProviderPro
    } = newUserData;
    const formData = new FormData();
    try {
      if (prevUserData.fullName !== fullName)
        formData.append('newFullName', fullName);

      if (prevUserData.userName !== userName)
        formData.append('newUsername', userName);

      if (prevUserData.website !== website)
        formData.append('newWebsite', website);

      if (prevUserData.about !== about) formData.append('newAbout', about);

      if (prevUserData.isCreatorPro !== isCreatorPro)
        formData.append('upgradeToPro', isCreatorPro.toString());

      if (prevUserData.isProviderPro !== isProviderPro)
        formData.append('upgradeToProviderPro', isProviderPro.toString());

      if (prevUserData.address1 !== address1)
        formData.append('newAddress1', address1);

      if (prevUserData.address2 !== address2)
        formData.append('newAddress2', address2);

      if (prevUserData.email !== email) formData.append('newEmail', email);
      if (prevUserData.city !== city) formData.append('newCity', city);
      if (prevUserData.state !== state) formData.append('newState', state);
      if (prevUserData.zip !== zip) formData.append('newZip', zip);

      formData.append('userId', userId);

      setIsProcessing(true);

      const _updatedUser = await updateUser(formData);
      dispatch(updateLocalUser(createExtendedUserModel(_updatedUser)));

      handleCloseEditModal();
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: e.response?.data?.description || null
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteSelectedUsers = async () => {
    setIsProcessing(true);

    try {
      const deleteRequests = deleteModal.users.map((id) => deleteUser(id));
      await Promise.all(deleteRequests);

      dispatch(deleteLocalUsers(deleteModal.users));
      setSelectedItems([]);
      handleCloseDeleteUser();
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: 'Failed to delete user(s). Try again.'
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleShowEditModal =
    (user: UserViewModel) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setEditModal({ isOpen: true, user });
    };

  const handleChangeUserVisibility =
    (userId: string, visible: boolean) => async () => {
      try {
        dispatch(updateLocalUserVisibility({ userId, visible }));
        await changeUserVisibility(userId, visible);
      } catch (e: any) {
        appToast.showError(
          'An error occured while changing user visibillity. Try again.'
        );
        dispatch(updateLocalUserVisibility({ userId, visible: !visible }));
      }
    };

  const handleGrantRestrictUserAccess =
    (userId: string, restrictAccess: boolean) => async () => {
      try {
        dispatch(
          updateLocalGrantRestrictUserAccess({ userId, restrictAccess })
        );
        await grantRestrictUserAccess(userId, restrictAccess);
      } catch (e: any) {
        appToast.showError(
          'An error occured while granting/restricting user access. Try again.'
        );
        dispatch(
          updateLocalGrantRestrictUserAccess({
            userId,
            restrictAccess: !restrictAccess
          })
        );
      }
    };

  const handleResetUserPassword = async () => {
    setIsProcessing(true);

    try {
      await resetUserPassword((resetPasswordModal.user as UserViewModel).email);
      handleCloseResetPassword();
      appToast.showSuccess("Reset intructions were sent to this user's email");
    } catch (e: any) {
      appToast.showError(
        "Something went wrong resetting user's password. Try again."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handleChangeScore =
    (userId: string) => (newScore: number, prevScore: number) => {
      const _newScore = newScore ?? 0;

      dispatch(updateLocalUseScore({ userId, score: newScore }));

      setUserScore(userId, _newScore).catch((e) => {
        dispatch(updateLocalUseScore({ userId, score: prevScore }));
        appToast.showError(
          'An error occured while rating this user. Try again.'
        );
      });
    };

  const handleChangeUserPassword = async (newPassword: string) => {
    try {
      setChangePasswordModal((prevState) => ({
        ...prevState,
        isProcessing: true
      }));

      await changeUserPassword(changePasswordModal.user as string, newPassword);

      appToast.showSuccess('New password set.');

      handleCloseChangePassword();
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);

      setChangePasswordModal((prevState) => ({
        ...prevState,
        isProcessing: false
      }));
    }
  };

  const handleSearchContent = async (query: string) => {
    try {
      if (!query.length) {
        setIsSearching(false);
        setCurrentUsers(users);
        return;
      }

      setIsSearching(true);

      const response = await searchUser(query);
      const _users = response.map(createExtendedUserModel);

      setCurrentUsers(_users);
    } catch (e: any) {
      appToast.showError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSearching(false);
    }
  };

  const handlePrevPage = () => {
    setPage((prevState) => prevState - 1);
  };

  const handleNextPage = async () => {
    if (!hasMore) return;

    const startIndex = page * USERS_PER_PAGE;
    const endIndex = startIndex + USERS_PER_PAGE;

    if (endIndex >= users.length - 1) {
      await getMoreUsers();
    }

    setPage((prevState) => prevState + 1);
  };

  /**
   * Side Panel
   */

  const handleViewUser =
    (user: IUserRow, relatedRowIndex: number) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setPanelState({ isOpen: true, user, relatedRowIndex });
    };

  const closePanel = () => {
    setPanelState({
      ...panelState,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  /**
   * Modal Methods
   */
  const handleShowNewUserModal = () => {
    setIsNewUserOpen(true);
  };

  const closeNewUserModal = () => {
    setIsNewUserOpen(false);
  };

  const handleCloseCredetials = () => {
    setUserCredentials({ isOpen: false, email: '', password: '' });
  };

  const handleCloseEditModal = () => {
    setEditModal({ isOpen: false, user: null });
  };

  const handleShowChangePassword = (user: string) => () => {
    setChangePasswordModal({ isOpen: true, user });
  };

  const handleShowResetPassword = (user: UserViewModel) => () => {
    setResetPasswordModal({ isOpen: true, user });
  };

  const handleShowDeleteUser =
    (userId: string[]) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDeleteModal({ isOpen: true, users: userId });
    };

  const handleCloseDeleteUser = () => {
    setDeleteModal({ isOpen: false, users: null });
  };

  const handleCloseChangePassword = () => {
    setChangePasswordModal({ isOpen: false, isProcessing: false, user: null });
  };

  const handleCloseResetPassword = () => {
    setResetPasswordModal({ isOpen: false, user: null });
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  /**
   * Init Infinite Scroll
   */
  // const [sentryRef] = useInfiniteScroll({
  //   loading: isLoadingMore,
  //   hasNextPage: hasMore,
  //   onLoadMore: getMoreUsers,
  //   disabled: !hasMore,
  //   rootMargin: '0px 0px 400px 0px'
  // });

  return (
    <MainLayout>
      <div className='Users'>
        <div className='Users__actionButtons'>
          <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={handleShowNewUserModal}
          >
            New user
          </Button>
        </div>

        <div className='Users__filterControls'>
          <SearchInput
            isProcessing={isSearching}
            onDebouncedTextChange={handleSearchContent}
            className='Users__search'
          />
          <Filter
            options={userFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentUsers(users);
                return;
              }

              filterUsersWith(selectedFilters);
            }}
          />
        </div>

        <div className='Users__content'>
          <LazyTable
            Header={UserTableHeader}
            data={currentUsers}
            idFromItem={idFromItem}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrder={SortType.Asc}
            initialOrderBy='userName'
            renderItem={(user, idx, { isSelected, handleSelectRow }) => (
              <UserRow
                user={user}
                key={user.id}
                selected={isSelected}
                onSelect={handleSelectRow(user.id)}
                onViewDetails={handleViewUser(user, idx)}
                onEdit={handleShowEditModal(user)}
                onDelete={handleShowDeleteUser([user.id])}
                onChangeVisibility={handleChangeUserVisibility(
                  user.id,
                  !user.accountVisibility
                )}
                onRestrictAccess={handleGrantRestrictUserAccess(
                  user.id,
                  !user.restrictAccess
                )}
                onChangePassword={handleShowChangePassword(user.id)}
                onResetPassword={handleShowResetPassword(user)}
                onChangeScore={handleChangeScore(user.id)}
              />
            )}
          />
        </div>
        {/* {(isLoadingMore || hasMore) && (
          <div ref={sentryRef}>
            <Loader adaptToParent size={32} />
          </div>
        )} */}

        <div className='Users__pageButtons'>
          <IconButton
            color='secondary'
            onClick={handlePrevPage}
            disabled={isLoadingMore || page === 0}
            size='large'
          >
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
          <IconButton
            color='secondary'
            onClick={handleNextPage}
            disabled={isLoadingMore || !hasMore}
            size='large'
          >
            <NavigateNextIcon fontSize='large' />
          </IconButton>
          {isLoadingMore && (
            <Loader className='Users__loader' adaptToParent size={32} />
          )}
        </div>
      </div>

      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onDeclineAll={handleShowDeleteUser(selectedItems)}
          declineTitle='Delete'
        />
      )}

      <UserPanel
        isOpen={panelState.isOpen}
        user={panelState.user}
        onClose={closePanel}
      />

      <NewUserModal
        isOpen={isNewUserOpen}
        onClose={closeNewUserModal}
        isProcessing={isProcessing}
        onAccept={handleCreateNewUser}
      />

      <EditUserModal
        isOpen={editModal.isOpen}
        user={editModal.user as UserViewModel}
        onClose={handleCloseEditModal}
        isProcessing={isProcessing}
        onAccept={handleUpdateUser}
      />

      <BaseModal
        title='User Credentials'
        isOpen={userCredentials.isOpen}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseCredetials}
      >
        <p>
          Email: <b>{userCredentials.email}</b>
        </p>
        <p>
          Password: <b>{userCredentials.password}</b>
        </p>
      </BaseModal>

      <BaseModal
        title='Delete User'
        isOpen={deleteModal.isOpen}
        acceptButtonTitle='Delete'
        onAccept={handleDeleteSelectedUsers}
        isProcessing={isProcessing}
        onClose={handleCloseDeleteUser}
      >
        Are you sure you want to delete selected user(s)? This action can not be
        undone.
      </BaseModal>

      <ChangeUserPasswordModal
        isOpen={changePasswordModal.isOpen}
        isProcessing={changePasswordModal.isProcessing}
        acceptButtonTitle='Change Password'
        onAccept={handleChangeUserPassword}
        onClose={handleCloseChangePassword}
      />

      <BaseModal
        title='Reset Password'
        isOpen={resetPasswordModal.isOpen}
        acceptButtonTitle='Send Reset Instructions'
        onAccept={handleResetUserPassword}
        isProcessing={isProcessing}
        onClose={handleCloseResetPassword}
      >
        Are you sure you want to reset this user's password? This action can not
        be undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </MainLayout>
  );
};

export default Users;
