import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Scrollbar from 'react-scrollbars-custom';
import { UserViewModel } from '../../../core/backend/models';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './UserTab.scss';
import UserImage from '../../UserImage/UserImage';
import { getMediaFile } from '../../../core/api';

interface IProps extends IBaseTabProps {
  user?: UserViewModel;
}

const UserTab = ({ user, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='UserTab'>
      <Scrollbar
        noScrollX={true}
        thumbYProps={{ className: 'thumbX' }}
        trackYProps={{ className: 'trackX' }}
        height='100%'
        width='100%'
      >
        <Grid
          container
          style={{ padding: '17px 12px 12px' }}
          direction={'column'}
          rowSpacing={2}
        >
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <UserImage size='lg' url={getMediaFile(user?.image)} />
          </Grid>

          <Grid item>
            <TextField
              id='name'
              label='Full Name'
              variant='standard'
              value={user?.fullName ?? ''}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id='username'
              label='Username'
              variant='standard'
              value={user?.userName ?? ''}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id='email'
              label='Email'
              variant='standard'
              value={user?.email ?? ''}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id='creator'
              label='Account Type'
              variant='standard'
              value={user?.isCreatorPro ? 'Creator' : 'Customer'}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id='Address'
              label='Address'
              variant='standard'
              type='text'
              multiline
              value={[
                user.address1,
                user.address2,
                user.city,
                user.state,
                user.zip
              ].join(', ')}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          {user.isCreatorPro && (
            <>
              <Grid item>
                <TextField
                  id='business'
                  label='Business Name'
                  variant='standard'
                  value={user?.businessName ?? ''}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item>
                <TextField
                  id='business-phone'
                  label='Business Phone'
                  variant='standard'
                  value={user?.businessPhone ?? ''}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item>
                <TextField
                  id='business-email'
                  label='Business Email'
                  variant='standard'
                  value={user?.businessEmail ?? ''}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </Scrollbar>
    </BaseTab>
  );
};

export default UserTab;
