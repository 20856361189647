import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { SimpleFilterOptionType } from '../../core/types';
import { useDebounce } from '../../hooks/useDebounce';

interface Props<T> {
  label?: string;
  placeholder?: string;
  noOptionsText?: string;
  labelProp: keyof T;
  valueProp: keyof T;
  minLength?: number;
  delay?: number;
  value: T[];
  options: T[];
  fetchMethod: (query: string) => Promise<T[]>;
  onOptionsChanged: (options: T[]) => void;
  onChange: (retunValue: string | number[] | null, value: T[]) => void;
}

const SimpleFilter = <T extends {}, K extends string | number>({
  label = '',
  placeholder = '',
  noOptionsText,
  labelProp,
  valueProp,
  minLength = 3,
  delay = 600,
  value = [],
  options = [],
  fetchMethod,
  onOptionsChanged,
  onChange
}: Props<T>) => {
  // const [value, setValue] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  // const [options, setOptions] = useState<T[]>([]);
  const trimmed = inputValue.trim();
  const debouncedSearchText = useDebounce(
    trimmed.length >= minLength ? trimmed : '',
    delay
  );

  useEffect(() => {
    if (!debouncedSearchText) {
      onOptionsChanged(value ?? []);
      return;
    }

    (async () => {
      const results = await fetchMethod(debouncedSearchText);
      onOptionsChanged(results);
    })();
  }, [debouncedSearchText, fetchMethod]);

  const convertToReturnValue = (values: T[]): string | number[] => {
    return values.reduce((acc, item) => [...acc, item[valueProp]], []);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      autoComplete
      includeInputInList
      filterSelectedOptions
      size='small'
      options={options}
      noOptionsText={noOptionsText}
      isOptionEqualToValue={(option, value) =>
        option[labelProp] === value[labelProp]
      }
      getOptionLabel={(option) => (option as T)[labelProp] as unknown as string}
      filterOptions={(x) => x}
      value={value}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue: any | null) => {
        onChange(newValue ? convertToReturnValue(newValue) : [], newValue);
        // setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth
        />
      )}
    />
  );
};

export default SimpleFilter;
