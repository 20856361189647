import { useEffect, useState } from 'react';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './AddCommentsTab.scss';
import AdminNotes from '../../AdminNotes/AdminNotes';
import { AdminCommentType } from '../../../core/enums';
import { AdminCommentViewModel } from '../../../core/backend/models';
import ReplyForm from '../../ReplyForm/ReplyForm';
import {
  addAdminNote,
  deleteAdminNoteByType,
  getAdminNotesByType
} from '../../../core/api';
import appToast from '../../../core/toast';
import { AdminNoteModel } from '../../../core/types';
import { useAppSelector } from '../../../state';

interface IProps extends IBaseTabProps {
  targetId?: string | number;
  adminCommentType?: AdminCommentType;
}

const AddNotesTab = ({ targetId, adminCommentType, ...props }: IProps) => {
  const { auth } = useAppSelector((state) => state);
  const [adminNotes, setAdminNotes] = useState<AdminCommentViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAdminNotes().then(() => setIsLoading(false));
  }, [targetId, adminCommentType]);

  const getAdminNotes = async () => {
    try {
      const notes = await getAdminNotesByType(adminCommentType, targetId);
      setAdminNotes(notes);
    } catch (e: any) {
      appToast.showError(
        'Could not get admin comments. Close side panel and try again.'
      );
    }
  };

  const handleSaveComments = async (comment: any) => {
    try {
      setIsProcessing(true);

      const note: AdminNoteModel = {
        adminCommentType,
        id: targetId,
        comment: comment.message
      };

      const newNote = await addAdminNote(note);
      setAdminNotes([...adminNotes, { ...newNote, admin: auth.user }]);

      appToast.showSuccess('Comment added.');
    } catch (e: any) {
      appToast.showError(
        'Something went wrong while adding the comment. Try again.'
      );
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteNote = (noteId: number) => async () => {
    try {
      await deleteAdminNoteByType(adminCommentType, noteId);

      const notes = adminNotes.filter((note) => note.id !== noteId);
      setAdminNotes(notes);

      appToast.showSuccess('Comment deleted.');
    } catch (e: any) {
      appToast.showError(
        'Something went wrong while deleting the comment. Try again.'
      );
    }
  };

  return (
    <BaseTab {...props} className='AddNotesTab'>
      <AdminNotes
        isLoading={isLoading}
        notes={adminNotes}
        onDeleteNote={handleDeleteNote}
        form={
          <ReplyForm
            isLoading={isProcessing}
            placeholder='Add new comment'
            onSubmit={handleSaveComments}
          />
        }
      />
    </BaseTab>
  );
};

export default AddNotesTab;
