import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './ResolveIssueModal.scss';
import { BookingResolution } from '../../../core/enums';
import { useCallback, useState } from 'react';

const ResolveIssueModal = ({ onAccept, onClose, ...props }: IModalProps) => {
  const [selectedValue, setSelectedValue] = useState('0');
  const [message, setMessage] = useState('');

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.currentTarget.value);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const handleOnAccept = useCallback(() => {
    onAccept(+selectedValue, message);
    setSelectedValue('0');
    setMessage('');
  }, [onAccept, selectedValue, message]);

  const handleOnClose = () => {
    onClose();
    setSelectedValue('0');
    setMessage('');
  };

  return (
    <BaseModal
      title='Resolve issue'
      onAccept={handleOnAccept}
      onClose={handleOnClose}
      {...props}
    >
      <div className='ResolveIssueModal__subheader'>Who is right</div>
      <RadioGroup row value={selectedValue} onChange={handleRadioChange}>
        <FormControlLabel
          value={BookingResolution.CustomerIsRight}
          control={<Radio />}
          label='Customer'
        />
        <FormControlLabel
          value={BookingResolution.ProviderIsRight}
          control={<Radio />}
          label='Experience Creator'
        />
        <FormControlLabel
          value={BookingResolution.MutualAgreement}
          control={<Radio />}
          label='Mutual'
        />
      </RadioGroup>
      <TextField
        id='reason'
        label='Summary'
        variant='standard'
        value={message}
        onChange={handleTextChange}
        fullWidth
      />
      <div className='ResolveIssueModal__message'>
        Message will be shown for Customer and Experience Creator
      </div>
    </BaseModal>
  );
};

export default ResolveIssueModal;
