import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import { BookingTicketViewModel } from '../../../core/backend/models';
import './TicketsTab.scss';
import { ItemQuantity } from '../../../core/types';
import { convertToCurrency } from '../../../core/helpers/helpers';

interface IProps extends IBaseTabProps {
  tickets?: BookingTicketViewModel[];
}

const TicketsTab = ({ tickets, ...props }: IProps) => {
  const _tickets = tickets.reduce<ItemQuantity[]>((acc, ticket) => {
    const existingIndex = acc.findIndex(
      (_ticket) => _ticket.name === ticket.ticketName
    );
    if (existingIndex > -1) acc[existingIndex].quantity += 1;
    else acc.push({ name: ticket.ticketName, quantity: 1 });
    return acc;
  }, []);

  return (
    <BaseTab {...props} className='TicketsTab'>
      {tickets && tickets.length > 0 ? (
        <>
          <div className='TicketsTab__header'>
            <div className='TicketsTab__ticketName'>Ticket</div>
            <div className='TicketsTab__ticketQuantity'>Quantity</div>
            <div className='TicketsTab__ticketQuantity'>Total</div>
          </div>
          <div className='TicketsTab__list'>
            {_tickets.map((ticket) => {
              return (
                <div className='TicketsTab__row' key={ticket.name}>
                  <div className='TicketsTab__ticketName'>{ticket.name}</div>
                  <div className='TicketsTab__ticketQuantity'>
                    {convertToCurrency(ticket.price || 0)} x {ticket.quantity}
                  </div>
                  <div className='TicketsTab__ticketQuantity'>
                    {convertToCurrency(ticket.amount || 0)}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className='TicketsTab__noContent'>No Tickets</div>
      )}
    </BaseTab>
  );
};

export default TicketsTab;
