import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getMediaFile } from '../../core/api';
import { MessageViewModel, UserViewModel } from '../../core/backend/models';
import { useAppSelector } from '../../state/hooks';
import UserImage from '../UserImage/UserImage';
import './Message.scss';

interface IProps {
  sender: UserViewModel;
  message: MessageViewModel;
  alignTo?: 'left' | 'right' | null;
  className?: string;
}

const Message = ({ sender, message, alignTo = 'left', className }: IProps) => {
  const auth = useAppSelector((state) => state.auth);
  const isPro = sender.roles?.find((role) => role.name === 'Creator Pro');
  const date = new Date(message.sendOn);
  const letter = sender.id === auth.user.id ? 'H' : sender.fullName[0];

  return (
    <div
      className={classNames(`Message ${className}`, {
        'Message--left': alignTo === 'left',
        'Message--right': alignTo === 'right'
      })}
    >
      <UserImage
        url={getMediaFile(sender.image)}
        letter={letter}
        className='Message__userImage'
      />
      <div className='Message__bubble'>
        <div className='Message__author'>
          {sender.id === auth.user.id ? 'You' : sender.fullName}
          {isPro && <Chip label='PRO' size='small' />}
        </div>
        <div className='Message__content'>{message.body}</div>
      </div>
      <div className='Message__time'>{dayjs(date).format('h:mma')}</div>
    </div>
  );
};

export default Message;
