import { forwardRef } from 'react';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import './DateRangeInput.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  className?: string;
}

const DateRangeInput = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  className = ''
}: Props) => {
  const CustomDateInput = forwardRef(
    (
      { value, onClick }: { value?: string; onClick?: React.MouseEventHandler },
      ref: any
    ) => (
      <button
        className='DateRangeInput__datePicker'
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  return (
    <div className={cn(`DateRangeInput ${className}`)}>
      <div className='DateRangeInput__controls'>
        <span>Start Date: </span>
        <DatePicker
          onChange={onStartDateChange}
          onYearChange={onStartDateChange}
          onMonthChange={onStartDateChange}
          selected={startDate}
          customInput={<CustomDateInput />}
          dropdownMode='select'
          showYearDropdown
        />
        <span>End Date:</span>
        <DatePicker
          onChange={onEndDateChange}
          onYearChange={onEndDateChange}
          onMonthChange={onEndDateChange}
          selected={endDate}
          customInput={<CustomDateInput />}
          dropdownMode='select'
          showYearDropdown
        />
      </div>
    </div>
  );
};

export default DateRangeInput;
