import {
  DiscountCodeViewModel,
  GetAllGigTicketClicksResponse,
  ServiceProviderPayoutLogViewModel,
  UserPaymentLogViewModel,
  UserRedemptionCodeViewModel,
  UserViewModel
} from '../backend/models';
import {
  IBookingReportRow,
  IBookingRow,
  IEventRow,
  IExperienceLogRow,
  INewContentRow,
  IStatsRow,
  IUserRow,
  IVideoReportRow
} from '../types';

export const searchBookingsFilter =
  (query = '') =>
  (booking: IBookingRow) =>
    booking.reservationId?.toString().toLowerCase().includes(query) ||
    booking.id.toString().includes(query) ||
    booking.gig?.name?.toLowerCase().includes(query) ||
    booking.gig?.description?.toLowerCase().includes(query) ||
    booking.customer?.fullName?.toLowerCase().includes(query) ||
    booking.gig?.provider?.fullName?.toLowerCase().includes(query) ||
    booking.customer?.userName?.toLowerCase().includes(query) ||
    booking.gig?.provider?.userName?.toLowerCase().includes(query) ||
    booking.status.toLowerCase().includes(query) ||
    booking.formattedRemainingTime.toLowerCase().includes(query) ||
    booking.formattedEventTimeDate.toLowerCase().includes(query) ||
    booking.formattedRequestTimeDate.toLowerCase().includes(query) ||
    booking.formattedEstimatedDeadlineDate.toLowerCase().includes(query);

export const searchExperienceBookingsFilter =
  (query = '') =>
  (booking: IExperienceLogRow) =>
    // booking.reservationId?.toString().toLowerCase().includes(query) ||
    booking.booking.gig?.name?.toLowerCase().includes(query) ||
    booking.booking.gig?.provider?.fullName?.toLowerCase().includes(query) ||
    booking.booking.gig?.provider?.userName?.toLowerCase().includes(query) ||
    booking.formattedTransactionDate.toLowerCase().includes(query) ||
    booking.formattedTransactionTime.toLowerCase().includes(query);

export const searchVideoReportsFilter =
  (query = '') =>
  (report: IVideoReportRow) => {
    return (
      report?.mediaFile?.linkedGig?.id?.toString().includes(query) ||
      report?.mediaFile?.linkedGig?.name?.toLowerCase().includes(query) ||
      report?.mediaFile?.linkedGig?.description
        ?.toLowerCase()
        .includes(query) ||
      report?.mediaFile?.description?.toLowerCase().includes(query) ||
      report?.status?.toLowerCase().includes(query) ||
      report?.mediaFile?.owner?.fullName.toLowerCase().includes(query) ||
      report?.mediaFile?.owner?.fullName.toLowerCase().includes(query) ||
      report?.reportingUser?.fullName.toLowerCase().includes(query) ||
      report?.reportingUser?.fullName.toLowerCase().includes(query)
    );
  };

export const searchVideosFilter =
  (query = '') =>
  (video: INewContentRow) => {
    return (
      video?.id?.toString().includes(query) ||
      video?.description?.toLowerCase().includes(query) ||
      video?.linkedGig?.name?.toLowerCase().includes(query) ||
      video?.status?.toLowerCase().includes(query) ||
      video?.owner?.fullName.toLowerCase().includes(query) ||
      video?.owner?.userName.toLowerCase().includes(query) ||
      video?.formattedActionDate.toLowerCase().includes(query) ||
      video?.type?.toLowerCase().includes(query)
    );
  };

export const searchBookingReportsFilter =
  (query = '') =>
  (report: IBookingReportRow) => {
    return (
      report?.gig?.name?.toLowerCase().includes(query) ||
      report?.gig?.description?.toLowerCase().includes(query) ||
      report?.status?.toLowerCase().includes(query) ||
      report?.gig?.provider?.fullName.toLowerCase().includes(query) ||
      report?.gig?.provider?.userName.toLowerCase().includes(query) ||
      report?.customer?.userName.toLowerCase().includes(query) ||
      report?.customer?.fullName.toLowerCase().includes(query)
    );
  };

export const searchEventsFilter =
  (query = '') =>
  (report: IEventRow) => {
    return (
      report?.name?.toLowerCase().includes(query) ||
      report?.description?.toLowerCase().includes(query) ||
      report?.state?.toLowerCase().includes(query) ||
      report?.provider.userName?.toLowerCase().includes(query)
    );
  };

export const searchExternalEventsFilter =
  (query = '') =>
  (event: GetAllGigTicketClicksResponse) =>
    event.gigId.toString().includes(query) ||
    event.gigName.toLowerCase().includes(query) ||
    event.ticketExternalLink.toLowerCase().includes(query) ||
    event.totalClicks.toString().includes(query) ||
    event.totalCost.toString().includes(query);

export const searchNewContentFilter =
  (query = '') =>
  (report: INewContentRow) => {
    return (
      report?.description?.toLowerCase().includes(query) ||
      report?.owner?.userName.toLowerCase().includes(query) ||
      report?.owner?.fullName.toLowerCase().includes(query) ||
      report?.status?.toLowerCase().includes(query)
    );
  };

export const searchUsersFilter =
  (query = '') =>
  (user: IUserRow) =>
    user.id.toString().includes(query) ||
    user.fullName?.toLowerCase().includes(query) ||
    user.userName?.toLowerCase().includes(query) ||
    user.email?.toLowerCase().includes(query) ||
    user.userType?.toLowerCase().includes(query) ||
    user.accountType?.toLowerCase().includes(query);

export const searchPayoutsFilter =
  (query = '') =>
  (payout: ServiceProviderPayoutLogViewModel) =>
    payout.reservationId.includes(query) ||
    payout.amount.toString().toLowerCase().includes(query) ||
    payout.provider.fullName?.toLowerCase().includes(query) ||
    payout.provider.userName?.toLowerCase().includes(query);

export const searchPaymentsFilter =
  (query = '') =>
  (payout: UserPaymentLogViewModel) =>
    payout.reservationId?.includes(query) ||
    payout.amount.toString().toLowerCase().includes(query) ||
    payout.user?.fullName?.toLowerCase().includes(query) ||
    payout.user?.userName?.toLowerCase().includes(query);

export const searchStatsFilter =
  (query = '') =>
  (stats: IStatsRow) => {
    return (
      stats?.sumCreatorTips?.toString().includes(query) ||
      stats?.sumHooplaFee?.toString().includes(query) ||
      stats?.serviceFee?.toString().includes(query) ||
      stats?.sumSubTotal?.toString().includes(query) ||
      stats?.sumTotalEarnings?.toString().includes(query) ||
      stats?.user?.id?.toLowerCase().includes(query) ||
      stats?.user?.userName?.toLowerCase().includes(query) ||
      stats?.user?.email?.toLowerCase().includes(query) ||
      stats?.user?.businessEmail?.toLowerCase().includes(query) ||
      stats?.user?.company?.toLowerCase().includes(query) ||
      stats?.user?.fullName?.toLowerCase().includes(query)
    );
  };

export const searchDiscountsFilter =
  (query = '') =>
  (discount: DiscountCodeViewModel) =>
    discount.stripeDiscountCodeId.toLowerCase().includes(query) ||
    discount.stripeCouponId.toLowerCase().includes(query) ||
    discount.code.toLowerCase().includes(query);

export const searchCouponReportFilter =
  (query = '') =>
  (report: UserRedemptionCodeViewModel) =>
    report.code.toLowerCase().includes(query) ||
    report.coupon.toLowerCase().includes(query) ||
    report.bearerName.toLowerCase().includes(query) ||
    report.customer.fullName.toLowerCase().includes(query) ||
    report.customer.userName.toLowerCase().includes(query) ||
    report.customer.email.toLowerCase().includes(query) ||
    report.amount.toString().includes(query) ||
    report.booking.reservationId.toLowerCase().includes(query);
