import { useEffect, useRef } from 'react';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './NewCategoryModal.scss';

const Input = styled('input')({
  display: 'none'
});

interface FormValues {
  image: File;
  name: string;
}

const NewCategoryModal = ({ onAccept, onClose, ...props }: IModalProps) => {
  const prevImageRef = useRef<HTMLImageElement>(null);
  const { errors, values, handleChange, submitForm, setFieldValue, resetForm } =
    useFormik<FormValues>({
      initialValues: { image: null, name: '' },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.image) {
          errors.image = 'Please select an image.';
        }

        if (!values.name.trim().length) {
          errors.name = 'Please enter a valid name.';
        }

        return errors;
      },
      onSubmit: async (values: FormikValues, { resetForm }) => {
        await onAccept(values);
        resetForm();
      }
    });

  useEffect(() => {
    if (!props.isOpen && prevImageRef.current) {
      prevImageRef.current = null;
    }
  }, [props.isOpen, prevImageRef.current]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();

      setFieldValue('image', e.currentTarget.files[0]);
      reader.onload = () => {
        prevImageRef.current.src = reader.result as string;
      };

      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Create Category'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='NewCategoryModal'>
        <div className='NewCategoryModal__icon'>
          {values.image && (
            <img className='NewCategoryModal__iconImage' ref={prevImageRef} />
          )}
          <label htmlFor='contained-button-file'>
            <Input
              accept='image/*'
              id='contained-button-file'
              multiple
              type='file'
              onChange={handleImageChange}
            />
            <Button
              variant='outlined'
              component='span'
              color='secondary'
              startIcon={<PhotoCamera />}
            >
              Select Icon
            </Button>
          </label>
          {errors.image && (
            <div className='NewCategoryModal__imageError'>{errors.image}</div>
          )}
        </div>

        <TextField
          id='name'
          name='name'
          label='Enter a name for this category'
          variant='standard'
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name ?? ''}
          fullWidth
        />
      </div>
    </BaseModal>
  );
};

export default NewCategoryModal;
