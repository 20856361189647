import { getMediaFile } from '../../../core/api';
import { IBookingRow } from '../../../core/types';
import Status from '../../Status/Status';
import UserImage from '../../UserImage/UserImage';
import Video from '../../Video/Video';
import './BookingCard.scss';

interface Props {
  booking: IBookingRow;
  className?: string;
}
const BookingCard = ({ booking, className = '' }: Props) => {
  return (
    <div className={`BookingCard ${className}`}>
      <Video
        className='BookingCard__video'
        size='small'
        url={getMediaFile(booking.gig?.gigVideo?.awsUrl)}
        coverUrl={getMediaFile(booking.gig?.gigVideo?.thumbnails.web[0])}
      />
      <div className='BookingCard__details'>
        <div className='BookingCard__info'>
          <b>{booking.gig?.name}</b>
        </div>
        <div className='BookingCard__info'>
          Requested: {booking.formattedRequestTime}
        </div>
        <div className='BookingCard__info'>
          {booking.formattedElapsedTime} &middot;
          <Status
            sx={{ height: '18px', marginLeft: '5px' }}
            status={booking.status}
          />
        </div>
        <div className='BookingCard__info'>
          <UserImage
            className='BookingCard__image'
            size='sm'
            url={getMediaFile(booking.customer.image)}
          />
          <div className='BookingCard__userNameWrapper'>
            {booking.customer.fullName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
