import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';
import { getMediaFile } from '../../../core/api';
import './EventBookingRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import { IBookingRow } from '../../../core/types';
import Video from '../../Video/Video';
import { getExperienceThumbnails } from '../../../core/helpers/model-helpers';
import Chip from '@mui/material/Chip';

dayjs.extend(relativeTime);

interface IProps extends IBaseRowProps {
  booking: IBookingRow;
  isActive: boolean;
  onPreviewEvent: React.MouseEventHandler;
  onPreviewProvider: React.MouseEventHandler;
  onPreviewCustomer: React.MouseEventHandler;
  onAddComment: React.MouseEventHandler;
}

const EventBookingRow = ({
  booking,
  isActive,
  onAddComment,
  onPreviewEvent,
  onPreviewCustomer,
  onPreviewProvider
}: IProps) => {
  const { staticImageUrl, videoUrl } = getExperienceThumbnails(
    booking.gig.gigMainMediaFiles ?? []
  );

  return (
    <BaseRow selected={isActive}>
      <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
        <div className='EventBookingRow__videoRow' onClick={onPreviewEvent}>
          <Video
            className='EventBookingRow__video'
            coverUrl={staticImageUrl}
            url={videoUrl}
          />
          <p>
            <b>{booking.gig.name}</b>
          </p>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '60px', maxWidth: '70px' }}
      >
        <p className='EventBookingRow__cellRow'>
          <b>{booking.reservationId}</b>
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '100px' }}
      >
        {booking.tickets.length}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        <p className='EventBookingRow__cellRow'>
          {booking.formattedRequestTimeDate}
        </p>
        <p className='EventBookingRow__cellRow'>
          {booking.formattedRequestTime}
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '110px' }}
      >
        <p className='EventBookingRow__cellRow'>
          {booking.formattedEventTimeDate}
        </p>
        <p className='EventBookingRow__cellRow'>{booking.formattedEventTime}</p>
        {booking.gig.isRecurrentEvent ? (
          <Status status='recurrent' size='small' />
        ) : null}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ width: '80px' }}
      >
        <Status
          status={booking.status}
          sx={{ height: '18px', marginLeft: '5px' }}
        />
        <div>{booking.statusDescriptor}</div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '140px' }}
      >
        <div className='EventBookingRow__userInfo' onClick={onPreviewProvider}>
          <UserImage
            className='EventBookingRow__image'
            url={getMediaFile(booking.gig.provider.image)}
          />
          <div className='EventBookingRow__userFullName'>
            {booking.gig.provider.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '140px' }}
      >
        <div className='EventBookingRow__userInfo' onClick={onPreviewCustomer}>
          <UserImage
            className='EventBookingRow__image'
            url={getMediaFile(booking.customer.image)}
          />
          <div className='EventBookingRow__userFullName'>
            <p className='EventBookingRow__cellRow'>
              {booking.customer.fullName}
            </p>
            {booking.expressCheckout ? (
              <Chip
                label='Guest'
                size='small'
                sx={{
                  backgroundColor: '#b83af3',
                  color: '#FFFFFF'
                }}
              />
            ) : null}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', maxWidth: '120px' }}
      >
        <IconButton onClick={onAddComment} title='Add Comments'>
          <MessageIcon />
        </IconButton>
      </TableCell>
    </BaseRow>
  );
};

export default EventBookingRow;
