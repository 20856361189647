import React from 'react';
import Header from '../../components/Header/Header';
import './MainLayout.scss';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const MainLayout = ({ children }: IProps) => {
  return (
    <div className='MainLayout'>
      <Header />
      <div className='MainLayout__wrapper'>{children}</div>
    </div>
  );
};

export default MainLayout;
