import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ITableHeaderProps } from '../../../core/types';

const PayoutTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='normal'>Reservation</TableCell>
        <TableCell align='center' padding='normal'>
          Amount
        </TableCell>
        <TableCell padding='normal'>Transaction Description</TableCell>
        <TableCell padding='normal'>Service Provider</TableCell>
        <TableCell align='center' padding='normal'>
          Date of Transaction
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default PayoutTableHeader;
