import { IModalProps } from '../../../core/types';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/consts';
import BaseModal from '../BaseModal/BaseModal';
import './ErrorModal.scss';

interface IProps extends IModalProps {
  message?: string;
}

const ErrorModal = ({ message, ...props }: IProps) => {
  return (
    <BaseModal title='Error' {...props}>
      <div className='ErrorModal__description'>
        {message ?? DEFAULT_ERROR_MESSAGE}
      </div>
    </BaseModal>
  );
};

export default ErrorModal;
