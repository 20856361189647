import * as yup from 'yup';
import dayjs from 'dayjs';

export default yup.object({
  fullName: yup
    .string()
    .nullable()
    .min(3, 'Must be 3 characters at least')
    .required('Required'),
  userName: yup
    .string()
    .nullable()
    .min(3, 'Must be 3 characters at least')
    .required('Required'),
  email: yup
    .string()
    .nullable()
    .email('Type a valid email')
    .required('Required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'User must be 16 years old at least',
      function (value: any) {
        if (!value) return true;
        return dayjs().diff(value, 'year') >= 16;
      }
    ),
  isCreatorPro: yup.boolean().nullable(),
  address1: yup
    .string()
    .nullable()
    .when('isCreatorPro', {
      is: (isCreatorPro: boolean) => isCreatorPro === true,
      then: yup.string().nullable().required('Type Address 1')
    }),
  city: yup
    .string()
    .nullable()
    .when('isCreatorPro', {
      is: true,
      then: yup.string().nullable().required('Type  user city')
    }),
  state: yup
    .string()
    .nullable()
    .when('isCreatorPro', {
      is: true,
      then: yup.string().nullable().required('Type user state')
    }),
  zip: yup
    .string()
    .nullable()
    .when('isCreatorPro', {
      is: true,
      then: yup.string().nullable().required('Type zip code')
    })
});
