import { FilterOptionType } from '../types';

export const bookingFilterOptions: FilterOptionType[] = [
  { group: 'Status', label: 'Overdue', value: 'overdue' },
  { group: 'Status', label: 'Confirmed', value: 'confirmed' },
  { group: 'Status', label: 'In Progress', value: 'in progress' },
  { group: 'Status', label: 'Pending Confirmation', value: 'pending' },
  { group: 'Date', label: 'Upcoming Events', value: 'upcoming' }
];

export const eventFilterOptions: FilterOptionType[] = [
  { group: 'Type', label: 'Recurrent', value: 'recurrent' },
  { group: 'Type', label: 'Single', value: 'single' },
  { group: 'Type', label: 'Private', value: 'private' },
  { group: 'Type', label: 'Public', value: 'public' }
];

export const bookingReportsFilterOptions: FilterOptionType[] = [
  { group: 'Status', label: 'New', value: 'new' },
  { group: 'Status', label: 'Completed', value: 'completed' }
];

export const videoFilterOptions: FilterOptionType[] = [
  { group: 'Status', label: 'New', value: 'new' },
  { group: 'Status', label: 'Approved', value: 'approved' },
  { group: 'Status', label: 'Under Review', value: 'review' },
  { group: 'Status', label: 'Rejected', value: 'rejected' },
  { group: 'Type', label: 'Clip', value: 'Clip' },
  { group: 'Type', label: 'Experience', value: 'Experience' }
];

export const videoReportsFilterOptions: FilterOptionType[] = [
  { group: 'Status', label: 'New', value: 'new' },
  { group: 'Status', label: 'Approved', value: 'approved' },
  { group: 'Status', label: 'Declined', value: 'declined' }
];

export const userFilterOptions: FilterOptionType[] = [
  { group: 'User Type', label: 'User', value: 'user' },
  { group: 'User Type', label: 'Creator', value: 'creator' },
  { group: 'Account Type', label: 'Normal', value: 'normal' },
  { group: 'Account Type', label: 'Admin', value: 'admin' },
  { group: 'Account Status', label: 'Active', value: 'active' },
  { group: 'Account Status', label: 'Inactive', value: 'inactive' }
];

export const providerPayoutFilterOptions: FilterOptionType[] = [
  { group: 'Transaction', label: 'Booking Deposit', value: '1' },
  { group: 'Transaction', label: 'Final Payment', value: '2' },
  { group: 'Transaction', label: 'Full Payment', value: '3' },
  { group: 'Transaction', label: 'Tip', value: '4' }
];

export const userPaymentFilterOptions: FilterOptionType[] = [
  { group: 'Payment Type', label: 'Commercial', value: '1' },
  { group: 'Payment Type', label: 'Charity', value: '2' },
  { group: 'Payment Type', label: 'Tip', value: '3' }
];

export const dicountBearerFilterOptions: FilterOptionType[] = [
  { group: 'Bearer', label: 'Hoopla', value: '0' },
  { group: 'Bearer', label: 'Creator', value: '1' },
  { group: 'Status', label: 'Active', value: 'true' },
  { group: 'Status', label: 'Inactive', value: 'false' }
];

export const couponRedemptionFilter: FilterOptionType[] = [
  { group: 'Bearer', label: 'Hoopla', value: '0' },
  { group: 'Bearer', label: 'Creator', value: '1' }
];
