import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  authSlice,
  bookingsSlice,
  scheduleSlice,
  usersSlice,
  categoriesSlice,
  hashtagsSlice,
  experienceLogsSlice,
  bookingReportsSlice,
  videoReportsSlice,
  videosSlice,
  statisticsSlice,
  eventsSlice
} from './slices';

const reducers = combineReducers({
  schedule: scheduleSlice.reducer,
  auth: authSlice.reducer,
  bookings: bookingsSlice.reducer,
  users: usersSlice.reducer,
  categories: categoriesSlice.reducer,
  hashtags: hashtagsSlice.reducer,
  bookingLogs: experienceLogsSlice.reducer,
  bookingReports: bookingReportsSlice.reducer,
  videoReports: videoReportsSlice.reducer,
  videos: videosSlice.reducer,
  statistics: statisticsSlice.reducer,
  events: eventsSlice.reducer
});

export const store = configureStore({ reducer: reducers });
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
