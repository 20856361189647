import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import UserImage from '../UserImage/UserImage';
import './MessagesRow.scss';
import { UserViewModel } from '../../core/backend/models';
import { getMediaFile } from '../../core/api';

interface IProps {
  user: UserViewModel;
  onPress: React.MouseEventHandler;
}

const MessagesRow = ({ user, onPress }: IProps) => {
  const isPro = user.roles.find((role) => role.name === 'Creator Pro');
  return (
    <div className='MessagesRow'>
      <Button
        onClick={onPress}
        classes={{
          root: 'MessagesRow__button',
          text: 'MessagesRow__buttonText'
        }}
      >
        <UserImage url={getMediaFile(user.image)} letter={user.fullName[0]} />
        <div className='MessagesRow__name'>
          {user.fullName} {isPro && <Chip label='PRO' size='small' />}
        </div>
      </Button>
    </div>
  );
};

export default MessagesRow;
