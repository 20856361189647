import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { FormikValues, useFormik } from 'formik';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './ViewDiscountModal.scss';
import { convertToCurrency } from '../../../core/helpers/helpers';
import { DiscountCodeViewModel } from '../../../core/backend/models';
import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { useAppSelector } from '../../../state';

interface IProps extends IModalProps {
  discount: DiscountCodeViewModel;
}

const ViewDiscountModal = ({
  discount,
  onAccept,
  onClose,
  ...props
}: IProps) => {
  const users = useAppSelector((state) => state.users);
  const { values, handleChange, resetForm, submitForm } =
    useFormik<DiscountCodeViewModel>({
      initialValues: discount,
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        const ok = await onAccept(values);
        if (ok) {
          resetForm();
        }
      }
    });

  const handleAccept = async () => {
    submitForm();
  };

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Discount Code'
      size='md'
      onAccept={handleAccept}
      acceptButtonTitle='Save'
      onClose={handleOnClose}
      {...props}
    >
      <Grid container direction={'column'} rowSpacing={2}>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='code'
              label='Code'
              variant='standard'
              value={values?.code}
              InputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='type'
              label={values?.amountOff ? 'Price' : 'Percentage'}
              variant='standard'
              value={
                values?.amountOff
                  ? convertToCurrency(values?.amountOff)
                  : values?.percentOff + '%'
              }
              InputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='bearer'
              label='Bearer'
              variant='standard'
              value={values?.costBearerType === 0 ? 'Hoopla' : 'Creator'}
              InputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='expires'
              label='Expires'
              variant='standard'
              value={dayjs(
                discount?.expiresAt ? discount?.expiresAt * 1000 : null
              ).format('MM/DD/YY')}
              InputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='maxRedem'
              label='Max Redemptions'
              variant='standard'
              value={values?.maxRedemptions ?? 'Unlimited'}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name='status'
              label='Status'
              variant='standard'
              value={values?.active ? 'Active' : 'Inactive'}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item>
          <Typography sx={{ mb: 1 }} component='div'>
            Restricted to Creators
          </Typography>
          <Stack direction='row' spacing={1}>
            {values?.assignedToProviders?.length > 0
              ? values?.assignedToProviders.map((provider) => (
                  <Chip
                    key={provider.id}
                    label={provider.userName}
                    variant='outlined'
                  />
                ))
              : 'None'}
          </Stack>
        </Grid>

        <Grid item>
          <Typography sx={{ mb: 1 }} component='div'>
            Restricted to Experiences
          </Typography>
          <List dense={true}>
            {values?.toBeUsedByExperiences?.length > 0
              ? values?.toBeUsedByExperiences.map((experience) => (
                  <Chip
                    key={experience.id}
                    label={experience.name}
                    variant='outlined'
                  />
                ))
              : 'None'}
          </List>
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            multiline
            name='comments'
            label='Notes'
            variant='standard'
            value={values?.comments ?? ''}
            onChange={handleChange}
            minRows={5}
            maxRows={10}
          />
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default ViewDiscountModal;
