import TableCell from '@mui/material/TableCell';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { GetAllGigTicketClicksResponse } from '../../../core/backend/models';
import './ExternalEventRow.scss';
import { convertToCurrency } from '../../../core/helpers/helpers';

interface IProps extends IBaseRowProps {
  event: GetAllGigTicketClicksResponse;
}

const ExternalEventRow = ({ event, selected, onClick }: IProps) => {
  // const date = dayjs(payout.dateOfTransaction);
  // const formattedDate = date.format('MM/DD/YY hh:mm a');

  return (
    <BaseRow selected={selected}>
      <TableCell sx={{ maxWidth: '250px' }}>
        <div className='ExternalEventRow__eventName' onClick={onClick}>
          {event.gigName}
        </div>
      </TableCell>
      <TableCell sx={{ maxWidth: '150px' }}>
        {event.ticketExternalLink}
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '100px' }}>
        {event.totalClicks}
      </TableCell>
      <TableCell align='right' sx={{ maxWidth: '100px' }}>
        {convertToCurrency(event.totalCost)}
      </TableCell>
    </BaseRow>
  );
};

export default ExternalEventRow;
