import TableCell from '@mui/material/TableCell';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import './InvitationCodeRow.scss';
import { GigInvitationCodeViewModel } from '../../../core/backend/models';
import { format } from 'date-fns';
import CopyBtn from '../../CopyBtn/CopyBtn';

interface IProps extends IBaseRowProps {
  code: GigInvitationCodeViewModel;
}

const InvitationCodeRow = ({ code }: IProps) => {
  if (code?.expirationDateEpoch === undefined) return null;
  return (
    <BaseRow>
      <TableCell align='left' sx={{ maxWidth: '130px' }}>
        <p className='InvitationCode__cellRow'>{code.code}</p>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <div className='InvitationCode__capital'>{code.userName}</div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <div className='InvitationCode__capital'>{code.bookingsLimit}</div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <div className='InvitationCode__capital'>
          {format(new Date(code.expirationDateEpoch), 'MM/dd/yyyy')}
        </div>
      </TableCell>
      <TableCell align='center' sx={{ maxWidth: '40px' }}>
        <CopyBtn
          textToCopy={code.shareUrl}
          successMsg='Invitation Url copied to clipboard!'
        />
      </TableCell>
    </BaseRow>
  );
};

export default InvitationCodeRow;
