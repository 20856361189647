import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ITableHeaderProps } from '../../../core/types';

const BannerTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount
}: ITableHeaderProps<null>) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell align='left' padding='none'>
          Banner
        </TableCell>
        <TableCell align='left' padding='normal'>
          Link
        </TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BannerTableHeader;
