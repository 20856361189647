import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Coupons.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import { RoutesEnum } from '../../core/enums';
import Categories from '../Categories/Categories';
import CouponList from '../CouponsList/CouponsList';
import CouponsReport from '../CouponsReport/CouponsReport';

const Cms = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.CouponList}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Coupons
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.CouponReport}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Report
          </Button>
        </ButtonGroup>
      </Box>
      <Switch>
        <Route exact path={RoutesEnum.CouponList} component={CouponList} />
        <Route exact path={RoutesEnum.CouponReport} component={CouponsReport} />
      </Switch>
    </MainLayout>
  );
};

export default Cms;
