import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './SearchResult.scss';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../state';
import {
  searchBookingReportsFilter,
  searchBookingsFilter,
  searchUsersFilter,
  searchVideoReportsFilter
} from '../../core/helpers/search-helpers';
import BookingCard from '../Cards/BookingCard/BookingCard';
import { IBookingRow, ReportSearchResult } from '../../core/types';
import { Link } from 'react-router-dom';
import ReportCard from '../Cards/ReportCard/ReportCard';
import { UserViewModel } from '../../core/backend/models';
import UserCard from '../Cards/UserCard/UserCard';

interface Props {
  query: string;
  className?: string;
  onViewResultsClick: () => void;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  margin: '7px 0',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  borderRadius: '0',
  alignSelf: 'stretch'
}));

const SearchResult = ({ query, onViewResultsClick, className = '' }: Props) => {
  const { bookings, videos, videoReports, bookingReports, users } =
    useAppSelector((state) => state);
  const [bookingResults, setBookingResults] = useState<IBookingRow[]>([]);
  const [reportResults, setReportResults] = useState<ReportSearchResult[]>([]);
  const [userResults, setUserResults] = useState<UserViewModel[]>([]);

  useEffect(() => {
    const lowerCaseQuery = query.toLowerCase();

    const filteredBookings = bookings
      .filter(searchBookingsFilter(lowerCaseQuery))
      .slice(0, 3);
    setBookingResults(filteredBookings);

    const filteredVideoReports: ReportSearchResult[] = videoReports
      .filter(searchVideoReportsFilter(lowerCaseQuery))
      .slice(0, 3)
      .map((video) => ({
        id: video.id,
        videoUrl: video.mediaFile.awsUrl,
        thumbnailUrl: video.mediaFile.thumbnails.web[0],
        description: video.mediaFile.description,
        status: video.status,
        type: 'Video Report',
        ownerImage: video.mediaFile.owner.image,
        ownerName: video.mediaFile.owner.fullName
      }));

    const filteredBookingReports = bookingReports
      .filter(searchBookingReportsFilter(lowerCaseQuery))
      .slice(0, 3)
      .map((booking) => ({
        id: booking.id,
        videoUrl: booking.gig.gigVideo.awsUrl,
        thumbnailUrl: booking.gig.gigVideo.thumbnails.web[0],
        description: booking.gig.name,
        status: booking.status,
        type: 'Booking Report',
        ownerImage: booking.gig.provider.image,
        ownerName: booking.gig.provider.fullName
      }));

    const reportSearchResult = []
      .concat(filteredBookingReports, filteredVideoReports)
      .slice(0, 3);
    setReportResults(reportSearchResult);

    const filteredUsers = users
      .filter(searchUsersFilter(lowerCaseQuery))
      .slice(0, 5);
    setUserResults(filteredUsers);
  }, [query]);

  return (
    <div className={`SearchResult ${className}`}>
      <div className='SearchResult__content'>
        <h3>Search results</h3>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={3}
            sx={{ display: 'flex', alignItems: 'stretch' }}
          >
            <Grid item xs={5}>
              <div className='SearchResult__sectionHeader'>
                <h5>Bookings</h5>
                {bookingResults.length > 0 && (
                  <Link
                    to={`/bookings?query=${query}`}
                    onClick={onViewResultsClick}
                  >
                    View all booking results &rsaquo;
                  </Link>
                )}
              </div>
              <Item>
                {bookingResults.length > 0
                  ? bookingResults.map((booking) => (
                      <BookingCard
                        key={booking.id}
                        booking={booking}
                        className='SearchResult--spacing'
                      />
                    ))
                  : 'No results.'}
              </Item>
            </Grid>
            <Grid item xs={4}>
              <div className='SearchResult__sectionHeader'>
                <h5>Moderation</h5>
                {reportResults.length > 0 && (
                  <Link
                    to={`/moderate/video-reports?query=${query}`}
                    onClick={onViewResultsClick}
                  >
                    View all report results &rsaquo;
                  </Link>
                )}
              </div>
              <Item>
                {reportResults.length > 0
                  ? reportResults.map((report) => (
                      <ReportCard
                        key={report.id}
                        {...report}
                        className='SearchResult--spacing'
                      />
                    ))
                  : 'No results.'}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <div className='SearchResult__sectionHeader'>
                <h5>Users</h5>
                {userResults.length > 0 && (
                  <Link
                    to={`/users?query=${query}`}
                    onClick={onViewResultsClick}
                  >
                    View all user results &rsaquo;
                  </Link>
                )}
              </div>

              <Item>
                {userResults.length > 0
                  ? userResults.map((user) => (
                      <UserCard
                        key={user.id}
                        user={user}
                        className='SearchResult--spacing'
                      />
                    ))
                  : 'No results.'}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default SearchResult;
