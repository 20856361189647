import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../components/Loader/Loader';
import {
  createDiscountCode,
  getDiscountCodes,
  toggleDiscountCode,
  updateDiscountCode
} from '../../core/api';
import {
  CreatePromotionCodeInput,
  DiscountCodeViewModel
} from '../../core/backend/models';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import { CouponData, FilterOptionType } from '../../core/types';
import './CouponsList.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchDiscountsFilter } from '../../core/helpers/search-helpers';
import Filter from '../../components/Filter/Filter';
import { Button } from '@mui/material';
import DiscountsTableHeader from '../../components/TableHeaders/DiscountsTableHeader/DiscountsTableHeader';
import DiscountsRow from '../../components/TableRows/DiscountsRow/DiscountsRow';
import NewDiscountModal from '../../components/Modals/NewDiscountModal/NewDiscountModal';
import appToast from '../../core/toast';
import { dicountBearerFilterOptions } from '../../core/helpers/filter-helpers';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import ViewDiscountModal from '../../components/Modals/ViewDiscountModal/ViewDiscountModal';

interface IViewDicountModal {
  isOpen: boolean;
  discount: DiscountCodeViewModel;
}

const CouponList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [discounts, setDiscounts] = useState<DiscountCodeViewModel[]>([]);
  const [currentDiscounts, setCurrentDiscounts] = useState<
    DiscountCodeViewModel[]
  >([]);
  const [isNewDiscountModalOpen, setIsNewDiscountModalOpen] = useState(false);
  const [viewDiscount, setViewDiscount] = useState<IViewDicountModal>({
    isOpen: false,
    discount: null
  });

  useEffect(() => {
    getDiscounts();
  }, []);

  const getDiscounts = async () => {
    const _discounts = await getDiscountCodes();

    setDiscounts(_discounts);
    setCurrentDiscounts(_discounts);
    setIsLoading(false);
  };

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setTablePage(newPage);
  };

  const idFromItem = (item: DiscountCodeViewModel) => {
    return item.stripeDiscountCodeId;
  };

  /**
   * Actions
   */

  const updateLocalDiscountCode = (newDiscountCode: DiscountCodeViewModel) => {
    const newDiscountCodes = discounts.map((discount) =>
      discount.id === newDiscountCode.id ? newDiscountCode : discount
    );

    setDiscounts(newDiscountCodes);
    setCurrentDiscounts(newDiscountCodes);
  };

  const filterDiscountsWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const bearerFilters: string[] = [];
      const activeFilters: string[] = [];

      selectedFilters.forEach((filter) => {
        if (filter.group === 'Bearer') bearerFilters.push(filter.value);
        else activeFilters.push(filter.value);
      });

      const checkFilterTypes = (discount: DiscountCodeViewModel) =>
        bearerFilters.length && activeFilters.length
          ? bearerFilters.includes(discount.costBearerType.toString()) &&
            activeFilters.includes(discount.active.toString())
          : bearerFilters.includes(discount.costBearerType.toString()) ||
            activeFilters.includes(discount.active.toString());

      const filteredDiscounts = discounts.filter(checkFilterTypes);
      setCurrentDiscounts(filteredDiscounts);
    }
  };

  const handleSearchContent = (query: string) => {
    if (!query.length) {
      setTablePage(0);
      setCurrentDiscounts(discounts);
      return;
    }

    const lowerCasedQuery = query.toLowerCase();
    const _result = discounts.filter(searchDiscountsFilter(lowerCasedQuery));

    setTablePage(0);
    setCurrentDiscounts(_result);
  };

  const handleCreateDiscountCode = async (
    couponData: CouponData
  ): Promise<boolean> => {
    try {
      setIsProcessing(true);

      const discountInput: CreatePromotionCodeInput = Object.assign(
        couponData,
        {
          maxRedemptions: +couponData.maxRedemptions,
          maxRedemptionsByCustomer: +couponData.maxRedemptionsByCustomer,
          costBearer: +couponData.costBearer
        }
      );

      if (couponData.type === 'fixed')
        discountInput.amountOff = +couponData.value;
      else discountInput.percentageOff = +couponData.value;

      const newDiscountCode = await createDiscountCode(discountInput);

      setDiscounts([newDiscountCode, ...discounts]);
      setCurrentDiscounts([newDiscountCode, ...discounts]);
      closeNewDiscountModal();

      appToast.showSuccess('Coupon has been created');

      return true;
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ??
          e.Message ??
          'Something went wrong while creating the code. Try again.'
      );
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateDiscountCode = async ({
    comments
  }: DiscountCodeViewModel): Promise<boolean> => {
    try {
      setIsProcessing(true);

      const discountCode = await updateDiscountCode(
        viewDiscount.discount.id,
        encodeURIComponent(comments)
      );

      updateLocalDiscountCode(discountCode);
      closeViewDiscountModal();
      appToast.showSuccess('Coupon has been updated');

      return true;
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ??
          e.Message ??
          'Something went wrong while updating the code. Try again.'
      );
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleToggleActive =
    (promotionCode: number) => async (e: React.SyntheticEvent) => {
      e.stopPropagation();
      try {
        const newDiscounts = discounts.map((discount) => {
          if (discount.id === promotionCode) {
            return { ...discount, active: !discount.active };
          }

          return discount;
        });

        setDiscounts(newDiscounts);
        setCurrentDiscounts(newDiscounts);

        await toggleDiscountCode(promotionCode);
      } catch (e: any) {
        appToast.showError(
          e.response?.data?.description ??
            e.Message ??
            'Something went wrong while updating the code. Try again.'
        );
      } finally {
        setIsProcessing(false);
      }
    };

  /**
   * Side Panel
   */

  /**
   * Modal Methods
   */

  const showNewDiscountModal = () => {
    setIsNewDiscountModalOpen(true);
  };

  const closeNewDiscountModal = () => {
    setIsNewDiscountModalOpen(false);
  };

  const showDiscountModal =
    (discount: DiscountCodeViewModel) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setViewDiscount({ isOpen: true, discount });
    };

  const closeViewDiscountModal = () => {
    setViewDiscount({ isOpen: false, discount: null });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='CouponsList'>
        <div className='CouponsList__actionButtons'>
          <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={showNewDiscountModal}
          >
            New Discount Code
          </Button>
        </div>
        <div className='CouponsList__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSearchContent}
            className='CouponsList__search'
          />
          <Filter
            options={dicountBearerFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentDiscounts(discounts);
                return;
              }

              filterDiscountsWith(selectedFilters);
            }}
          />
        </div>

        <div className='CouponsList__content'>
          <BasicTable
            Header={DiscountsTableHeader}
            data={currentDiscounts}
            idFromItem={idFromItem}
            page={tablePage}
            rowsPerPage={20}
            onPageChange={handleChangePage}
            renderItem={(item, idx) => (
              <DiscountsRow
                key={`CouponsList-row-${idx}`}
                onClick={showDiscountModal(item)}
                discount={item}
                onToggleActive={handleToggleActive(item.id)}
              />
            )}
          />
        </div>

        <NewDiscountModal
          isOpen={isNewDiscountModalOpen}
          isProcessing={isProcessing}
          onClose={closeNewDiscountModal}
          onAccept={handleCreateDiscountCode}
        />

        <ViewDiscountModal
          discount={viewDiscount.discount}
          isOpen={viewDiscount.isOpen}
          isProcessing={isProcessing}
          onClose={closeViewDiscountModal}
          onAccept={handleUpdateDiscountCode}
        />
      </div>
    </>
  );
};

export default CouponList;
