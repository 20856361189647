import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserRow } from '../../core/types';

const initialState: IUserRow[] = [];

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addAllUsers: (state, action: PayloadAction<IUserRow[]>) => action.payload,
    addLocalUser: (state, action: PayloadAction<IUserRow>) => {
      return [action.payload, ...state];
    },
    appendUsers: (state, action: PayloadAction<IUserRow[]>) => [
      ...state,
      ...action.payload
    ],
    updateLocalUser: (state, action: PayloadAction<IUserRow>) => {
      const userIndex = state.findIndex(
        (user) => user.id === action.payload.id
      );

      state[userIndex] = action.payload;
      return state;
    },
    updateLocalUserNotes: (
      state,
      action: PayloadAction<{ userId: string; notes: string }>
    ) => {
      const userIndex = state.findIndex(
        (user) => user.id === action.payload.userId
      );

      state[userIndex].adminNotes = action.payload.notes;
    },
    updateLocalUseScore: (
      state,
      action: PayloadAction<{ userId: string; score: number }>
    ) => {
      const userIndex = state.findIndex(
        (user) => user.id === action.payload.userId
      );

      state[userIndex].userScoring = action.payload.score;
    },
    updateLocalUserVisibility: (
      state,
      action: PayloadAction<{ userId: string; visible: boolean }>
    ) => {
      const userIndex = state.findIndex(
        (user) => user.id === action.payload.userId
      );

      state[userIndex].accountVisibility = action.payload.visible;
    },
    updateLocalGrantRestrictUserAccess: (
      state,
      action: PayloadAction<{ userId: string; restrictAccess: boolean }>
    ) => {
      const userIndex = state.findIndex(
        (user) => user.id === action.payload.userId
      );

      state[userIndex].restrictAccess = action.payload.restrictAccess;
    },
    deleteLocalUser: (state, action: PayloadAction<string>) => {
      return state.filter((user) => user.id !== action.payload);
    },
    deleteLocalUsers: (state, action: PayloadAction<string[]>) => {
      return state.filter((user) => !action.payload.includes(user.id));
    }
  }
});

export const {
  addAllUsers,
  appendUsers,
  addLocalUser,
  updateLocalUser,
  updateLocalUserNotes,
  updateLocalUserVisibility,
  updateLocalGrantRestrictUserAccess,
  updateLocalUseScore,
  deleteLocalUser,
  deleteLocalUsers
} = usersSlice.actions;
