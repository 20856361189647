import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Scrollbar from 'react-scrollbars-custom';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './VideoDetailsTab.scss';
import Video from '../../Video/Video';
import { getMediaFile } from '../../../core/api';
import { FileResponseViewModel } from '../../../core/backend/models';
import getGlobal from '../../../core/globals';
import { Button } from '@mui/material';

interface IProps extends IBaseTabProps {
  video?: FileResponseViewModel;
  onViewBookingDetails?: () => void;
}

const VideoDetailsTab = ({ video, onViewBookingDetails, ...props }: IProps) => {
  const urlSection =
    video.linkedGig !== null
      ? `experience/${video.linkedGig.id}`
      : `clip/${video.id}`;

  const mediaUrl = `${getGlobal('appUrl')}/${urlSection}`;

  return (
    <BaseTab {...props} className='VideoDetailsTab'>
      <Scrollbar
        noScrollX={true}
        thumbYProps={{ className: 'thumbX' }}
        trackYProps={{ className: 'trackX' }}
        height='100%'
        width='100%'
      >
        <Grid
          container
          direction={'column'}
          rowSpacing={2}
          style={{ padding: '17px 12px 12px' }}
        >
          <Grid item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Video
                url={
                  video.awsUrl.endsWith('.mp4')
                    ? getMediaFile(video.awsUrl)
                    : null
                }
                coverUrl={getMediaFile(
                  video.thumbnails?.web[0] ?? video.awsUrl
                )}
                size='large'
              />
            </div>
          </Grid>
          <Grid item>
            <TextField
              id='description'
              label='Description'
              multiline
              fullWidth
              maxRows={14}
              variant='standard'
              value={
                video.linkedGig?.description?.trim() ??
                video.description?.trim() ??
                ''
              }
              inputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              id='title'
              label='Experience title'
              fullWidth
              variant='standard'
              value={video.linkedGig?.name?.trim() ?? ''}
              inputProps={{
                readOnly: true
              }}
            />
          </Grid>

          <Grid item>
            <Button variant='text' href={mediaUrl} target='_blank'>
              View Video
            </Button>
          </Grid>
        </Grid>
      </Scrollbar>
    </BaseTab>
  );
};

export default VideoDetailsTab;
