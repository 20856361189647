import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';
import { GigInvitationCodeViewModel } from '../../../core/backend/models';

const InvitationCodesTableHeader = ({
  onRequestSort,
  order,
  orderBy
}: ITableHeaderProps<GigInvitationCodeViewModel>) => {
  const createSortHandler =
    (property: keyof GigInvitationCodeViewModel) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          <TableSortLabel
            active={orderBy === 'code'}
            direction={orderBy === 'code' ? order : 'asc'}
            onClick={createSortHandler('code')}
          >
            Code
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'companyName'}
            direction={orderBy === 'companyName' ? order : 'asc'}
            onClick={createSortHandler('userName')}
          >
            User
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'bookingsLimit'}
            direction={orderBy === 'bookingsLimit' ? order : 'asc'}
            onClick={createSortHandler('bookingsLimit')}
          >
            Bookings Limit
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'expirationDateEpoch'}
            direction={orderBy === 'expirationDateEpoch' ? order : 'asc'}
            onClick={createSortHandler('expirationDateEpoch')}
          >
            Expiration Date
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel>Share Url</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default InvitationCodesTableHeader;
