import * as yup from 'yup';

export default yup.object({
  gigId: yup.number().nullable().min(3, 'Must be 3 characters at least'),
  userId: yup
    .string()
    .nullable()
    .min(3, 'Must be 3 characters at least')
    .required('Required'),
  expirationDateEpoch: yup.string().nullable().required('Required'),
  code: yup.string().nullable().required('Required'),
  bookingsLimit: yup.number().nullable()
});
