import {
  BookingStatus,
  CustomerReportReason,
  HostingType,
  LocationType,
  PricingModel,
  ProviderReportReason,
  TransferType,
  VideoRejectReasonType
} from '../backend/models';

export const translateCustomerReportReason = (reason: CustomerReportReason) => {
  switch (reason) {
    case CustomerReportReason.NUMBER_0:
      return 'Harrasment Or Bullying';
    case CustomerReportReason.NUMBER_1:
      return 'Harmful Desinformation';
    case CustomerReportReason.NUMBER_2:
      return 'Hate Speech';
    case CustomerReportReason.NUMBER_3:
      return 'Impersonating Me';
    case CustomerReportReason.NUMBER_4:
      return 'Nudity Or Pornography';
    case CustomerReportReason.NUMBER_5:
      return 'Private Information';
    case CustomerReportReason.NUMBER_6:
      return 'Sale Or Promotion Of Drugs';
    case CustomerReportReason.NUMBER_7:
      return 'Sale Or Promotion Of Firearms';
    case CustomerReportReason.NUMBER_8:
      return 'Self Harm';
    case CustomerReportReason.NUMBER_9:
      return 'Violence Or Physical Abuse Threat';
    case CustomerReportReason.NUMBER_10:
      return 'Admin Decline';
    case CustomerReportReason.NUMBER_11:
      return 'Spam';
    case CustomerReportReason.NUMBER_12:
      return 'Other';
    default:
      return 'Unknown';
  }
};

export const translateProviderReportReason = (status: ProviderReportReason) => {
  switch (status) {
    case ProviderReportReason.NUMBER_0:
      return 'Not Available';
    case ProviderReportReason.NUMBER_1:
      return 'Change Of Plans';
    case ProviderReportReason.NUMBER_2:
      return "Don't Want To Do It";
    case ProviderReportReason.NUMBER_3:
      return 'Change Price';
    case ProviderReportReason.NUMBER_4:
      return 'Harrasment Or Bullying';
    case ProviderReportReason.NUMBER_5:
      return 'Harmful Desinformation';
    case ProviderReportReason.NUMBER_6:
      return 'Hate Speech';
    case ProviderReportReason.NUMBER_7:
      return 'Impersonating Me';
    case ProviderReportReason.NUMBER_8:
      return 'Nudity Or Pornography';
    case ProviderReportReason.NUMBER_9:
      return 'Private Information';
    case ProviderReportReason.NUMBER_10:
      return 'Sale Or Promotion Of Drugs';
    case ProviderReportReason.NUMBER_11:
      return 'Sale Or Promotion Of Firearms';
    case ProviderReportReason.NUMBER_12:
      return 'Self Harm';
    case ProviderReportReason.NUMBER_13:
      return 'Violence Or Physical Abuse Threat';
    default:
      return 'Unknown';
  }
};

export const translateBookingStatus = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.NUMBER_0:
      return 'Creator Confirmation Needed';
    case BookingStatus.NUMBER_1:
      return 'Creator Declined';
    case BookingStatus.NUMBER_2:
      return 'Creator Confirmed';
    case BookingStatus.NUMBER_3:
      return 'Customer Canceled';
    case BookingStatus.NUMBER_4:
      return 'Customer Confimation Needed';
    case BookingStatus.NUMBER_5:
      return 'Finished';
    case BookingStatus.NUMBER_6:
      return 'Customer Left Feedback';
    case BookingStatus.NUMBER_7:
      return 'Declined';
    default:
      return 'Unknown';
  }
};

export const translateHostingType = (hostingType: number) => {
  switch (hostingType) {
    case 0:
      return 'Virtual';
    case 1:
      return 'In Person';
    default:
      return 'Unknown';
  }
};

export const translateLocationType = (locationType: LocationType) => {
  switch (locationType) {
    case LocationType.NUMBER_0:
      return 'None';
    case LocationType.NUMBER_1:
      return "Customer's Location";
    case LocationType.NUMBER_2:
      return 'My Location';
    case LocationType.NUMBER_3:
      return 'Flexible';
    default:
      return 'Unknown';
  }
};

export const translatePricingModel = (pricingModel: PricingModel) => {
  switch (pricingModel) {
    case PricingModel.NUMBER_0:
      return 'Hourly';
    case PricingModel.NUMBER_1:
      return 'Fixed Price';
    default:
      return 'Unknown';
  }
};

export const translateTransferType = (type: TransferType) => {
  switch (type) {
    case TransferType.NUMBER_0:
    default:
      return 'Unknown';
    case TransferType.NUMBER_1:
      return 'Booking Deposit';
    case TransferType.NUMBER_2:
      return 'Booking Final Payment';
    case TransferType.NUMBER_3:
      return 'Booking Full Payment';
    case TransferType.NUMBER_4:
      return 'Tip Payment';
  }
};

export const translateVideoRejectionReason = (
  rejectionReason: VideoRejectReasonType
) => {
  switch (rejectionReason) {
    case VideoRejectReasonType.NUMBER_0:
      return 'Inappropriate Behavior / Content';
    case VideoRejectReasonType.NUMBER_1:
      return 'Content not relevant to Experience';
    case VideoRejectReasonType.NUMBER_2:
      return 'Abusive Behavior';
    case VideoRejectReasonType.NUMBER_3:
      return 'Other';
    default:
      return '';
  }
};
