import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import { CommentViewModel } from '../../../core/backend/models';
import './CommentsTab.scss';
import Comments from '../../Comments/Comments';
import Comment from '../../Comment/Comment';

interface IProps extends IBaseTabProps {
  data: CommentViewModel[];
  isLoading?: boolean;
  onReachBottom?: () => void;
}

const CommentsTab = ({ data, isLoading, onReachBottom, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='CommentsTab'>
      <Comments
        data={data}
        isLoading={isLoading}
        onReachBottom={onReachBottom}
        renderItem={(comment) => (
          <Comment key={`comment-${comment.id}`} comment={comment} />
        )}
      />
    </BaseTab>
  );
};

export default CommentsTab;
