import { RoutesEnum, UserRoles } from './enums';
import { RolesService } from './types';

const rolesService = (): RolesService => {
  return {
    pathAccess: {
      [UserRoles.Unknown]: [RoutesEnum.SignIn],
      [UserRoles.Reviewer]: [
        RoutesEnum.BookingLogs,
        RoutesEnum.Events,
        RoutesEnum.ExternalEvents,
        RoutesEnum.Payments,
        RoutesEnum.Payouts,
        RoutesEnum.InvitationCode
      ],
      [UserRoles.Editor]: [
        RoutesEnum.Bookings,
        RoutesEnum.BookingLogs,
        RoutesEnum.Events,
        RoutesEnum.ExternalEvents,
        RoutesEnum.Payments,
        RoutesEnum.Payouts,
        RoutesEnum.ModerateBookingReports,
        RoutesEnum.ModerateVideoReports,
        RoutesEnum.ModerateComments,
        RoutesEnum.ModerateVideos,
        RoutesEnum.Banners,
        RoutesEnum.Categories,
        RoutesEnum.InvitationCode
      ],
      [UserRoles.SuperAdmin]: [
        RoutesEnum.EventBookings,
        RoutesEnum.ExperienceBookings,
        RoutesEnum.BookingLogs,
        RoutesEnum.Events,
        RoutesEnum.ExternalEvents,
        RoutesEnum.Payments,
        RoutesEnum.Payouts,
        RoutesEnum.ModerateBookingReports,
        RoutesEnum.ModerateVideoReports,
        RoutesEnum.ModerateComments,
        RoutesEnum.ModerateVideos,
        RoutesEnum.Statistics,
        RoutesEnum.Users,
        RoutesEnum.Banners,
        RoutesEnum.Categories,
        RoutesEnum.CouponList,
        RoutesEnum.CouponReport,
        RoutesEnum.InvitationCode,
        RoutesEnum.InvitationCodes
      ]
    },
    getUserAccessPermissionAreas(userRole: UserRoles) {
      return this.pathAccess[userRole];
    }
  };
};

export default rolesService;
