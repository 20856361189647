import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGeneralStats, IStatsRow } from '../../core/types';

const initialState: IGeneralStats = null;

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<IGeneralStats>) =>
      action.payload
  }
});

export const { setStatistics } = statisticsSlice.actions;
