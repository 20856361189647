import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Bookings.scss';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import { RoutesEnum } from '../../core/enums';
import BookingRequests from '../BookingRequests/BookingRequests';
import BookingLogs from '../BookingLogs/BookingLogs';
import { useAppSelector } from '../../state';

const Bookings = () => {
  const auth = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          {auth.accessPermissionAreas.includes(RoutesEnum.EventBookings) && (
            <>
              <Button
                color='secondary'
                component={NavLink}
                to={RoutesEnum.EventBookings}
                sx={{
                  '&.active': {
                    color: (theme) => theme.palette.primary.contrastText,
                    bgcolor: (theme) => theme.palette.secondary.dark
                  }
                }}
                exact
              >
                Events
              </Button>
              <Button
                color='secondary'
                component={NavLink}
                to={RoutesEnum.ExperienceBookings}
                sx={{
                  '&.active': {
                    color: (theme) => theme.palette.primary.contrastText,
                    bgcolor: (theme) => theme.palette.secondary.dark
                  }
                }}
                exact
              >
                Experiences
              </Button>
            </>
          )}
          {auth.accessPermissionAreas.includes(RoutesEnum.BookingLogs) && (
            <Button
              color='secondary'
              component={NavLink}
              to={RoutesEnum.BookingLogs}
              sx={{
                '&.active': {
                  color: (theme) => theme.palette.primary.contrastText,
                  bgcolor: (theme) => theme.palette.secondary.dark
                }
              }}
              exact
            >
              Booking Logs
            </Button>
          )}
        </ButtonGroup>
      </Box>
      <Switch>
        {/* <Route exact path={RoutesEnum.Bookings}>
          <Redirect to={RoutesEnum.EventBookings} />
        </Route> */}
        <Route
          exact
          path={[RoutesEnum.EventBookings, RoutesEnum.ExperienceBookings]}
          component={BookingRequests}
        />
        <Route exact path={RoutesEnum.BookingLogs} component={BookingLogs} />
      </Switch>
    </MainLayout>
  );
};

export default Bookings;
