import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Scrollbar, ScrollbarContext } from 'react-scrollbars-custom';
import { useAppSelector } from '../../state/hooks';
import './Conversation.scss';
import {
  ChatMemberViewModel,
  MessageViewModel
} from '../../core/backend/models';
import Message from '../Message/Message';
import Loader from '../Loader/Loader';

interface IProps {
  members: ChatMemberViewModel[];
  data: MessageViewModel[];
  form?: React.ReactNode;
  title?: string;
  onGoBackClick?: () => void;
  isDirectChat?: boolean;
  className?: string;
  isLoading?: boolean;
}

const Conversation = ({
  members,
  data,
  title,
  onGoBackClick,
  form,
  className,
  isDirectChat = true,
  isLoading = false
}: IProps) => {
  const auth = useAppSelector((state) => state.auth);
  const scroller = useRef<Scrollbar | null>(null);
  const firstUser = useRef<string | null>(null);

  useEffect(() => {
    if (isDirectChat && scroller) {
      scroller.current.scrollToBottom();
    }
  }, [data]);

  return (
    <div className='Conversation'>
      {title && (
        <div className='Conversation__navigation'>
          {onGoBackClick && (
            <IconButton onClick={onGoBackClick} sx={{ marginRight: '12px' }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {title}
        </div>
      )}
      <div className={`Conversation__wrapper ${className}`}>
        {isLoading ? (
          <Loader adaptToParent />
        ) : (
          <Scrollbar
            noScrollX={true}
            thumbYProps={{ className: 'thumbX' }}
            trackYProps={{ className: 'trackX' }}
            height='100%'
            width='100%'
            createContext={true}
          >
            <ScrollbarContext.Consumer>
              {({ parentScrollbar }) => {
                scroller.current = parentScrollbar;
                let lastDate: string | null = null;
                return (
                  <div className='Conversation__content'>
                    {data.length ? (
                      data.map((message, idx) => {
                        const currentDate = dayjs(
                          new Date(message.sendOn)
                        ).format('MM.DD.YYYY');

                        const member = members.find(
                          (member) => member.member.id === message.senderId
                        );

                        let alignTo: 'left' | 'right' = 'left';
                        if (!isDirectChat) {
                          if (!firstUser.current)
                            firstUser.current = message.senderId;

                          if (firstUser.current !== message.senderId)
                            alignTo = 'right';
                        } else {
                          if (message.senderId === auth.user.id)
                            alignTo = 'right';
                        }

                        const shouldDisplayDate = lastDate !== currentDate;
                        if (shouldDisplayDate) lastDate = currentDate;

                        return (
                          <>
                            {shouldDisplayDate && (
                              <div
                                key={`date-${idx}`}
                                className='Conversation__dateRow'
                              >
                                <div className='Conversation__date'>
                                  {currentDate}
                                </div>
                              </div>
                            )}
                            <Message
                              key={`message-${message.id}`}
                              alignTo={alignTo}
                              sender={member.member}
                              message={message}
                            />
                          </>
                        );
                      })
                    ) : (
                      <div className='Conversation__noMessages'>
                        No messages.
                      </div>
                    )}
                  </div>
                );
              }}
            </ScrollbarContext.Consumer>
          </Scrollbar>
        )}
      </div>
      {form && form}
    </div>
  );
};

export default Conversation;
