import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import Loader from '../../components/Loader/Loader';
import NewBannerModal from '../../components/Modals/NewBannerModal/NewBannerModal';
import BannerRow from '../../components/TableRows/BannerRow/BannerRow';
import BannerTableHeader from '../../components/TableHeaders/BannerTableHeader/BannerTableHeader';
import {
  createBanner,
  deleteBanner,
  getAllBanners,
  updateBanner,
  updateBannerSort
} from '../../core/api';
import { BannerType, BannerViewModel } from '../../core/backend/models';
import './Banners.scss';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import EditBannerModal from '../../components/Modals/EditBannerModal/EditBannerModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import { SwapVert } from '@mui/icons-material';
import { IUpdateBannersSortRequest } from '../../core/types';
import appToast from '../../core/toast';

const Banners = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [reportPage, setReportPage] = useState(0);
  const [isNewBannerOpen, setIsNewBannerOpen] = useState(false);
  const [editModal, setEditModal] = useState<{
    banner: BannerViewModel | null;
    isOpen: boolean;
  }>({ banner: null, isOpen: false });
  const [deleteModal, setDeleteModal] = useState<{
    id: number | null;
    isOpen: boolean;
  }>({ id: null, isOpen: false });
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [banners, setBanners] = useState<BannerViewModel[]>([]);
  const [compareSortData, setCompareSortData] = useState<
    { id: number; order: number }[]
  >([]);
  const [enableSaveSortBtn, setEnableSaveSortBtn] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  /**
   * Request content
   */
  useEffect(() => {
    getContent().then(() => setIsLoading(false));
  }, []);

  const getContent = async () => {
    let _banners = await getAllBanners();
    setBanners(_banners);
    setCompareSortData(
      _banners
        .map((b) => ({ id: b.id, order: b.order }))
        .sort((a, b) => a.order - b.order)
    );
  };

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setReportPage(newPage);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: BannerViewModel) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleCreateNewBanner = async (values: {
    image: File;
    link: string;
    type: BannerType;
    genericData?: string;
  }) => {
    try {
      setIsProcessing(true);
      const newBanner = await createBanner(
        values.image,
        values.link,
        values.type,
        values.genericData
      );
      // It updates Banners sorting:
      let _sortedBanners = [...banners];
      _sortedBanners
        .sort((a, b) => a.order - b.order)
        .forEach((b) => b.order++);
      const _newBanners = [newBanner, ..._sortedBanners];
      setCompareSortData(
        _newBanners.map((b) => ({ id: b.id, order: b.order }))
      );
      setBanners(_newBanners);
    } catch (e: any) {
      setErrorMsg(e.response.data.description);
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      closeNewBannerModal();
    }
  };

  const handleEditBanner = async (values: {
    image: File;
    link: string;
    type: BannerType;
    genericData?: string;
  }) => {
    try {
      setIsProcessing(true);

      const updatedBanner = await updateBanner(
        editModal.banner.id,
        values.link,
        values.image,
        values.type,
        values.genericData
      );

      const _banners = banners.map((banner) =>
        banner.id === updatedBanner.id ? updatedBanner : banner
      );

      setBanners(_banners);
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      handleCloseEditModal();
    }
  };

  const handleSortBanners = async () => {
    try {
      setIsProcessing(true);

      const _banners = banners.map(
        (banner): IUpdateBannersSortRequest => ({
          id: banner.id,
          order: banner.order
        })
      );

      await updateBannerSort(_banners);
      appToast.showSuccess('The banner sort was updated successfully!');
      setCompareSortData(
        banners
          .map((b) => ({ id: b.id, order: b.order }))
          .sort((a, b) => a.order - b.order)
      );
      setEnableSaveSortBtn(false);
    } catch (e: any) {
      setErrorMsg(e.description);
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteBanner = async () => {
    try {
      setIsProcessing(true);
      await deleteBanner(deleteModal.id);
      const deletedBannerOrder = banners.find(
        (banner) => banner.id === deleteModal.id
      ).order;

      // It updates Banners sorting:
      let _banners = banners.filter((banner) => banner.id !== deleteModal.id);
      _banners.forEach((b, i) => {
        if (b.order > deletedBannerOrder) {
          b.order--;
        }
      });
      setBanners(_banners);
      setCompareSortData(
        _banners
          .map((b) => ({ id: b.id, order: b.order }))
          .sort((a, b) => a.order - b.order)
      );
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      handleCloseDeleteModal();
    }
  };

  const deleteSelectedBanners = async () => {
    const _deleteBanners = selectedItems.map((id) => deleteBanner(id));
    Promise.all(_deleteBanners);

    const _newBanners = banners.filter(
      (banner) => !selectedItems.includes(banner.id)
    );

    setBanners(_newBanners);
    setSelectedItems([]);

    handleCloseDeleteModal();
  };

  /**
   * Modal Methods
   */
  const handleShowNewBannerModal = () => {
    setIsNewBannerOpen(true);
  };

  const closeNewBannerModal = () => {
    setIsNewBannerOpen(false);
  };

  const handleShowEditBanner = (banner: BannerViewModel) => () => {
    setEditModal({ banner, isOpen: true });
  };

  const handleCloseEditModal = () => {
    setEditModal({ banner: null, isOpen: false });
  };

  const handleShowDeleteBanner = (bannerId: number | null) => () => {
    setDeleteModal({ id: bannerId, isOpen: true });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ id: null, isOpen: false });
  };

  const handleCloseErrorModal = () => {
    setErrorMsg('');
    setErrorModalVisible(false);
  };

  const handleSortSelect = (
    event: React.ChangeEvent<Element>,
    banner: BannerViewModel
  ) => {
    const newOrderValue = Number(
      (event as React.ChangeEvent<HTMLInputElement>).target.value
    );

    const updatedBanners = banners.map((b) =>
      b.id === banner.id ? { ...b, order: newOrderValue } : b
    );
    const sortedBanners = updatedBanners
      .sort((a, b) => {
        if (a.order - b.order !== 0) return a.order - b.order;
        return -1;
      })
      .map((item, index) => ({
        ...item,
        order: index
      }));

    setBanners(sortedBanners);

    const bannersJson = JSON.stringify(
      sortedBanners.map((b) => ({ id: b.id, order: b.order }))
    );
    const compareSortDataJson = JSON.stringify(compareSortData);
    setEnableSaveSortBtn(bannersJson !== compareSortDataJson);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='Banners'>
        <div className='Banners__actionButtons'>
          <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={handleShowNewBannerModal}
          >
            Add New Banner
          </Button>
          {enableSaveSortBtn && (
            <Button
              variant='text'
              color='secondary'
              startIcon={<SwapVert />}
              onClick={handleSortBanners}
            >
              Save Banner Sort
            </Button>
          )}
        </div>
        <div className='Banners__content'>
          <BasicTable
            Header={BannerTableHeader}
            data={banners}
            idFromItem={idFromItem}
            page={reportPage}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            renderItem={(banner, index, { isSelected, handleSelectRow }) => (
              <BannerRow
                sortMaxLength={banners.length}
                key={banner.id}
                banner={banner}
                selected={isSelected}
                onSelect={handleSelectRow(banner.id)}
                onEdit={handleShowEditBanner(banner)}
                onDelete={handleShowDeleteBanner(banner.id)}
                onSortChange={(ev) => handleSortSelect(ev, banner)}
              />
            )}
          />
        </div>
      </div>

      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onDeclineAll={handleShowDeleteBanner(null)}
          declineTitle='Delete'
        />
      )}

      <NewBannerModal
        isOpen={isNewBannerOpen}
        onClose={closeNewBannerModal}
        isProcessing={isProcessing}
        onAccept={handleCreateNewBanner}
      />

      <EditBannerModal
        isOpen={editModal.isOpen}
        banner={editModal.banner}
        onClose={handleCloseEditModal}
        isProcessing={isProcessing}
        onAccept={handleEditBanner}
      />

      <BaseModal
        title='Delete Banner'
        isOpen={deleteModal.isOpen}
        isProcessing={isProcessing}
        onAccept={deleteModal.id ? handleDeleteBanner : deleteSelectedBanners}
        onClose={handleCloseDeleteModal}
      >
        Are you sure you want to delete the banner(s)? This action can not be
        undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModalVisible}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        message={errorMsg}
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default Banners;
