import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PaymenttTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='normal'>Reservation</TableCell>
        <TableCell align='center' padding='normal'>
          Amount
        </TableCell>
        <TableCell padding='normal'>Payment Description</TableCell>
        <TableCell padding='normal'>Customer</TableCell>
        <TableCell align='center' padding='normal'>
          Date of Transaction
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default PaymenttTableHeader;
