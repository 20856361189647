import { useCallback, useState } from 'react';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './JustifyBookingModal.scss';

interface FormValues {
  message: string;
}

const JustifyBookingModal = ({ onAccept, onClose, ...props }: IModalProps) => {
  const { errors, values, handleChange, submitForm, resetForm } =
    useFormik<FormValues>({
      initialValues: { message: '' },
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        await onAccept();
        resetForm();
      }
    });

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Booking Cancellation'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='ResolveIssueModal__subheader'>
        This booking will be removed from this list and moved to booking logs.
      </div>
    </BaseModal>
  );
};

export default JustifyBookingModal;
