import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';

type Size = 'sm' | 'md' | 'lg';

const sizes: { [key: string]: number } = {
  sm: 28,
  md: 40,
  lg: 160
};

interface IProps {
  url?: string;
  letter?: string;
  size?: Size;
  className?: string;
}

const UserImage = ({ url, letter, size = 'md', className }: IProps) => {
  const avatarSettings = {
    width: sizes[size],
    height: sizes[size],
    bgcolor: deepPurple[500]
  };

  return (
    <div className={`UserImage ${className}`}>
      {url ? (
        <Avatar alt='' src={url} sx={avatarSettings} />
      ) : (
        <Avatar sx={avatarSettings}>{letter}</Avatar>
      )}
    </div>
  );
};

export default UserImage;
