import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITableHeaderProps } from '../../../core/types';
import { GetAllGigTicketClicksResponse } from '../../../core/backend/models';
import { TableSortLabel } from '@mui/material';

const ExternalEventsTableHeader: React.FC<
  ITableHeaderProps<GetAllGigTicketClicksResponse>
> = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler =
    (property: keyof GetAllGigTicketClicksResponse) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Event
        </TableCell>
        <TableCell align='left' padding='normal'>
          Link
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'totalClicks'}
            direction={orderBy === 'totalClicks' ? order : 'asc'}
            onClick={createSortHandler('totalClicks')}
          >
            Clicks
          </TableSortLabel>
        </TableCell>
        <TableCell align='right' padding='normal'>
          <TableSortLabel
            active={orderBy === 'totalCost'}
            direction={orderBy === 'totalCost' ? order : 'asc'}
            onClick={createSortHandler('totalCost')}
          >
            Cost
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ExternalEventsTableHeader;
