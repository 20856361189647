import Scrollbar from 'react-scrollbars-custom';
import dayjs from 'dayjs';
import './Comments.scss';
import { CommentViewModel } from '../../core/backend/models';
import { useRef } from 'react';
import Loader from '../Loader/Loader';

interface IProps {
  data: CommentViewModel[];
  isLoading: boolean;
  renderItem?: (comment: CommentViewModel) => React.ReactNode;
  onReachBottom?: () => void;
  className?: string;
}

const Comments = ({
  data,
  isLoading,
  onReachBottom,
  className,
  renderItem
}: IProps) => {
  const lastDate = useRef<string | null>(null);

  return (
    <div className='Comments'>
      <div className={`Comments__wrapper ${className}`}>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
          createContext={true}
          onScrollStop={(scrollValues) => {
            const { clientHeight, scrollHeight, scrollTop } = scrollValues;
            if (clientHeight + scrollTop === scrollHeight && !isLoading)
              onReachBottom();
          }}
        >
          <div className='Comments__content'>
            {data.map((comment, idx) => {
              const currentDate = dayjs(new Date(comment.timeStamp)).format(
                'MM.DD.YYYY'
              );
              const dateAreNotTheSame = currentDate !== lastDate.current;
              if (dateAreNotTheSame) lastDate.current = currentDate;

              return (
                <>
                  {dateAreNotTheSame && (
                    <div
                      key={`date-container-${idx}`}
                      className='Comments__dateRow'
                    >
                      <div className='Comments__date'>{currentDate}</div>
                    </div>
                  )}
                  {renderItem(comment)}
                </>
              );
            })}

            {!isLoading && !data.length && (
              <div className='Comments__noMessages'>No comments.</div>
            )}
            {isLoading && (
              <div className='Comments__loading'>
                <Loader adaptToParent size={42} />
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default Comments;
