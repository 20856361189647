import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import { isValidEmail } from '../../../core/helpers/helpers';
import { IModalProps } from '../../../core/types';
import { UserViewModel } from '../../../core/backend/models';
import BaseModal from '../BaseModal/BaseModal';
import './EditUserModal.scss';
import UserImage from '../../UserImage/UserImage';
import { getMediaFile } from '../../../core/api';
import UserSchema from '../../../validators/UserSchema';

type FormValues = Partial<UserViewModel>;
interface IProps extends IModalProps {
  user: UserViewModel;
}

const EditUserModal = ({ user, onAccept, onClose, ...props }: IProps) => {
  const { values, errors, handleChange, resetForm, submitForm, setFieldValue } =
    useFormik<FormValues>({
      initialValues: { ...user },
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      validationSchema: UserSchema,
      onSubmit: async (values: FormikValues, { resetForm }) => {
        await onAccept(user, values);
        resetForm();
      }
    });

  const handleLimitedInputsChange = (
    field: string,
    length = 2,
    numbersOnly = false
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value.trim();
      const onlyNumbersRegex = /^[0-9\b]+$/;

      if (inputValue.length > 0 && numbersOnly) {
        if (!onlyNumbersRegex.test(inputValue)) return;
      }

      if (inputValue.length > length) {
        setFieldValue(field, inputValue.slice(0, length));
        return;
      }

      setFieldValue(field, inputValue);
    };
  };

  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  return (
    <BaseModal
      title='Edit User'
      size='lg'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <Grid container direction={'column'} rowSpacing={2}>
        <Grid item>
          <UserImage
            className='EditUserModal__image'
            letter='E'
            size='lg'
            url={getMediaFile(values?.image)}
          />
        </Grid>

        <Grid item>
          <TextField
            name='fullName'
            label='Full Name'
            variant='standard'
            value={values?.fullName ?? ''}
            onChange={handleChange}
            helperText={errors.fullName ?? ''}
            error={!!errors.fullName}
            fullWidth
          />
        </Grid>

        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name='userName'
              label='Username'
              variant='standard'
              value={values?.userName ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>@</InputAdornment>
                )
              }}
              onChange={handleChange}
              helperText={errors.userName ?? ''}
              error={!!errors.userName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='email'
              label='Email'
              variant='standard'
              value={values?.email ?? ''}
              onChange={handleChange}
              helperText={errors.email ?? ''}
              error={!!errors.email}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: '12px' }}>
            <TextField
              name='website'
              label='Website'
              variant='standard'
              value={values?.website ?? ''}
              onChange={handleChange}
              helperText={errors.website ?? ''}
              error={!!errors.website}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item>
          <TextField
            name='about'
            label='About'
            variant='standard'
            multiline
            rows={6}
            value={values?.about ?? ''}
            onChange={handleChange}
            helperText={errors.about ?? ''}
            error={!!errors.about}
            fullWidth
          />
        </Grid>
        <Grid item container columnSpacing={8}>
          <Grid item>
            <FormControlLabel
              label='Creator'
              control={
                <Checkbox
                  name='isCreatorPro'
                  checked={values?.isCreatorPro}
                  onChange={handleChange}
                />
              }
            />
            {errors.isCreatorPro}
          </Grid>
          {values?.isCreatorPro && (
            <Grid item>
              <FormControlLabel
                label='Creator Pro'
                control={
                  <Checkbox
                    name='isProviderPro'
                    checked={values?.isProviderPro}
                    onChange={handleChange}
                  />
                }
              />
              {errors.isProviderPro}
            </Grid>
          )}
        </Grid>

        {values?.isCreatorPro && (
          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                name='address1'
                label='Address 1'
                variant='standard'
                value={values?.address1 ?? ''}
                onChange={handleChange}
                helperText={errors.address1 ?? ''}
                error={!!errors.address1}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='address2'
                label='Address 2'
                variant='standard'
                value={values?.address2 ?? ''}
                onChange={handleChange}
                helperText={errors.address2 ?? ''}
                error={!!errors.address2}
                fullWidth
              />
            </Grid>
            <Grid item xs={5} sx={{ marginTop: '12px' }}>
              <TextField
                name='city'
                label='City'
                variant='standard'
                value={values?.city ?? ''}
                onChange={handleChange}
                helperText={errors.city ?? ''}
                error={!!errors.city}
                fullWidth
              />
            </Grid>
            <Grid item xs={5} sx={{ marginTop: '12px' }}>
              <TextField
                sx={{ textTransform: 'uppercase' }}
                name='state'
                label='State'
                variant='standard'
                value={values?.state ?? ''}
                onChange={handleLimitedInputsChange('state', 2)}
                helperText={errors.state ?? ''}
                error={!!errors.state}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{ marginTop: '12px' }}>
              <TextField
                name='zip'
                label='Zip'
                variant='standard'
                value={values?.zip ?? ''}
                onChange={handleLimitedInputsChange('zip', 5, true)}
                helperText={errors.zip ?? ''}
                error={!!errors.zip}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </BaseModal>
  );
};

export default EditUserModal;
