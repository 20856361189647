import { useState } from 'react';
import { useDebounce } from './useDebounce';

export function useSimpleSearch(
  initialValue: string,
  delay = 300,
  minLength = 2
) {
  const [searchText, setSearchText] = useState<string>(initialValue);
  const trimmed = searchText.trim();
  const debouncedSearchText = useDebounce(
    trimmed.length >= minLength ? trimmed : '',
    delay
  );

  const onSearchChange = (value: string) => {
    setSearchText(value);
  };

  return {
    debouncedSearchText,
    searchText,
    onSearchChange
  };
}
