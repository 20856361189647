import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ITableHeaderProps } from '../../../core/types';

const BookingReportsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Video
        </TableCell>
        <TableCell padding='normal'>Name</TableCell>
        <TableCell padding='normal'>User</TableCell>
        <TableCell padding='normal'>Reported by</TableCell>
        <TableCell padding='normal'>Reported on</TableCell>
        <TableCell align='center' padding='normal'>
          Status
        </TableCell>
        <TableCell align='center' padding='normal'>
          Report
        </TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BookingReportsTableHeader;
