import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Video from '../../Video/Video';
import { getMediaFile } from '../../../core/api';
import './BookingReportRow.scss';
import Status from '../../Status/Status';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import UserImage from '../../UserImage/UserImage';
import {
  translateCustomerReportReason,
  translateProviderReportReason
} from '../../../core/helpers/enums-helpers';
import { UserViewModel } from '../../../core/backend/models';
import { IBookingReportRow } from '../../../core/types';

interface IProps extends IBaseRowProps {
  booking: IBookingReportRow;
  isActive: boolean;
  status: string;
  onPreviewUser: (user: UserViewModel) => React.MouseEventHandler;
  onViewDetails: React.MouseEventHandler;
}

const BookingReportRow = ({
  booking,
  isActive,
  onPreviewUser,
  status,
  onViewDetails
}: IProps) => {
  const mediaFiles = booking.gig.gigMainMediaFiles ?? [];
  const media = mediaFiles[mediaFiles.length - 1];

  return (
    <BaseRow selected={isActive}>
      <TableCell component='th' scope='row'>
        <Video
          coverUrl={getMediaFile(media.thumbnails?.web[0] ?? media.awsUrl)}
          url={getMediaFile(media.awsUrl)}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        {booking.gig.name}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        <div
          className='BookingReportRow__userInfo'
          onClick={onPreviewUser(booking.gig.provider)}
        >
          <UserImage
            className='BookingReportRow__image'
            url={getMediaFile(booking.gig.provider.image)}
          />
          <div className='BookingReportRow__userFullName'>
            {booking.gig.provider.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '140px' }}>
        <div
          className='BookingReportRow__userInfo'
          onClick={onPreviewUser(
            booking.customerReportReason
              ? booking.customer
              : booking.gig.provider
          )}
        >
          <UserImage
            className='BookingReportRow__image'
            url={getMediaFile(
              booking.customerReportReason
                ? booking.customer.image
                : booking.gig.provider.image
            )}
          />
          <div className='BookingReportRow__userFullName'>
            {booking.customerReportReason
              ? booking.customer.fullName
              : booking.gig.provider.fullName}
          </div>
        </div>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <p className='BookingLogRow__cellRow'>
          {booking.formattedReportingDate}
        </p>
        <p className='BookingLogRow__cellRow'>
          {booking.formattedReportingTime}
        </p>
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <Status status={status} />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '120px' }}
      >
        <div className='VideoReportRow__reportReasons'>
          {booking.customerReportReason
            ? translateCustomerReportReason(booking.customerReportReason)
            : translateProviderReportReason(booking.providerReportReason)}
        </div>
      </TableCell>
      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <Button variant='text' onClick={onViewDetails}>
          View details
        </Button>
      </TableCell>
    </BaseRow>
  );
};

export default BookingReportRow;
