import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import './CommentRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { CommentViewModel, UserViewModel } from '../../../core/backend/models';
import UserImage from '../../UserImage/UserImage';
import dayjs from 'dayjs';

interface IProps extends IBaseRowProps {
  comment: CommentViewModel;
  onSelect: React.MouseEventHandler;
  onPreviewUser: (user: UserViewModel) => React.MouseEventHandler;
  onDelete: () => void;
}

const CommentRow = ({
  comment,
  selected,
  onSelect,
  onPreviewUser,
  onDelete
}: IProps) => {
  const date = dayjs(comment.timeStamp);
  const formattedAddedAtDate = date.format('MM/DD/YY');
  const formattedAddedAtTime = date.format('hh:mm a');

  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell component='th' scope='row' style={{ maxWidth: '500px' }}>
        {comment.text}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top', maxWidth: '300px' }}>
        <div
          className='CommentRow__userInfo'
          onClick={onPreviewUser(comment.user)}
        >
          <UserImage
            className='CommentRow__image'
            url={getMediaFile(comment.user.image)}
          />

          <div className='CommentRow__userNameWrapper'>
            <div className='CommentRow__userName'>@{comment.user.userName}</div>
            <div className='CommentRow__userFullName'>
              {comment.user.fullName}
            </div>
          </div>
        </div>
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <p className='CommentRow__cellRow'>{formattedAddedAtDate}</p>
        <p className='CommentRow__cellRow'>{formattedAddedAtTime}</p>
      </TableCell>

      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <Button variant='text' color='error' onClick={onDelete}>
          <ClearIcon /> Delete
        </Button>
      </TableCell>
    </BaseRow>
  );
};

export default CommentRow;
