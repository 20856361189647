import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormikValues, useFormik } from 'formik';
import { CouponData, IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import { GigViewModel, UserViewModel } from '../../../core/backend/models';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import './NewDiscountModal.scss';
import { forwardRef, useCallback, useState } from 'react';
import DiscountSchema, {
  DiscountSchema2
} from '../../../validators/DiscountSchema';
import { generateRandomString } from '../../../core/helpers/helpers';
import SimpleFilter from '../../SimpleFilter/SimpleFilter';
import { searchCreators, searchExperiences } from '../../../core/api';
import dayjs from 'dayjs';

interface IProps extends IModalProps {}

const NewDiscountModal = ({ onAccept, onClose, ...props }: IProps) => {
  const [step, setStep] = useState(1);
  const [creatorsValue, setCreatorValue] = useState<UserViewModel[]>([]);
  const [creatorOptions, setCreatorOptions] = useState<UserViewModel[]>([]);
  const [experiencesValue, setExperiencesValue] = useState<GigViewModel[]>([]);
  const [experiencesOptions, setExperiencesOptions] = useState<GigViewModel[]>(
    []
  );

  const {
    values,
    errors,
    handleChange,
    resetForm,
    submitForm,
    setFieldValue,
    validateForm
  } = useFormik<CouponData>({
    initialValues: {
      code: '',
      type: 'fixed',
      value: 0,
      maxRedemptions: 0,
      maxRedemptionsByCustomer: 0,
      costBearer: '-1',
      restrictToExperiences: [],
      restrictToProviders: [],
      expiresAt: undefined,
      expirationDate: undefined
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: step === 1 ? DiscountSchema : DiscountSchema2,
    onSubmit: async (values: FormikValues, { resetForm }) => {
      const ok = await onAccept(values);
      if (ok) {
        setStep(1);
        resetForm();
        setCreatorValue([]);
        setCreatorOptions([]);
        setExperiencesOptions([]);
        setExperiencesValue([]);
      }
    }
  });

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value.trim().length) return setFieldValue('code', '');

    if (value.match(/^[a-zA-Z0-9]+$/)) {
      setFieldValue('code', value.toUpperCase());
    }
  };

  const handleLimitedInputsChange = (
    field: string,
    length = 2,
    numbersOnly = false
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value.trim();
      const onlyNumbersRegex = /^[0-9\b]+$/;

      if (inputValue.length > 0 && numbersOnly) {
        if (!onlyNumbersRegex.test(inputValue)) return;
      }

      if (inputValue.length > length) {
        setFieldValue(field, inputValue.slice(0, length));
        return;
      }

      setFieldValue(field, inputValue);
    };
  };

  const handleDateChange = (date: Date) => {
    const _date = dayjs(date).hour(23).minute(59).second(59);
    setFieldValue('expiresAt', _date.valueOf());
    setFieldValue('expirationDate', _date.toDate());
  };

  const handleGenerateCode = () => {
    const discountCode = generateRandomString(8);
    setFieldValue('code', discountCode);
  };

  const handleAccept = async () => {
    const errors = await validateForm();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) return;

    if (step === 1) setStep((prevStep) => prevStep + 1);
    else submitForm();
  };

  const handleSearchExperience = useCallback(
    async (query: string) => {
      const result = await searchExperiences({
        experienceName: query,
        providers: values?.restrictToProviders
      });

      return result;
    },
    [values?.restrictToProviders]
  );

  const handleOnClose = () => {
    if (step === 2) {
      setStep((prevStep) => prevStep - 1);
    } else {
      onClose();
      resetForm();
    }
  };

  const CustomDateInput = forwardRef(
    (
      {
        value,
        onClick
      }: {
        value?: string;
        onClick?: React.MouseEventHandler;
      },
      ref: any
    ) => (
      <TextField
        className='NewDiscountModal__datePicker'
        label='Expiration Date'
        onClick={onClick}
        ref={ref}
        variant='standard'
        value={value}
        inputProps={{ readOnly: true }}
      />
    )
  );

  return (
    <BaseModal
      title='New Discount Code'
      size='md'
      onAccept={handleAccept}
      acceptButtonTitle={step === 1 ? 'Next' : 'Create'}
      cancelButtonTitle={step === 2 ? 'Back' : 'Cancel'}
      onClose={handleOnClose}
      {...props}
    >
      {step === 1 && (
        <Grid container direction={'column'} rowSpacing={2}>
          <Grid item container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                name='code'
                label='Code'
                variant='standard'
                value={values?.code ?? ''}
                onChange={handleCodeChange}
                inputProps={{
                  style: { textTransform: 'uppercase' },
                  pattern: '[A-Z0-9]'
                }}
                InputProps={{
                  endAdornment: (
                    <Button onClick={handleGenerateCode}>GENERATE</Button>
                  )
                }}
                helperText={errors.code ?? ''}
                error={!!errors.code}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              <FormLabel id='radio-buttons-group-label'>Coupon Type</FormLabel>
              <RadioGroup
                aria-labelledby='radio-buttons-group-label'
                value={values.type}
                name='type'
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value='fixed'
                  control={<Radio />}
                  label='Fixed Price'
                />
                <FormControlLabel
                  value='percentage'
                  control={<Radio />}
                  label='Percentage'
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={6}>
              <TextField
                name='value'
                label={values.type === 'fixed' ? 'Fixed Price' : 'Percentage'}
                variant='standard'
                value={values?.value ?? ''}
                InputProps={{
                  startAdornment: values.type === 'fixed' && (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                  endAdornment: values.type === 'percentage' && (
                    <InputAdornment position='end'>%</InputAdornment>
                  )
                }}
                onChange={handleLimitedInputsChange('value', 5, true)}
                helperText={errors.value ?? ''}
                error={!!errors.value}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                name='maxRedem'
                label='Max Redemptions'
                variant='standard'
                value={values?.maxRedemptions ?? ''}
                onChange={handleLimitedInputsChange('maxRedemptions', 3, true)}
                helperText={errors.maxRedemptions ?? ''}
                error={!!errors.maxRedemptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name='maxRedem'
                label='Max Redemptions Per Customer'
                variant='standard'
                value={values?.maxRedemptionsByCustomer ?? ''}
                onChange={handleLimitedInputsChange(
                  'maxRedemptionsByCustomer',
                  3,
                  true
                )}
                helperText={errors.maxRedemptionsByCustomer ?? ''}
                error={!!errors.maxRedemptionsByCustomer}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* end of first block */}

      {step === 2 && (
        <Grid container direction={'column'} rowSpacing={2}>
          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.costBearer}>
                <InputLabel id='bearer-label'>Coupon Bearer</InputLabel>
                <Select
                  labelId='bearer-select-label'
                  id='bearer-select'
                  value={values.costBearer}
                  label='Coupon Bearer'
                  name='costBearer'
                  onChange={handleChange}
                >
                  <MenuItem key='select' value='-1'>
                    Select
                  </MenuItem>
                  <MenuItem key='0' value='0'>
                    Hoopla
                  </MenuItem>
                  <MenuItem key='1' value='1'>
                    Creator
                  </MenuItem>
                </Select>
                {errors.costBearer && (
                  <FormHelperText>{errors.costBearer}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.expirationDate}>
                <DatePicker
                  onChange={handleDateChange}
                  onYearChange={handleDateChange}
                  onMonthChange={handleDateChange}
                  selected={values.expirationDate}
                  customInput={<CustomDateInput />}
                  dropdownMode='select'
                  showYearDropdown
                />

                {errors.expirationDate && (
                  <FormHelperText>{errors.expirationDate}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item>
            <SimpleFilter
              label='Restrict to Creators'
              placeholder='Type here to search'
              fetchMethod={searchCreators}
              labelProp='userName'
              valueProp='id'
              value={creatorsValue}
              options={creatorOptions}
              onOptionsChanged={(options) => setCreatorOptions(options)}
              onChange={(formattedValue, rawValue) => {
                setFieldValue('restrictToProviders', formattedValue);
                setCreatorValue(rawValue);
              }}
            />
          </Grid>

          <Grid item>
            <SimpleFilter
              label='Restrict to Experiences'
              placeholder='Type here to search'
              fetchMethod={handleSearchExperience}
              labelProp='name'
              valueProp='id'
              value={experiencesValue}
              options={experiencesOptions}
              onOptionsChanged={(options) => setExperiencesOptions(options)}
              onChange={(formattedValue, rawValue) => {
                setFieldValue('restrictToExperiences', formattedValue);
                setExperiencesValue(rawValue);
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              multiline
              name='adminComment'
              label='Notes'
              variant='standard'
              value={values?.adminComment ?? ''}
              onChange={handleChange}
              minRows={5}
              maxRows={10}
            />
          </Grid>
        </Grid>
      )}
    </BaseModal>
  );
};

export default NewDiscountModal;
