import { useEffect, useState } from 'react';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import BookingRow from '../../components/TableRows/BookingRow/BookingRow';
import { getBookingsResponseTime, justifyBooking } from '../../core/api';
import {
  AdminActionTaken,
  CancelBookingOnBehalfOfProviderRequest,
  UserViewModel
} from '../../core/backend/models';
import {
  FilterOptionType,
  IBookingPanel,
  IBookingRow,
  IMessage,
  IUserPanel
} from '../../core/types';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import BookingsTableHeader from '../../components/TableHeaders/BookingsTableHeader/BookingsTableHeader';
import JustifyBookingModal from '../../components/Modals/JustifyBookingModal/JustifyBookingModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import './BookingRequests.scss';
import { addAllBookings, clearSchedules } from '../../state';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchBookingsFilter } from '../../core/helpers/search-helpers';
import Filter from '../../components/Filter/Filter';
import { bookingFilterOptions } from '../../core/helpers/filter-helpers';
import BookingPanel from '../../components/DetailsPanel/BookingPanel/BookingPanel';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import { sortBookings } from '../../core/helpers/sorting-helpers';
import { useLocation } from 'react-router-dom';
import { createLightBookingModel } from '../../core/helpers/model-helpers';
import { BookingType, SortType } from '../../core/enums';
import { DEFAULT_ERROR_MESSAGE } from '../../core/consts';
import appToast from '../../core/toast';
import EventBookingRow from '../../components/TableRows/EventBookingRow/EventBookingRow';
import EventBookingsTableHeader from '../../components/TableHeaders/EventBookingsTableHeader/EventBookingsTableHeader';
import Loader from '../../components/Loader/Loader';

interface IJustifyModal {
  isOpen: boolean;
  justification: AdminActionTaken;
  bookingId: number;
}

const BookingRequests = () => {
  const dispatch = useAppDispatch();
  const bookings = useAppSelector((state) => state.bookings);
  const [isSendingJustification, setIsSendingJustification] = useState(false);
  const [bookingsPage, setBookingsPage] = useState(0);
  const [bookingPanel, setBookingPanel] = useState<IBookingPanel>({
    isOpen: false,
    bookingId: null,
    experienceId: null,
    relatedRowIndex: -1
  });
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [errorModal, setErrorModal] = useState<IMessage>({
    isOpen: false,
    message: null
  });
  const [justifyModal, setJustifyModal] = useState<IJustifyModal>({
    isOpen: false,
    justification: null,
    bookingId: 0
  });
  const [currentBookings, setCurrentBookings] =
    useState<IBookingRow[]>(bookings);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [bookingType, setBookingType] = useState<BookingType>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const bookingType = pathname.includes('events')
      ? BookingType.Event
      : BookingType.Experience;

    setBookingType(bookingType);
    getBookings(bookingType);
  }, [pathname]);

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setBookingsPage(newPage);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const idFromItem = (item: IBookingRow) => {
    return item.id;
  };

  /**
   * Actions
   */

  const getBookings = async (
    bookingType: BookingType,
    searchQuery: string | undefined = undefined,
    sort?: SortType
  ) => {
    try {
      setIsLoading(true);

      const bookingsResponse = await getBookingsResponseTime(
        bookingType,
        searchQuery,
        sort,
        0,
        1000
      );

      const bookings = bookingsResponse
        .map(createLightBookingModel)
        .sort(sortBookings);

      dispatch(addAllBookings(bookings));
      setCurrentBookings(bookings);
    } catch (e: any) {
      const error = e.response?.date?.description ?? DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterByUpcomingDates = (filteredBookings: Array<IBookingRow>) => {
    const currentDate = new Date();
    const upcomingEvents = filteredBookings.filter((report: IBookingRow) => {
      const itemDate = new Date(report.formattedEventTimeDate);
      return itemDate > currentDate;
    });
    return upcomingEvents;
  };

  const filterBookingsWith = (selectedFilters: FilterOptionType[]) => {
    if (selectedFilters.length) {
      const statusFilters: string[] = selectedFilters.map((filter) => {
        if (filter.group === 'Status') return filter.value;
      });

      const dateFilters: string[] = selectedFilters.map((filter) => {
        if (filter.group === 'Date') return filter.value;
      });

      let filteredBookings = bookings.filter((report: IBookingRow) =>
        statusFilters.includes(report.status)
      );

      if (dateFilters.length !== 0 && dateFilters[0] !== undefined) {
        filteredBookings.length !== 0
          ? (filteredBookings = filterByUpcomingDates(filteredBookings))
          : (filteredBookings = filterByUpcomingDates(bookings));
      }

      setCurrentBookings(filteredBookings);
      return;
    }
  };

  const handleSearchContent = (query: string) => {
    if (!query.length) {
      setBookingsPage(0);
      setCurrentBookings(bookings);
      return;
    }

    const _result = bookings.filter(searchBookingsFilter(query.toLowerCase()));

    setBookingsPage(0);
    setCurrentBookings(_result);
  };

  /**
   * Side Panel
   */

  const handlePreviewExperience =
    (booking: IBookingRow, relatedRowIndex: number, initialTab = 0) =>
    () => {
      setBookingPanel({
        ...bookingPanel,
        isOpen: true,
        bookingId: booking.id,
        experienceId: booking.gig?.id ?? null,
        relatedRowIndex,
        initialTab
      });
    };

  const handlePreviewUser =
    (user: UserViewModel, relatedRowIndex: number) => () => {
      setUserPanel({ ...userPanel, user, relatedRowIndex, isOpen: true });
    };

  const handleJustification =
    (bookingId: number) => (justification: AdminActionTaken) => {
      setJustifyModal({
        isOpen: true,
        justification,
        bookingId
      });
    };

  const closeUserPanel = () => {
    setUserPanel({
      ...userPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  const closeBookingPanel = () => {
    setBookingPanel({
      ...bookingPanel,
      isOpen: false,
      relatedRowIndex: -1
    });
  };

  const handleSendJustification = async () => {
    try {
      setIsSendingJustification(true);

      const { bookingId, justification } = justifyModal;

      const payload: CancelBookingOnBehalfOfProviderRequest = {
        bookingId,
        actionTaken: justification
      };

      await justifyBooking(payload);

      const filteredBookings = bookings.filter(
        (booking) => booking.id !== bookingId
      );
      dispatch(addAllBookings(filteredBookings));
      setCurrentBookings(bookings);
      handleCloseBookingModal();
      openSnack('Booking has been cancelled and removed from this list.');
    } catch (e: any) {
      setErrorModal({
        isOpen: true,
        message: e?.response?.data?.description ?? null
      });
    } finally {
      setIsSendingJustification(false);
    }
  };

  const handleCloseBookingModal = () => {
    setJustifyModal({ isOpen: false, justification: null, bookingId: 0 });
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ isOpen: false, message: null });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='BookingRequests'>
        <div className='BookingRequests__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSearchContent}
            className='BookingRequests__search'
          />
          <Filter
            options={bookingFilterOptions}
            onChange={(selectedFilters: FilterOptionType[]) => {
              if (!selectedFilters.length) {
                setCurrentBookings(bookings);
                return;
              }

              filterBookingsWith(selectedFilters);
            }}
          />
        </div>

        <div className='BookingRequests__content'>
          <BasicTable
            Header={BookingsTableHeader}
            data={currentBookings}
            idFromItem={idFromItem}
            page={bookingsPage}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            initialOrderBy='status'
            customSortingHandlers={{ status: sortBookings }}
            renderHeader={(headerProps) =>
              bookingType === BookingType.Event ? (
                <EventBookingsTableHeader {...headerProps} />
              ) : (
                <BookingsTableHeader {...headerProps} />
              )
            }
            renderItem={(booking, index, { isSelected, handleSelectRow }) =>
              bookingType === BookingType.Event ? (
                <EventBookingRow
                  key={`booking-row-${index}`}
                  booking={booking}
                  isActive={bookingPanel.relatedRowIndex === index}
                  onPreviewEvent={handlePreviewExperience(booking, index)}
                  onPreviewProvider={handlePreviewUser(
                    booking.gig.provider,
                    index
                  )}
                  onPreviewCustomer={handlePreviewUser(booking.customer, index)}
                  onAddComment={handlePreviewExperience(booking, index, 3)}
                />
              ) : (
                <BookingRow
                  key={`booking-row-${index}`}
                  booking={booking}
                  isActive={bookingPanel.relatedRowIndex === index}
                  onPreviewExperience={handlePreviewExperience(booking, index)}
                  onPreviewProvider={handlePreviewUser(
                    booking.gig.provider,
                    index
                  )}
                  onPreviewCustomer={handlePreviewUser(booking.customer, index)}
                  onAddComment={handlePreviewExperience(booking, index, 3)}
                  onJustify={handleJustification(booking.id)}
                />
              )
            }
          />
        </div>
      </div>

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={closeUserPanel}
      />

      <BookingPanel
        isOpen={bookingPanel.isOpen}
        bookingId={bookingPanel.bookingId}
        experienceId={bookingPanel.experienceId}
        title='Experience Details'
        onClose={closeBookingPanel}
        showComments={true}
        initialTab={bookingPanel.initialTab}
      />

      <JustifyBookingModal
        isOpen={justifyModal.isOpen}
        isProcessing={isSendingJustification}
        onAccept={handleSendJustification}
        onClose={handleCloseBookingModal}
      />

      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
      />

      <ErrorModal
        isOpen={errorModal.isOpen}
        message={errorModal.message}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default BookingRequests;
