import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { ICoordinates, IPanelProps, ReportType } from '../../../core/types';
import './BookingPanel.scss';
import { useState, useEffect } from 'react';
import DetailsTab from '../../Tabs/DetailsTab/DetailsTab';
import ConversationTab from '../../Tabs/ConversationTab/ConversationTab';
import MessagesTab from '../../Tabs/MessagesTab/MessagesTab';
import { getBooking, getBookingChat, getExperience } from '../../../core/api';
import Loader from '../../Loader/Loader';
import {
  BookingTicketViewModel,
  ChatViewModel,
  GigViewModel,
  LocationType,
  MessageViewModel
} from '../../../core/backend/models';
import { getLatLngFromAddress } from '../../../core/helpers/helpers';
import appToast from '../../../core/toast';
import AddCommentsTab from '../../Tabs/AddCommentsTab/AddCommentsTab';
import { AdminCommentType } from '../../../core/enums';
import TicketsTab from '../../Tabs/TicketsTab/TicketsTab';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/consts';

interface IProps extends IPanelProps {
  bookingId: number;
  experienceId: number;
  isResolved?: boolean;
  onResolve?: (id: number, type: ReportType) => React.MouseEventHandler;
  showComments?: boolean;
  initialTab?: number;
}

const BookingPanel = ({
  title = 'Experience details',
  bookingId,
  experienceId,
  isResolved = false,
  onResolve,
  showComments = false,
  initialTab = 0,
  ...props
}: IProps) => {
  const [value, setValue] = useState(initialTab);
  const [isProcessing, setIsProcessing] = useState(true);
  const [experience, setExperience] = useState<GigViewModel>(null);
  const [chat, setChat] = useState<ChatViewModel | null>(null);
  const [conversation, setConversation] = useState<MessageViewModel[] | null>(
    []
  );
  const [bookingLatLng, setBookingLatLng] = useState<ICoordinates | null>(null);
  const [bookingTickets, setBookingTickets] =
    useState<BookingTicketViewModel[]>(null);

  useEffect(() => {
    if (props.isOpen) {
      setValue(initialTab);
      return;
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!bookingId || !props.isOpen) return;

    setBookingLatLng(null);
    getBookingData();
  }, [bookingId, props.isOpen]);

  const getBookingData = async () => {
    try {
      setIsProcessing(true);

      const booking = await getBooking(bookingId);
      const _experience = booking.gig;

      setExperience(booking.gig);
      setBookingTickets(booking.tickets);

      if (
        _experience.locationType === LocationType.NUMBER_1 ||
        _experience.locationType === LocationType.NUMBER_2
      ) {
        await requestEventLatLng(_experience.location);
      }

      await requestChatMessages(bookingId);
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  const requestEventLatLng = async (eventLocation?: string) => {
    const response = await getLatLngFromAddress(eventLocation);
    setBookingLatLng(response?.results[0]?.geometry?.location);
  };

  const requestChatMessages = async (bookingId: number) => {
    try {
      const chat = await getBookingChat(bookingId);
      if (!chat) return;

      setChat(chat);
      setConversation(chat.messages);
    } catch (e) {
      appToast.showError('Failed to get booking chat');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='scrollable'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='booking-tab-0' value={0} />
          {experience.oneEventTime && (
            <Tab label='Tickets' id='booking-tab-1' value={1} />
          )}
          <Tab label='Conversation' id='booking-tab-2' value={2} />
          <Tab label='Messages' id='booking-tab-3' value={3} />
          {showComments && <Tab label='Notes' id='booking-tab-4' value={4} />}
        </Tabs>
      )}
      <div className='BookingPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <DetailsTab
              experience={experience}
              isResolved={isResolved}
              bookingLatLng={bookingLatLng}
              value={value}
              index={0}
              onResolve={
                onResolve ? onResolve(bookingId, 'booking_report') : undefined
              }
            />
            {experience.oneEventTime && (
              <TicketsTab value={value} index={1} tickets={bookingTickets} />
            )}
            <ConversationTab
              members={chat?.members}
              data={conversation}
              value={value}
              index={2}
            />
            <MessagesTab members={chat?.members} value={value} index={3} />
            {showComments && (
              <AddCommentsTab
                targetId={bookingId}
                adminCommentType={AdminCommentType.Booking}
                value={value}
                index={4}
              />
            )}
          </>
        )}
      </div>
    </BasePanel>
  );
};

export default BookingPanel;
