import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryViewModel } from '../../core/backend/models';

const initialState: string[] = [];

export const hashtagsSlice = createSlice({
  name: 'hashtags',
  initialState,
  reducers: {
    addAllHashtags: (state, action: PayloadAction<string[]>) => action.payload
  }
});

export const { addAllHashtags } = hashtagsSlice.actions;
