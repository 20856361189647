import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const DiscountsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align='center' padding='normal'>
          Code
        </TableCell>
        <TableCell align='center' padding='normal'>
          Coupon
        </TableCell>
        <TableCell align='center' padding='normal'>
          Amount/Percentage
        </TableCell>
        <TableCell align='center' padding='normal'>
          Bearer
        </TableCell>
        <TableCell align='center' padding='normal'>
          Max Redemptions
        </TableCell>
        <TableCell align='center' padding='normal'>
          Expires
        </TableCell>
        <TableCell align='center' padding='normal'>
          Active
        </TableCell>
        <TableCell align='center' padding='normal'>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DiscountsTableHeader;
