import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { INewContentRow, ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';

const NewContentTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  order,
  orderBy,
  onRequestSort
}: ITableHeaderProps<INewContentRow>) => {
  const createSortHandler =
    (property: keyof INewContentRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell align='left' padding='none'>
          Content
        </TableCell>
        <TableCell padding='normal'>Description</TableCell>
        <TableCell padding='normal'>
          <TableSortLabel
            active={orderBy === 'createdOn'}
            direction={orderBy === 'createdOn' ? order : 'asc'}
            onClick={createSortHandler('createdOn')}
          >
            Date Added
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal'>Type</TableCell>
        <TableCell padding='normal'>User</TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'status'}
            direction={orderBy === 'status' ? order : 'asc'}
            onClick={createSortHandler('status')}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal'>Rejection Reason</TableCell>
        <TableCell padding='normal'>Action By</TableCell>
        <TableCell padding='normal'>Action Date</TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default NewContentTableHeader;
