import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as HooplaLogo } from '../../images/hoopla_com.svg';
import { getUser, logOut, signIn } from '../../core/api';
import SignInSchema from '../../validators/SignInSchema';
import './SignIn.scss';
import { RoutesEnum } from '../../core/enums';
import Loader from '../../components/Loader/Loader';
import { useAppDispatch, signInUser } from '../../state';

const SignIn = () => {
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState('');
  const history = useHistory();

  const { errors, values, handleChange, handleSubmit } = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: SignInSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: FormikValues) => {
      setIsProcessing(true);
      signIn(values.email, values.password)
        .then(() => getUser())
        .then((user) => {
          if (!user.isAdmin || !user.accessLevel) {
            logOut();
            throw new Error("This account doesn't have permissions to access");
          }

          return user;
        })
        .then((user) => {
          setIsProcessing(false);
          dispatch(signInUser(user));
          history.push(RoutesEnum.Bookings);
        })
        .catch((e) => {
          setIsProcessing(false);
          setServerError(
            e.response?.data?.description ?? e.message ?? 'Invalid credentials'
          );
        });
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className='SignIn'>
      <div className='SignIn__formWrapper'>
        <HooplaLogo className='SignIn__logo' width={246} />
        <Card className='SignIn__form'>
          <form onSubmit={handleSubmit} method='post'>
            <CardContent className='SignIn__formContent'>
              <Typography
                sx={{ fontSize: 16, textAlign: 'center', marginBottom: '30px' }}
                color='text.secondary'
                gutterBottom
              >
                Welcome to hoopla.com admin
              </Typography>
              {serverError && (
                <Typography
                  sx={{
                    fontSize: 14,
                    textAlign: 'center',
                    marginBottom: '30px',
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                  color='text.secondary'
                  gutterBottom
                >
                  {serverError}
                </Typography>
              )}
              <TextField
                id='email'
                label='Email'
                variant='standard'
                style={{ marginBottom: '20px' }}
                name='email'
                value={values.email}
                onChange={handleChange}
                helperText={errors.email}
                error={!!errors.email}
                fullWidth
              />
              <FormControl fullWidth variant='standard'>
                <InputLabel htmlFor='password' error={!!errors.password}>
                  Password
                </InputLabel>
                <Input
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.password && (
                  <FormHelperText error={true}>
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </CardContent>
            <CardActions>
              {isProcessing ? (
                <Loader adaptToParent size={32} />
              ) : (
                <Button
                  type='submit'
                  variant='contained'
                  style={{ margin: '0 auto' }}
                >
                  LOGIN
                </Button>
              )}
            </CardActions>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default SignIn;
