import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './EventsPage.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import Events from '../Events/Events';
import ExternalEvents from '../ExternalEvents/ExternalEvents';
import { RoutesEnum } from '../../core/enums';

const EventsPage = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.Events}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Events
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.ExternalEvents}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            External Links
          </Button>
        </ButtonGroup>
      </Box>
      <Switch>
        <Route exact path={RoutesEnum.Events} component={Events} />
        <Route
          exact
          path={RoutesEnum.ExternalEvents}
          component={ExternalEvents}
        />
      </Switch>
    </MainLayout>
  );
};

export default EventsPage;
