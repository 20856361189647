import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { DiscountCodeViewModel } from '../../../core/backend/models';
import './DiscountsRow.scss';
import { convertToCurrency } from '../../../core/helpers/helpers';
import { Button } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

interface IProps extends IBaseRowProps {
  discount: DiscountCodeViewModel;
  onToggleActive: React.MouseEventHandler;
}

const DiscountsRow = ({ discount, onToggleActive, onClick }: IProps) => {
  const date = dayjs(discount.expiresAt * 1000);
  const formattedDate = date.format('MM/DD/YY');

  return (
    <BaseRow onClick={onClick}>
      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        <div className='DiscountsRow__code'>
          <b>{discount.code}</b>
          {discount?.comments?.length > 0 && (
            <ChatBubbleOutlineIcon sx={{ fontSize: 19 }} />
          )}
        </div>
      </TableCell>
      <TableCell
        align='center'
        sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}
      >
        {discount.stripeCouponId}
      </TableCell>

      <TableCell align='center' sx={{ maxWidth: '130px' }}>
        {discount.amountOff
          ? convertToCurrency(discount.amountOff)
          : `${discount.percentOff}%`}
      </TableCell>
      <TableCell
        align='center'
        sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}
      >
        {discount.costBearerType === 0 ? 'Hoopla' : 'Creator'}
      </TableCell>
      <TableCell align='center' style={{ maxWidth: '140px' }}>
        {discount.maxRedemptions ?? 'Unlimited'}
      </TableCell>
      <TableCell
        align='center'
        sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}
      >
        {formattedDate}
      </TableCell>
      <TableCell
        align='center'
        sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}
      >
        {discount.active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell
        align='center'
        sx={{ maxWidth: '130px', overflowWrap: 'break-word' }}
      >
        <Button
          size='small'
          variant='outlined'
          onClick={onToggleActive}
          color={discount.active ? 'error' : 'primary'}
        >
          {discount.active ? 'Disable' : 'Enable'}
        </Button>
      </TableCell>
    </BaseRow>
  );
};

export default DiscountsRow;
