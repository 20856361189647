import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import './BannerRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { BannerViewModel } from '../../../core/backend/models';
import CopyBtn from '../../CopyBtn/CopyBtn';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface IProps extends IBaseRowProps {
  banner: BannerViewModel;
  sortMaxLength: number;
  onSelect: React.MouseEventHandler;
  onSortChange: React.ChangeEventHandler;
  onEdit: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
}

const BannerRow = ({
  banner,
  sortMaxLength,
  selected,
  onSelect,
  onSortChange,
  onEdit,
  onDelete
}: IProps) => {
  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell component='th' scope='row'>
        <img
          alt=''
          src={getMediaFile(banner.mediaFile.awsUrl)}
          width='300'
          height='150'
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        {banner.link}
      </TableCell>

      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '50px' }}>
        <TextField
          id='banner-sort'
          select
          label='Sort'
          value={banner.order}
          onChange={onSortChange}
          variant='standard'
        >
          {Array.from({ length: sortMaxLength }, (_, index) => index).map(
            (option) => (
              <MenuItem key={option} value={option}>
                {option + 1}
              </MenuItem>
            )
          )}
        </TextField>
      </TableCell>

      <TableCell align='center' style={{ verticalAlign: 'top' }}>
        <Grid container justifyContent='flex-start'>
          <Grid item md={12} lg={4}>
            <div className='BannerRow__copy-button'>
              <CopyBtn
                textToCopy={banner.link}
                successMsg='Banner URL link copied to the clipboard!'
              />
            </div>
          </Grid>
          <Grid item md={12} lg={4}>
            <Button variant='text' color='primary' onClick={onEdit}>
              <EditIcon /> Edit
            </Button>
          </Grid>
          <Grid item md={12} lg={4}>
            <Button variant='text' color='error' onClick={onDelete}>
              <ClearIcon /> Delete
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </BaseRow>
  );
};

export default BannerRow;
