import { Link } from 'react-router-dom';
import { UserViewModel } from '../../core/backend/models';
import './Mentions.scss';

interface IProps {
  content: string;
  textOnly?: boolean;
  taggedUsers: UserViewModel[];
  className?: string;
}

const Mentions = ({
  content,
  taggedUsers,
  textOnly = false,
  className = ''
}: IProps) => {
  return (
    <div className={className}>
      {taggedUsers.length
        ? content.split(/\s/).map((slice) => {
            const user = taggedUsers.find((user) => '@' + user.id === slice);
            if (user)
              return textOnly ? (
                `@${user.userName} `
              ) : (
                <>
                  <Link className='Mentions__link' to={`/creators/${user.id}`}>
                    @{user.userName}
                  </Link>{' '}
                </>
              );

            return slice + ' ';
          })
        : content}
    </div>
  );
};

export default Mentions;
