import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps } from '../../../core/types';
import './UserPanel.scss';
import { useState, useEffect } from 'react';
import {
  UserViewModel,
  CommentViewModel,
  ProviderSummaryResponse,
  CustomerSummaryViewModel
} from '../../../core/backend/models';
import CommentsTab from '../../Tabs/CommentsTab/CommentsTab';
import UserTab from '../../Tabs/UserTab/UserTab';
import MessagesTab from '../../Tabs/MessagesTab/MessagesTab';
import AddCommentsTab from '../../Tabs/AddCommentsTab/AddCommentsTab';
import ProviderStatsTab from '../../Tabs/ProviderStatsTab/ProviderStatsTab';
import {
  getCustomerStats,
  getProviderStats,
  getUserComments
} from '../../../core/api';
import Loader from '../../Loader/Loader';
import CustomerStatsTab from '../../Tabs/CustomerStatsTab/CustomerStatsTab';
import { updateLocalUserNotes, useAppDispatch } from '../../../state';
import appToast from '../../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/consts';
import { AdminCommentType } from '../../../core/enums';

interface IProps extends IPanelProps {
  user: UserViewModel;
}

interface ICommentsState {
  isLoading: boolean;
  skip: number;
  hasMore: boolean;
  comments: CommentViewModel[];
}

const UserPanel = ({ title = 'User Details', user, ...props }: IProps) => {
  const [value, setValue] = useState(0);
  const [userStats, setUserStats] = useState<CustomerSummaryViewModel>(null);
  const [creatorStats, setCreatorStats] =
    useState<ProviderSummaryResponse>(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [commentsState, setCommentsState] = useState<ICommentsState>({
    isLoading: false,
    skip: 0,
    hasMore: true,
    comments: []
  });

  useEffect(() => {
    if (props.isOpen) {
      setValue(0);
      return;
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (user && props.isOpen) {
      setIsProcessing(true);
      setUserStats(null);
      setCreatorStats(null);
      setCommentsState({
        isLoading: false,
        skip: 0,
        hasMore: true,
        comments: []
      });
      getUserData().then(() => {
        setIsProcessing(false);
      });
    }
  }, [user]);

  const getUserData = async () => {
    try {
      if (user.isCreatorPro) {
        const _creatorStats = await getProviderStats(user.id);
        setCreatorStats(_creatorStats);
      }

      const _userStats = await getCustomerStats(user.id);
      await loadUserComments();

      setUserStats(_userStats);
    } catch (e: any) {
      appToast.showError(
        e.response?.data?.description ??
          'Something went wrong while loading user stats. Close panel and try again.'
      );
    }
  };

  const loadUserComments = async () => {
    try {
      if (!commentsState.hasMore) return;

      setCommentsState({ ...commentsState, isLoading: true });
      const _comments = await getUserComments(user.id, commentsState.skip, 15);

      if (_comments.length === 0) {
        setCommentsState({
          ...commentsState,
          isLoading: false,
          hasMore: false
        });
        return;
      }

      setCommentsState({
        ...commentsState,
        isLoading: false,
        comments: [...commentsState.comments, ..._comments],
        hasMore: _comments.length > 0
      });
    } catch (e) {
      appToast.showError('Could not get user comments.');
      setCommentsState({ ...commentsState, isLoading: false });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='scrollable'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='simple-tab-1' />
          <Tab label='Direct Chat' id='simple-tab-2' />
          {user.isCreatorPro && <Tab label='Creator Stats' id='simple-tab-3' />}
          <Tab label='Customer Stats' id='simple-tab-4' />
          <Tab label='Comments' id='simple-tab-5' />
          <Tab label='Notes' id='simple-tab-6' />
        </Tabs>
      )}
      <div className='UserPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <UserTab user={user} value={value} index={0} />
            <MessagesTab user={user} value={value} index={1} />
            {user.isCreatorPro && (
              <ProviderStatsTab stats={creatorStats} value={value} index={2} />
            )}
            <CustomerStatsTab
              stats={userStats}
              value={value}
              index={user.isCreatorPro ? 3 : 2}
            />
            <CommentsTab
              data={commentsState.comments}
              isLoading={commentsState.isLoading}
              onReachBottom={loadUserComments}
              value={value}
              index={user.isCreatorPro ? 4 : 3}
            />
            <AddCommentsTab
              targetId={user.id}
              adminCommentType={AdminCommentType.User}
              value={value}
              index={user.isCreatorPro ? 5 : 4}
            />
          </>
        )}
      </div>
    </BasePanel>
  );
};

export default UserPanel;
