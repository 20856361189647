import { createPortal } from 'react-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import './ActionsToolbar.scss';

interface IProps {
  selectedItems: number;
  onApproveAll?: () => void;
  onDeclineAll?: React.MouseEventHandler;
  approveTitle?: string;
  declineTitle?: string;
}

const ActionsToolbar = ({
  selectedItems,
  onApproveAll,
  onDeclineAll,
  approveTitle = 'Approve',
  declineTitle = 'Decline'
}: IProps) => {
  return createPortal(
    <div className='ActionsToolbar'>
      <div className='ActionsToolbar__container'>
        <div className='ActionsToolbar__selectedCount'>
          {selectedItems} selected
        </div>
        <div className='ActionsToolbar__separator'></div>
        <Stack spacing={2} direction='row'>
          {onApproveAll && (
            <Button variant='text' onClick={onApproveAll}>
              {approveTitle}
            </Button>
          )}
          {onDeclineAll && (
            <Button variant='text' onClick={onDeclineAll}>
              {declineTitle}
            </Button>
          )}
        </Stack>
      </div>
    </div>,
    document.getElementById('ActionsToolbar')
  );
};

export default ActionsToolbar;
