import CircularProgress from '@mui/material/CircularProgress';
import { deepPurple } from '@mui/material/colors';
import { ReactComponent as HooplaIcon } from '../../images/logo_sm.svg';
import classNames from 'classnames';
import './Loader.scss';

interface IProps {
  className?: string;
  size?: number;
  adaptToParent?: boolean;
  light?: boolean;
  backdrop?: boolean;
}

const Loader = ({
  className,
  size = 68,
  light = false,
  backdrop = false,
  adaptToParent = false
}: IProps) => {
  return (
    <div
      className={classNames(`Loader ${className}`, {
        'Loader--backdrop': backdrop,
        'Loader--adapt': adaptToParent
      })}
    >
      <div
        className='Loader__container'
        style={{ width: size + 'px', height: size + 'px' }}
      >
        <CircularProgress
          size={size}
          sx={{
            color: deepPurple[light ? 50 : 400],
            position: 'absolute',
            top: '0',
            left: '0'
          }}
        />
        <HooplaIcon
          className={classNames('Loader__logo', {
            'Loader__logo--light': light
          })}
          width={size - 10}
          height={size - 10}
        />
      </div>
    </div>
  );
};

export default Loader;
