import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { CategoryViewModel } from '../../../core/backend/models';

interface IProps extends IBaseRowProps {
  category: CategoryViewModel;
  onEdit: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
}

const CategoryRow = ({ category, onEdit, onDelete }: IProps) => {
  return (
    <BaseRow>
      <TableCell
        component='th'
        scope='row'
        padding='normal'
        sx={{ maxWidth: '50px' }}
      >
        <img alt='' src={getMediaFile(category.image)} width='42' height='42' />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        {category.name}
      </TableCell>
      <TableCell align='right' style={{ verticalAlign: 'top' }}>
        <Button variant='text' color='primary' onClick={onEdit}>
          <EditIcon sx={{ marginRight: '5px' }} /> Edit
        </Button>
        <Button variant='text' color='error' onClick={onDelete}>
          <ClearIcon /> Delete
        </Button>
      </TableCell>
    </BaseRow>
  );
};

export default CategoryRow;
