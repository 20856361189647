import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { CommentViewModel } from '../../../core/backend/models';
import CommentsTableHeader from '../../TableHeaders/CommentsTableHeader/CommentsTableHeader';
import { SortType } from '../../../core/enums';

interface IProps {
  data: CommentViewModel[];
  selectedRows: number[];
  order: SortType;
  onSelectionChange: (selectedRows: number[]) => void;
  onSort: React.MouseEventHandler;
  renderItem: (
    item: CommentViewModel,
    index: number,
    helpers: {
      handleSelectRow: (id: number) => React.MouseEventHandler;
      isSelected: boolean;
    }
  ) => React.ReactNode;
}

const CommentsTable = ({
  data,
  order,
  selectedRows,
  onSelectionChange,
  renderItem,
  onSort
}: IProps) => {
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: number[] = data.map((comment) => comment.id);

      onSelectionChange(newSelecteds);
      return;
    }

    onSelectionChange([]);
  };

  const handleSelectRow =
    (id: number) => (event: React.MouseEvent<unknown>) => {
      event.stopPropagation();

      const selectedIndex = selectedRows.findIndex(
        (selectedId) => selectedId === id
      );
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selectedRows, id];
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      onSelectionChange(newSelected);
    };

  const isSelected = (id: number) =>
    selectedRows.findIndex((selectedId) => selectedId === id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size='medium'>
            <CommentsTableHeader
              order={order}
              onSort={onSort}
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAll}
              rowCount={data.length}
            />
            <TableBody>
              {data.map((row, index) =>
                renderItem(row, index, {
                  handleSelectRow,
                  isSelected: isSelected(row.id)
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CommentsTable;
