import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IBookingRow, ITableHeaderProps } from '../../../core/types';
import { TableSortLabel } from '@mui/material';

const BookingsTableHeader: React.FC<ITableHeaderProps<IBookingRow>> = ({
  onRequestSort,
  order,
  orderBy
}) => {
  const createSortHandler =
    (property: keyof IBookingRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align='left' padding='normal'>
          Details
        </TableCell>
        <TableCell align='center' padding='normal'>
          Booking Reservation
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'requestTime'}
            direction={orderBy === 'requestTime' ? order : 'asc'}
            onClick={createSortHandler('requestTime')}
          >
            Requested on
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'estimatedResponseTime'}
            direction={orderBy === 'estimatedResponseTime' ? order : 'asc'}
            onClick={createSortHandler('estimatedResponseTime')}
          >
            Resp. deadline
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'eventTime'}
            direction={orderBy === 'eventTime' ? order : 'asc'}
            onClick={createSortHandler('eventTime')}
          >
            Event Date
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'remainingTime'}
            direction={orderBy === 'remainingTime' ? order : 'asc'}
            onClick={createSortHandler('remainingTime')}
          >
            Time Remaining
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' padding='normal'>
          <TableSortLabel
            active={orderBy === 'status'}
            direction={orderBy === 'status' ? order : 'asc'}
            onClick={createSortHandler('status')}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell padding='normal'>Creator</TableCell>
        <TableCell padding='normal'>Customer</TableCell>
        <TableCell align='center' padding='normal'>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default BookingsTableHeader;
