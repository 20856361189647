import { useCallback, useEffect, useState } from 'react';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import BookingPanel from '../../components/DetailsPanel/BookingPanel/BookingPanel';
import Filter from '../../components/Filter/Filter';
import ResolveIssueModal from '../../components/Modals/ResolveIssueModal/ResolveIssueModal';
import BookingReportRow from '../../components/TableRows/BookingReportRow/BookingReportRow';
import { resolveBookingReport } from '../../core/api';
import { BookingViewModel, UserViewModel } from '../../core/backend/models';
import {
  FilterOptionType,
  IMainPanel,
  IMessage,
  IModalState,
  IBookingReportRow,
  ReportType,
  IUserPanel,
  IBookingPanel
} from '../../core/types';
import {
  addAllBookingReports,
  addSchedule,
  clearSchedules,
  getAllBookingReports,
  updateBookingReportStatus,
  useAppDispatch,
  useAppSelector
} from '../../state';
import './ModerateBookingReports.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { searchBookingReportsFilter } from '../../core/helpers/search-helpers';
import { bookingReportsFilterOptions } from '../../core/helpers/filter-helpers';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import BookingReportsTableHeader from '../../components/TableHeaders/BookingReportsTableHeader/BookingReportsTableHeader';
import { createBookingReportModel } from '../../core/helpers/model-helpers';
import UserPanel from '../../components/DetailsPanel/UserPanel/UserPanel';
import { BookingResolution } from '../../core/enums';

const ModerateBookingReports = () => {
  const dispatch = useAppDispatch();
  const { schedule, bookingReports } = useAppSelector((state) => state);
  const [page, setPage] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<FilterOptionType[]>(
    []
  );
  const [reportModal, setReportModal] = useState<IModalState>({
    isOpen: false,
    id: 0
  });
  const [panelState, setPanelState] = useState<IBookingPanel>({
    isOpen: false,
    bookingId: null,
    experienceId: null,
    isResolved: false,
    relatedRowIndex: -1
  });
  const [userPanel, setUserPanel] = useState<IUserPanel>({
    isOpen: false,
    user: null,
    relatedRowIndex: -1
  });
  const [snackState, setSnackState] = useState<IMessage>({
    isOpen: false,
    message: ''
  });
  const [currentReports, setCurrentReports] =
    useState<IBookingReportRow[]>(bookingReports);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (!isSearching) setCurrentReports(bookingReports);
  }, [bookingReports, isSearching]);

  /**
   * Run existing scheduled actions before closing the window
   */
  useEffect(() => {
    if (schedule.length === 0) {
      window.onbeforeunload = null;
      return;
    }

    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue = '??');
    };
  }, [schedule]);

  /**
   * Filter reports if selected filter changes or reports change
   */
  useEffect(() => {
    filterReports();
  }, [selectedFilters, bookingReports]);

  /**
   * Process all scheduled tasks
   */
  useEffect(() => {
    if (snackState.isOpen || schedule.length < 1) return;
    runScheduledActions();
  }, [snackState.isOpen, schedule]);

  const runScheduledActions = async () => {
    const refreshState = async () => {
      const _reports = await getAllBookingReports();
      const reports = _reports.map(createBookingReportModel);
      dispatch(addAllBookingReports(_reports));
      setCurrentReports(bookingReports);
    };

    try {
      const requests: Promise<any>[] = [];
      schedule.forEach((task) => {
        requests.push(
          resolveBookingReport(task.id, +task.action, task.extra as string)
        );
        dispatch(
          updateBookingReportStatus({
            reportResolution: +task.action,
            status: 'completed',
            bookingId: task.id
          })
        );
      });

      await Promise.all(requests);

      //TODO: Remove if not needed
      //await refreshState();
    } catch (e) {
      await refreshState();
      filterReports();
    } finally {
      dispatch(clearSchedules());
    }
  };

  const filterReports = () => {
    if (selectedFilters.length) {
      const statusFilters: string[] = selectedFilters.map(
        (filter) => filter.value
      );

      const checkFilterType = (report: IBookingReportRow) =>
        statusFilters.includes(report.status);

      const filteredReports = bookingReports.filter(checkFilterType);
      // .sort(sortReportsByStatus);

      setCurrentReports(filteredReports);
    }
  };

  /**
   * Table methods
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /**
   * Table Methods
   */

  const idFromItem = (item: IBookingReportRow) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleSeachContent = useCallback(
    (query: string) => {
      if (!query.length) {
        setIsSearching(false);
        setCurrentReports(bookingReports);
        return;
      }

      setIsSearching(true);
      const lowerCasedQuery = query.toLowerCase();

      const _result = bookingReports.filter(
        searchBookingReportsFilter(lowerCasedQuery)
      );
      setCurrentReports(_result);
    },
    [bookingReports]
  );

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const confirmResolveIssue = (decisionId: number, message: string) => {
    dispatch(
      addSchedule({
        id: reportModal.id,
        type: 'booking_report',
        action: decisionId,
        extra: message
      })
    );

    const _reports = currentReports.map((report) =>
      report.id === reportModal.id
        ? {
            ...report,
            reportResolution: decisionId,
            status: 'completed'
          }
        : report
    );

    setCurrentReports(_reports);

    setPanelState({
      ...panelState,
      isResolved: true
    });

    closeReportModal();
    openSnack('Booking resolved');
  };

  /**
   * Side Panel
   */

  const handleViewUser = (user: UserViewModel) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setUserPanel({ isOpen: true, user });
  };

  const handleViewBooking =
    (booking: BookingViewModel, relatedRowIndex: number = -1) =>
    () => {
      if (relatedRowIndex === -1) {
        relatedRowIndex = bookingReports.findIndex(
          (report) => report.id === booking.id
        );
      }

      setPanelState({
        isOpen: true,
        bookingId: booking.id,
        experienceId: booking.gig?.id ?? null,
        isResolved: booking.reportResolution !== null,
        relatedRowIndex
      });
    };

  const closePanel = () => {
    setPanelState({ ...panelState, isOpen: false, relatedRowIndex: -1 });
  };

  const handleCloseUserPanel = () => {
    setUserPanel({ ...userPanel, isOpen: false });
  };

  /**
   * Modal Methods
   */
  const handleShowReportModal =
    (id: number, type: ReportType) => (event: React.SyntheticEvent) => {
      event.stopPropagation();
      setReportModal({ isOpen: true, id, type });
    };

  const closeReportModal = () => {
    setReportModal({ isOpen: false, id: 0, type: null });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  const handleSnackUndo = () => {
    dispatch(clearSchedules());
    setCurrentReports(bookingReports);
    setPanelState({ ...panelState, isResolved: false });
    filterReports();
    closeSnack();
  };

  return (
    <>
      <div className='ModerateBookingReports'>
        <div className='ModerateBookingReports__filterControls'>
          <SearchInput
            onDebouncedTextChange={handleSeachContent}
            className='ModerateBookingReports__search'
          />
          <Filter
            options={bookingReportsFilterOptions}
            onChange={(value: FilterOptionType[]) => {
              if (!value.length) {
                setCurrentReports(bookingReports);
                return;
              }

              setSelectedFilters(value);
            }}
          />
        </div>

        <div className='ModerateBookingReports__content'>
          <BasicTable
            data={currentReports}
            Header={BookingReportsTableHeader}
            page={page}
            idFromItem={idFromItem}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            renderItem={(booking, idx, { isSelected, handleSelectRow }) => (
              <BookingReportRow
                key={`booking-row-${idx}`}
                status={booking.status}
                booking={booking}
                isActive={panelState.relatedRowIndex === idx}
                onViewDetails={handleViewBooking(booking, idx)}
                onPreviewUser={handleViewUser}
              />
            )}
          />
        </div>
      </div>

      <BookingPanel
        isOpen={panelState.isOpen}
        isResolved={panelState.isResolved}
        bookingId={panelState.bookingId}
        experienceId={panelState.experienceId}
        onResolve={handleShowReportModal}
        onClose={closePanel}
      />

      <UserPanel
        isOpen={userPanel.isOpen}
        user={userPanel.user}
        onClose={handleCloseUserPanel}
      />

      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
        onAction={handleSnackUndo}
      />

      <ResolveIssueModal
        isOpen={reportModal.isOpen}
        onAccept={confirmResolveIssue}
        onClose={closeReportModal}
      />
    </>
  );
};

export default ModerateBookingReports;
