import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { createCategory, deleteCategory, updateCategory } from '../../core/api';
import { CategoryViewModel } from '../../core/backend/models';
import './Categories.scss';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import CategoryRow from '../../components/TableRows/CategoryRow/CategoryRow';
import NewCategoryModal from '../../components/Modals/NewCategoryModal/NewCategoryModal';
import EditCategoryModal from '../../components/Modals/EditCategoryModal/EditCategoryModal';
import BasicTable from '../../components/Tables/BasicTable/BasicTable';
import CategoryTableHeader from '../../components/TableHeaders/CategoryTableHeader/CategoryTableHeader';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import {
  addToCategories,
  deleteFromCategories,
  updateCategories,
  useAppDispatch,
  useAppSelector
} from '../../state';
import appToast from '../../core/toast';

interface ICategoryModal {
  category: CategoryViewModel | null;
  isOpen: boolean;
}

const Categories = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.categories);
  const [isProcessing, setIsProcessing] = useState(false);
  const [reportPage, setReportPage] = useState(0);
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState(false);
  const [editModal, setEditModal] = useState<ICategoryModal>({
    category: null,
    isOpen: false
  });
  const [deleteModal, setDeleteModal] = useState<ICategoryModal>({
    category: null,
    isOpen: false
  });
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  /**
   * Table methods
   */

  const handleChangePage = (event: unknown, newPage: number) => {
    setReportPage(newPage);
  };

  const idFromItem = (item: CategoryViewModel) => {
    return item.id;
  };

  /**
   * Actions
   */

  const handleCreateNewCategory = async (values: {
    image: File;
    name: string;
  }) => {
    setIsProcessing(true);
    const result = await createCategory(values.image, values.name).catch(
      (e) => {
        setIsProcessing(false);
        setErrorModalVisible(true);
      }
    );

    if (result) {
      dispatch(addToCategories(result));
      setIsProcessing(false);
      handleCloseNewCategoryModal();
    }
  };

  const handleEditCategory = async (values: { image: File; name: string }) => {
    setIsProcessing(true);
    const result = await updateCategory(
      editModal.category.id,
      values.name,
      values.image
    ).catch((e) => {
      setIsProcessing(false);
      setErrorModalVisible(true);
    });

    if (result) {
      dispatch(updateCategories(result));
      setIsProcessing(false);
      handleCloseEditModal();
    }
  };

  const handleDeleteCategory = async () => {
    setIsProcessing(true);

    await deleteCategory(deleteModal.category.id);
    dispatch(deleteFromCategories(deleteModal.category.id));

    setIsProcessing(false);
    handleCloseDeleteModal();
  };

  /**
   * Modal Methods
   */
  const handleShowNewCategoryModal = () => {
    setIsNewCategoryOpen(true);
  };

  const handleCloseNewCategoryModal = () => {
    setIsNewCategoryOpen(false);
  };

  const handleShowEditCategory = (category: CategoryViewModel) => () => {
    setEditModal({ category, isOpen: true });
  };

  const handleShowDeleteCategory = (category: CategoryViewModel) => () => {
    setDeleteModal({ category, isOpen: true });
  };

  const handleCloseEditModal = () => {
    setEditModal({ category: null, isOpen: false });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ category: null, isOpen: false });
  };

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  return (
    <>
      <div className='Categories'>
        <div className='Banners__actionButtons'>
          <Button
            variant='text'
            color='secondary'
            startIcon={<AddIcon />}
            onClick={handleShowNewCategoryModal}
          >
            Create new category
          </Button>
        </div>
        <div className='Categories__content'>
          <BasicTable
            Header={CategoryTableHeader}
            data={categories}
            idFromItem={idFromItem}
            page={reportPage}
            onPageChange={handleChangePage}
            renderItem={(row, index) => (
              <CategoryRow
                key={row.id}
                category={row}
                onEdit={handleShowEditCategory(row)}
                onDelete={handleShowDeleteCategory(row)}
              />
            )}
          />
        </div>
      </div>

      {/* {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onDeclineAll={handleShowDeleteBanner(null)}
          declineTitle='Delete'
        />
      )} */}

      <NewCategoryModal
        isOpen={isNewCategoryOpen}
        onClose={handleCloseNewCategoryModal}
        isProcessing={isProcessing}
        onAccept={handleCreateNewCategory}
      />

      <EditCategoryModal
        isOpen={editModal.isOpen}
        category={editModal.category}
        onClose={handleCloseEditModal}
        isProcessing={isProcessing}
        onAccept={handleEditCategory}
      />

      <BaseModal
        title='Delete Category'
        isOpen={deleteModal.isOpen}
        onAccept={handleDeleteCategory}
        onClose={handleCloseDeleteModal}
        isProcessing={isProcessing}
      >
        Are you sure you want to delete this category? All content under this
        category will be moved to a generic category. This action can not be
        undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModalVisible}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default Categories;
